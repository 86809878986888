import React, { useEffect, useLayoutEffect, useState, useMemo, useRef, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Modal } from "react-bootstrap";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useForm, useWatch } from "react-hook-form";
import {
    BankAccountUpdateCheckoutModalSubmitForm,
    CheckOutDowngradeSeatFormData,
    CheckOutFormData,
    CheckOutSavedCardFormData,

} from "../../models/submit-form";
import {
    CreateSubscriptionFormData,
    CreateSubThroughBankAccFormData,
    DowngradeSeatSubscriptionFormData,
    CreateSubscriptionAlreadySubscribedFormData
} from "../../models/redux-models"
import { yupResolver } from "@hookform/resolvers/yup";
import {
    CheckOutBankAccountFormValidationSchema,
    CheckOutFormDowngradeSeatValidationSchema,
    checkOutFormSavedCardBankAccValidationSchema,
    CheckOutFormSavedCardValidationSchema,
    CheckOutFormValidationSchema,
    salesTaxIdRegex,
} from "../../../Helper/validation";
import { ADMIN_CONSTANT, ADMIN, MONTHS, USDollar, actionType, calculateDiscount, formatUSDCurrency, seatDiscountPercentage, setFormValues, subscriptionPathname } from "../../../utils/helper";
import {
    calculateTax,
    cartSelectBasinCountyActions,
    updateLinkedAccounts,
    clearCartItemsList,
    clearCartItemsTotalTax,
    clearSavedCardDetails,
    createOrUpdateSubscription,
    getStateList,
    validatePromoCode,
    handleSubscribingToStripeLoading
} from "../../store/actions/cart-select-basin-county-actions";
import {
    hideCheckOutModal,
    hideSiteLoader,
    showSiteLoader,
} from "../../store/actions/modal-actions";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import LocationSearchInput from "../LocationSearchInput";
import { geocodeByAddress } from "react-places-autocomplete";
import {
    clearCompanySettingsDetails,
    fetchCompanySettingDetail,
} from "../../store/actions/company-settings-actions";
import { SelectInput } from "../SelectInput";
import RadioInputComponent from "../RadioInputComponent";
import CardNumberInput from "../CardNumberInput";
import CardExpiryInput from "../CardExpiryInput";
import CardCvcInput from "../CardCvcInput";
import moment from "moment";
import InputComponent from "../InputComponent";
import {
    cancelSubscription,
    clearSubscriptionData,
    fetchSubscriptionData,
    saveAndUpdateTaxExemptID,
} from "../../store/actions/subscription-settings-actions";
import { loadUser, logUserAction } from "../../store/actions/auth-actions";
import Accordion from 'react-bootstrap/Accordion';
import { clearProductionData, clearRigsData, clearWellsData, handlePageChange } from "../../store/actions/wells-rigs-action";
import { loadInviteMembers, membersSettingsActions } from "../../store/actions/members-setting-actions";
import { fetchClientSecret } from "../../store/actions/billing-settings-actions";
import { PaymentIntentResult, PaymentMethodResult } from "@stripe/stripe-js";
import { PreviewInvoiceLineItems } from "../../models/redux-models";
import { setInvoiceLineItemsAndTotal } from "../../store/actions/cart-select-basin-county-actions";
import InventoryLineItemTable from "./InventoryLineItemTable"
import TotalSubtotalBillNow from "./TotalSubtotalBillNow"
import { SetupIntentResult } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import { InviteMembersRowDataType, UnacceptedMemberInvitesRowDataType } from "../../models/page-props"
import { hasInvoiceBeenCreated } from "../../../Helper/helper";

function PaymentModal({
    onCancelBtnClick,
    isEdit,
    removeBasinOrCounty,
    updateFilterAndUnmatchedDataAfterSub,
    seatModal,
}: {
    onCancelBtnClick: () => void;
    isEdit?: boolean;
    removeBasinOrCounty: (
        id: number,
        item_type: number,
        sub_total: number
    ) => void;
    updateFilterAndUnmatchedDataAfterSub?: () => void;
    seatModal?: boolean
}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();


    const [stateData, setStateData] = useState<{
        address: string,
        calcTax: boolean,
        initialMount: boolean,
        newItemTotalInEditCase: number,
        newItemTotalTaxInEditCase: number,
        promoCodeValidating: boolean,
        taxExempt: boolean,
        showMembersDropDown: boolean,
        membersSelected: number[],
        uninvitedSelected: number[],
        unacceptedInvitesSelected: number[],
        downgradeValidation: boolean,
        creditCardTab: boolean,
        failedInvoiceId: string,
        failedPaymentMethodIds: string[]
    }>({
        address: "",
        calcTax: false,
        initialMount: true,
        newItemTotalInEditCase: 0,
        newItemTotalTaxInEditCase: 0,
        promoCodeValidating: false,
        taxExempt: false,
        showMembersDropDown: false,
        membersSelected: [],
        unacceptedInvitesSelected: [],
        uninvitedSelected: [],
        downgradeValidation: false,
        creditCardTab: false,
        failedInvoiceId: '',
        failedPaymentMethodIds: []
    });

    const {
        address,
        calcTax,
        initialMount,
        newItemTotalInEditCase,
        newItemTotalTaxInEditCase,
        promoCodeValidating,
        taxExempt,
        membersSelected,
        unacceptedInvitesSelected,
        uninvitedSelected,
        downgradeValidation,
        creditCardTab,
        failedInvoiceId,
        failedPaymentMethodIds
    } = stateData;
    const toggleMembersDropDown = () => setStateData((prev) => ({ ...prev, showMembersDropDown: !prev.showMembersDropDown }));

    const checkOutModal = useAppSelector(state => state.modal.checkOutModal);
    const siteLoader = useAppSelector(state => state.modal.siteLoader);
    const first_name = useAppSelector(state => state.auth.user.first_name);
    const last_name = useAppSelector(state => state.auth.user.last_name);
    const access_token = useAppSelector(state => state.auth.user.access_token);
    const subscription_status = useAppSelector(state => state.auth.user.subscription_status);
    const free_trial_period_enabled = useAppSelector(state => state.auth.user.company_configs.free_trial_period_enabled);
    const no_of_free_days_allowed = useAppSelector(state => state.auth.user.company_configs.no_of_free_days_allowed);
    const trial_expired = useAppSelector(state => state.auth.user.company_configs.trial_expired);
    const email = useAppSelector(state => state.auth.user.email);
    const first_address = useAppSelector(state => state.companySettings.address.first_address);
    const city = useAppSelector(state => state.companySettings.address.city);
    const state = useAppSelector(state => state.companySettings.address.state);
    const zip_code = useAppSelector(state => state.companySettings.address.zip_code);
    const phone_no = useAppSelector(state => state.companySettings.address.phone_no);
    const billing_email = useAppSelector(state => state.companySettings.company.billing_email);
    const company_name = useAppSelector(state => state.companySettings.company.company_name);
    const company_id = useAppSelector(state => state.companySettings.company.company_id);
    const cartItemsTotalPerMonth = useAppSelector(state => state.cartSelectBasinCounty.cartItemsTotalPerMonth);
    const stateOptions = useAppSelector(state => state.cartSelectBasinCounty.stateOptions);
    const cartListItems = useAppSelector(state => state.cartSelectBasinCounty.cartListItems);
    const cartListItemsYearly = useAppSelector(state => state.cartSelectBasinCounty.cartListItemsYearly);
    const cartItemsTotalPerYear = useAppSelector(state => state.cartSelectBasinCounty.cartItemsTotalPerYear);
    const cartItemsTotalTax = useAppSelector(state => state.cartSelectBasinCounty.cartItemsTotalTax);
    const saved_card = useAppSelector(state => state.cartSelectBasinCounty.saved_card);
    const tax_percentage = useAppSelector(state => state.cartSelectBasinCounty.tax_percentage);
    const cartModified = useAppSelector(state => state.cartSelectBasinCounty.cartModified);
    const yearly = useAppSelector(state => state.cartSelectBasinCounty.yearly);
    const yearlyDiscountPercent = useAppSelector(state => state.cartSelectBasinCounty.yearlyDiscountPercent);
    const invoiceLineItems = useAppSelector(state => state.cartSelectBasinCounty.invoiceLineItems);
    const invoiceMonthlyCost = useAppSelector(state => state.cartSelectBasinCounty.invoiceMonthlyCost);
    const invoiceItemsTotal = useAppSelector(state => state.cartSelectBasinCounty.invoiceItemsTotal);
    const invoiceItemsSubtotal = useAppSelector(state => state.cartSelectBasinCounty.invoiceItemsSubtotal);
    const invoiceTimestamp = useAppSelector(state => state.cartSelectBasinCounty.invoiceTimestamp);
    const is_valid = useAppSelector(state => state.cartSelectBasinCounty.promoCodeDetails.is_valid);
    const discount_percent = useAppSelector(state => state.cartSelectBasinCounty.promoCodeDetails.discount_percent);
    const discount_fixed = useAppSelector(state => state.cartSelectBasinCounty.promoCodeDetails.discount_fixed);
    const minimum_amount = useAppSelector(state => state.cartSelectBasinCounty.promoCodeDetails.minimum_amount);
    const promoCode = useAppSelector(state => state.cartSelectBasinCounty.promoCodeDetails.promoCode);
    const upgrade_subscription = useAppSelector(state => state.subscriptionSettings.upgrade_subscription);
    const subscriptionData = useAppSelector(state => state.subscriptionSettings.subscriptionData);
    const inviteMemberDataLoading = useAppSelector(state => state.membersSettings.inviteMemberDataLoading);
    const inviteMembersData = useAppSelector(state => state.membersSettings.inviteMembersData);
    const unacceptedMemberInvitations = useAppSelector(state => state.membersSettings.unacceptedMemberInvitations);
    const subscribingToStripeLoading = useAppSelector(state => state.cartSelectBasinCounty.subscribingToStripeLoading);
    const calculateTaxLoading = useAppSelector(state => state.cartSelectBasinCounty.calculateTaxLoading);
    const stripe = useStripe();
    const elements = useElements();

    const cartListItemsMonthlyOrYearly = useMemo(() => {
        if (yearly) {
            return cartListItemsYearly
        }
        return cartListItems
    }, [yearly, cartListItems, cartListItemsYearly])
    const allowedSubUser = subscriptionData?.subscription?.allowed_user || 1;

    let cartTotalCost = subscriptionData
        ? Number(
            subscriptionData.details
                // .filter((item) => !item.unsubscribe_status)
                .reduce(
                    (accumulator, currentValue) =>
                        accumulator + Number(currentValue.total_cost),
                    0
                )
                .toFixed(2)
        )
        : 0;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        control,
        watch,
        trigger,
        getValues
    } = useForm<CheckOutFormData | CheckOutSavedCardFormData | CheckOutDowngradeSeatFormData | BankAccountUpdateCheckoutModalSubmitForm>({
        ...(saved_card.length === 0 && {
            resolver: yupResolver(creditCardTab ? CheckOutFormValidationSchema : CheckOutBankAccountFormValidationSchema),
        }),
        ...(saved_card.length > 0 && {
            resolver: yupResolver(creditCardTab ? CheckOutFormSavedCardValidationSchema : checkOutFormSavedCardBankAccValidationSchema),
        }),
        ...(downgradeValidation && {
            resolver: yupResolver(CheckOutFormDowngradeSeatValidationSchema),
        }),
        defaultValues: {
            saved_card: "-1"
        }
    });
    const savedCardRef = useRef(true);
    useEffect(() => {
        if (Array.isArray(saved_card) && saved_card.length && !creditCardTab && savedCardRef.current) {
            savedCardRef.current = false;
            setStateData((prev) => ({ ...prev, creditCardTab: true }));
        }
    }, [saved_card, creditCardTab])


    useEffect(() => {
        if ((!watch("saved_card") || watch("saved_card") === "-1") && saved_card) {
            saved_card.forEach((card) => {
                if (card.is_default_card) {
                    setValue("saved_card", card.id.toString())
                    return
                }
            })
        }
    }, [saved_card])

    let seat_count = useWatch({ control, name: "seat_count" });

    const paidSeatSpotsWithoutMembersAndInvites = useMemo(() => {
        const arrayLength = allowedSubUser - (unacceptedMemberInvitations || []).length - (inviteMembersData || []).length
        return Array.from({ length: arrayLength }, (_, index) => index + 1)
    }, [unacceptedMemberInvitations, inviteMembersData])

    const addRemoveMembers = useCallback((id: number) => {
        if (Number(seat_count) < allowedSubUser && (membersSelected.length + unacceptedInvitesSelected.length + uninvitedSelected.length) === Math.abs(Number(seat_count) - allowedSubUser) && !membersSelected.includes(id)) {
            toast.info("You are selecting more users than the downgraded seat value you have selected.", {
                toastId: "selecting_more_user_than_downgrading_seat"
            })
            return
        }
        if (membersSelected.includes(id)) {
            setStateData(prev => ({ ...prev, membersSelected: membersSelected.filter((item) => item !== id) }));
        } else {
            setStateData(prev => ({ ...prev, membersSelected: [...prev.membersSelected, id] }));
        }
    }, [allowedSubUser, membersSelected, seat_count, unacceptedInvitesSelected, uninvitedSelected]);

    const addRemoveUnacceptedInvites = useCallback((id: number) => {
        if (Number(seat_count) < allowedSubUser && (membersSelected.length + unacceptedInvitesSelected.length + uninvitedSelected.length) === Math.abs(Number(seat_count) - allowedSubUser) && !unacceptedInvitesSelected.includes(id)) {
            toast.info("You are selecting more users than the downgraded seat value you have selected.", {
                toastId: "selecting_more_user_than_downgrading_seat"
            })
            return
        }
        if (unacceptedInvitesSelected.includes(id)) {
            setStateData(prev => ({ ...prev, unacceptedInvitesSelected: unacceptedInvitesSelected.filter((item) => item !== id) }));
        } else {
            setStateData(prev => ({ ...prev, unacceptedInvitesSelected: [...prev.unacceptedInvitesSelected, id] }));
        }
    }, [allowedSubUser, membersSelected, seat_count, unacceptedInvitesSelected, uninvitedSelected]);

    const addRemoveUnclaimedSeats = useCallback((id: number) => {
        if (Number(seat_count) < allowedSubUser && (membersSelected.length + unacceptedInvitesSelected.length + uninvitedSelected.length) === Math.abs(Number(seat_count) - allowedSubUser) && !unacceptedInvitesSelected.includes(id)) {
            toast.info("You are selecting more users than the downgraded seat value you have selected.", {
                toastId: "selecting_more_user_than_downgrading_seat"
            })
            return
        }
        if (uninvitedSelected.includes(id)) {
            setStateData(prev => ({ ...prev, uninvitedSelected: uninvitedSelected.filter((item) => item !== id) }));
        } else {
            setStateData(prev => ({ ...prev, uninvitedSelected: [...prev.uninvitedSelected, id] }));
        }
    }, [allowedSubUser, membersSelected, seat_count, unacceptedInvitesSelected, uninvitedSelected]);



    const previewInvoicePayload: PreviewInvoiceLineItems = useMemo(() => {
        return {
            billing_email: billing_email || "",
            company_name: `${company_name}`,
            company: company_id as number,
            billing_cycle: yearly ? "yearly" : "monthly",
            billing_address: {
                line1: first_address,
                city: city,
                state: state,
                postal_code: zip_code,
                country: "US",
            },
            phone_no: phone_no,
            preview_invoice_line_items: true,
            seat_count: getValues("seat_count")
        }
    }, [yearly, billing_email, company_id, first_address, city, zip_code, phone_no, company_name, getValues("seat_count")])

    const submitForm = async (
        data: CheckOutFormData | CheckOutSavedCardFormData | CheckOutDowngradeSeatFormData | BankAccountUpdateCheckoutModalSubmitForm
    ) => {

        dispatch(showSiteLoader());
        dispatch(handleSubscribingToStripeLoading(true))
        // payment through credit card 
        let res;

        try {
            // create a payment method
            if ((saved_card.length === 0 || watch("saved_card") === "0") && !downgradeValidation && creditCardTab) {
                res = await stripe?.createPaymentMethod({
                    type: "card",
                    card: elements?.getElement(CardNumberElement)!,
                    billing_details: {
                        name: data.name,
                        email: data.billing_email,
                        address: {
                            line1: data.address,
                            city: data.city,
                            state: data.state,
                            postal_code: data.zip_code,
                            country: "US",
                        },
                    },
                });
                const { error } = (res as PaymentMethodResult | SetupIntentResult) || {};

                if (error) {
                    const { message } = error;
                    toast.error('Your payment details could not be confirmed.');
                    dispatch(hideSiteLoader());
                    return;
                }
            }
            // payment through bank account 
            if (!creditCardTab && (saved_card.length === 0 || watch("saved_card") === "0") && !downgradeValidation) {
                const response = await dispatch(fetchClientSecret(access_token))
                const { client_secret, status, msg } = response || {};
                if (status === 200 && client_secret) {
                    setStateData((prev) => ({ ...prev, clientSecret: client_secret }))
                    // Confirm payment with bank account details
                    stripe?.collectBankAccountForSetup({
                        clientSecret: client_secret,
                        params: {
                            payment_method_type: 'us_bank_account',
                            payment_method_data: {
                                billing_details: {
                                    name: `${first_name} ${last_name}`,
                                    email: email,
                                },
                            },
                        },
                        expand: ['payment_method'],
                    }).then(async (result: any) => {
                        if (result.error) {
                            console.error(result.error.message);
                            toast.error('Your bank accounts could not be linked.');
                        } else {
                            await dispatch(updateLinkedAccounts(access_token, client_secret, stripe))
                            dispatch(hideSiteLoader());
                        }
                        return
                    }).catch((err) => {
                        console.log("the error with getting financial connections ", err)
                        toast.error('Your bank accounts could not be linked');
                        dispatch(hideSiteLoader());
                        return
                    })
                } else {
                    toast.error('Your ACH Details could not be confirmed.');
                    dispatch(hideSiteLoader());
                    return
                }
            }
            const confirmPaymentFromStripe = async (paymentType: string, client_secret: string) => {
                let confirmPayment;
                if (paymentType == "card") {
                    confirmPayment =
                        await stripe?.confirmCardPayment(
                            client_secret
                        );
                } else {
                    confirmPayment = await stripe?.confirmUsBankAccountPayment(client_secret)
                }
                return confirmPayment
            }

            const paymentMethod = (res as PaymentMethodResult)?.paymentMethod;
            // call the backend to create subscription
            let creditCardFormData: CreateSubscriptionFormData = {
                billing_email: data.billing_email,
                company_name: `${company_name}`,
                payment_id: creditCardTab ? `${paymentMethod?.id}` : `${(res as PaymentIntentResult)?.paymentIntent?.payment_method}`,
                company: company_id as number,
                phone_no: phone_no,
                last4: `${paymentMethod?.card?.last4}`,
                exp_month: `${paymentMethod?.card?.exp_month}`,
                exp_year: `${paymentMethod?.card?.exp_year}`,
                brand: `${paymentMethod?.card?.brand}`,
                name_on_card: data.name,
                billing_cycle: yearly ? "yearly" : "monthly",
                seat_count: Number(data.seat_count as string),
                billing_address: {
                    line1: data.address,
                    city: data.city,
                    state: data.state,
                    postal_code: data.zip_code,
                    country: "US",
                },
                type: "Credit Card",
                failed_invoice_id: failedInvoiceId,
            }
            if (promoCode || data.promoCode) {
                creditCardFormData = {
                    ...creditCardFormData,
                    promo_code: promoCode || data.promoCode
                }
            }

            let achFormData: CreateSubThroughBankAccFormData = {
                billing_email: data.billing_email,
                company_name: `${company_name}`,
                payment_id: `${(res as SetupIntentResult)?.setupIntent?.payment_method}`,
                company: company_id as number,
                phone_no: phone_no,
                billing_cycle: yearly ? "yearly" : "monthly",
                seat_count: Number(data.seat_count as string),
                ...(seatModal && ({ new_item: false })),
                type: "ACH/Bank account",
                setup_intent_id: `${(res as SetupIntentResult)?.setupIntent?.id}`,
                billing_address: {
                    line1: data.address,
                    city: data.city,
                    state: data.state,
                    postal_code: data.zip_code,
                    country: "US",
                },
                proration_date: invoiceTimestamp,
                failed_invoice_id: failedInvoiceId,
            }
            if (promoCode || data.promoCode) {
                achFormData = {
                    ...achFormData,
                    promo_code: promoCode || data.promoCode
                }
            }
            const downgradeFormData: DowngradeSeatSubscriptionFormData = {
                billing_email: billing_email,
                company: company_id as number,
                phone_no: phone_no,
                billing_cycle: yearly ? "yearly" : "monthly",
                seat_count: Number(data.seat_count as string),
                remove_user: membersSelected,
                remove_invitation: unacceptedInvitesSelected,
                new_item: false,
                failed_invoice_id: failedInvoiceId,
            }

            const getSavedCardID = (idOrCCNo: string) => {
                const savedCardFilter = saved_card.filter(
                    (item) =>
                        Number(watch("saved_card")) ===
                        item.id
                )
                if (savedCardFilter && savedCardFilter.length > 0) {
                    if (idOrCCNo == "id") {
                        return savedCardFilter[0].id.toString()
                    }
                    return savedCardFilter[0].cc_no.toString()
                }
                return ""
            }
            let defaultFormData: CreateSubscriptionAlreadySubscribedFormData = {
                billing_email: data.billing_email,
                saved_card_id: getSavedCardID("id"),
                company: company_id as number,
                phone_no: phone_no,
                last4: getSavedCardID("cc_no"),
                billing_cycle: yearly ? "yearly" : "monthly",
                seat_count: Number(data.seat_count as string),
                proration_date: invoiceTimestamp,
                failed_invoice_id: failedInvoiceId,
            }
            if (promoCode || data.promoCode) {
                defaultFormData = {
                    ...defaultFormData,
                    promo_code: promoCode || data.promoCode
                }
            }
            if (
                (access_token && paymentMethod) ||
                (access_token && (watch("saved_card") && watch("saved_card") !== "-1")) ||
                (access_token && downgradeValidation) ||
                (access_token && (res as SetupIntentResult)?.setupIntent)
            ) {
                dispatch(
                    createOrUpdateSubscription(
                        access_token,
                        (paymentMethod || (res as PaymentIntentResult)?.paymentIntent?.payment_method) || (res as SetupIntentResult)?.setupIntent?.payment_method || watch("saved_card") === "0" && !downgradeValidation
                            ? creditCardTab ? creditCardFormData
                                : achFormData
                            : downgradeValidation ? downgradeFormData : defaultFormData
                    )).then(async (result: any) => {
                        if (result) {
                            const { status, data, msg } = result || {};
                            if (status === 200) {
                                if (data) {
                                    const { client_secret, payment_intent_type, stripe_invoice_id } = data;

                                    let hasInvoice: boolean = false
                                    let invoiceError: string = ''
                                    let invoiceId: string = ''
                                    /** We now connect the payment intent to the customer before hand, 
                                     * so when the subscription is modified they are charged and invoice
                                     * automatically, therefore, it's often 'successfully paid' and doesn't
                                     * need confirmation
                                     */
                                    if (client_secret == "verify_invoice") {
                                        const invoiceResponse = await hasInvoiceBeenCreated(stripe_invoice_id, dispatch)
                                        hasInvoice = invoiceResponse?.hasInvoice || false
                                        invoiceError = invoiceResponse?.invoiceError || ''
                                        invoiceId = invoiceResponse?.invoiceId || ''
                                        if (hasInvoice && !invoiceError) {
                                            await dispatch(loadUser())
                                        }
                                    } else if (client_secret !== "na") {

                                        const paymentType = payment_intent_type || creditCardTab
                                        const confirmPayment = await confirmPaymentFromStripe(paymentType, client_secret)
                                        if (confirmPayment?.error) {
                                            hasInvoice = false
                                            invoiceError = confirmPayment.error.message || ''
                                            if (invoiceError) {
                                                toast.error(
                                                    invoiceError
                                                );
                                            }
                                            dispatch(hideSiteLoader());
                                        } else if (confirmPayment?.paymentIntent?.status === "processing") {
                                            const invoiceResponse = await hasInvoiceBeenCreated(stripe_invoice_id, dispatch)
                                            hasInvoice = invoiceResponse?.hasInvoice || false
                                            invoiceError = invoiceResponse?.invoiceError || ''
                                            invoiceId = invoiceResponse?.invoiceId || ''
                                            dispatch(hideSiteLoader());
                                        } else if (confirmPayment?.paymentIntent?.status === "succeeded") {
                                            hasInvoice = true
                                            invoiceError = ''
                                            dispatch(hideSiteLoader());
                                        }
                                    }
                                    if (hasInvoice && !invoiceError) {
                                        dispatch(hideCheckOutModal());
                                        //log user action
                                        let action_log_detail = "";
                                        cartListItemsMonthlyOrYearly.map((_i, _indx) => "county_name" in _i ? (action_log_detail += `${_i.county_name} - ${_i.state_abbr} ${_indx === cartListItemsMonthlyOrYearly.length - 1 ? "" : "|"}`) : (action_log_detail += `${_i.basin_name} ${_indx === cartListItemsMonthlyOrYearly.length - 1 ? "" : "|"}`))
                                        dispatch(
                                            logUserAction({
                                                action_type: actionType['new_subscription'],
                                                action_log_detail
                                            })
                                        );

                                        toast.success(
                                            "Success! Check your email for the invoice."
                                        );
                                        // this function will call from api list upload in search page
                                        updateFilterAndUnmatchedDataAfterSub &&
                                            updateFilterAndUnmatchedDataAfterSub();

                                        //get the updated data of user after subscription   
                                        await dispatch(loadUser())

                                        dispatch(hideSiteLoader());
                                        seatModal && downgradeValidation && dispatch(membersSettingsActions.clearInviteMembers());
                                        subscriptionData !== null && dispatch(clearSubscriptionData())
                                        //Clear the existing table data to allow for a fresh reload. This will ensure that the new data, corresponding to the updated subscription, is properly loaded.
                                        // only when we have some subscription data
                                        if (subscriptionData !== null) {
                                            dispatch(handlePageChange(1));
                                            dispatch(clearProductionData());
                                            dispatch(clearWellsData());
                                            dispatch(clearRigsData());
                                        }
                                        navigate("/search");
                                        // get the new subscription data
                                        dispatch(fetchSubscriptionData(access_token))
                                    } else if (hasInvoice && invoiceError) {
                                        toast.error(invoiceError)
                                        dispatch(hideSiteLoader());
                                        dispatch(handleSubscribingToStripeLoading(false))
                                        setStateData((prev) => ({ ...prev, failedInvoiceId: invoiceId, failedPaymentMethodIds: [...failedPaymentMethodIds, watch("saved_card")] }))
                                    }
                                }
                            } else {
                                toast.error(msg);
                                dispatch(hideSiteLoader());
                                dispatch(handleSubscribingToStripeLoading(false))
                            }
                        }
                    })
            } else {
                dispatch(hideSiteLoader());
                dispatch(handleSubscribingToStripeLoading(false))
            }
        } catch (error) {
            dispatch(hideSiteLoader());
            dispatch(handleSubscribingToStripeLoading(false))
            console.log(error);
        }
    };

    const addressOnChange = (address: string) => {
        setStateData((prev) => ({ ...prev, address }));
    };

    const addressOnSelect = (address: string) => {
        let tempValue = address.split(",");
        let tempFirstAddress = [];
        for (let i = 1; i <= tempValue.length; i++) {
            switch (i) {
                case 1:
                    break;
                case 2:
                    setValue("state", tempValue[tempValue.length - i]?.trim());
                    trigger("state")
                    break;
                case 3:
                    setValue("city", tempValue[tempValue.length - i]?.trim());
                    trigger("city")
                    break;
                default:
                    tempFirstAddress.push(
                        tempValue[tempValue.length - i].trim()
                    );
            }
        }
        let formatedAddress = tempFirstAddress.reverse().join(",");
        setValue("address", formatedAddress);
        setStateData((prev) => ({
            ...prev,
            address: formatedAddress,
        }));
        geocodeByAddress(address)
            .then((results) => {
                const { address_components } = results[0];
                address_components.forEach((item) => {
                    if (item.types.includes("postal_code")) {
                        setValue("zip_code", item.long_name?.trim());
                        trigger("zip_code")
                    }
                });
            })
            .catch((error) => console.error("Error", error)).finally(() => {
                setStateData(
                    (prev) => ({
                        ...prev,
                        calcTax: true,
                    })
                );
            });

    };

    //closing the payment modal when no item is in list
    useLayoutEffect(() => {
        if (!cartModified && checkOutModal && !seatModal) {
            dispatch(hideCheckOutModal());
        }

        if (isEdit && cartListItemsMonthlyOrYearly.length > 0) {
            let tempValue = cartListItemsMonthlyOrYearly
                .filter(
                    (item) =>
                        item.is_deleted === false &&
                        item.subscription_details_id === null
                )
                .map((_item) => _item.price)
                .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                );
            setStateData((prev) => ({
                ...prev,
                newItemTotalInEditCase: Number(tempValue.toFixed(2)),
                newItemTotalTaxInEditCase: Number(
                    ((tempValue * tax_percentage) / 100).toFixed(2)
                ),
            }));
        }

        // eslint-disable-next-line
    }, [cartListItemsMonthlyOrYearly, tax_percentage]);

    const discountForPromoCodeCoupon = useMemo(() => {
        if (!is_valid) {
            return 0
        }
        const newDiscountForPromoCodeCoupon = discount_percent ? (Number(calculateDiscount(
            Number((yearly ? cartItemsTotalPerYear : cartItemsTotalPerMonth).toFixed(2)), (discount_percent as number)))) : (discount_fixed as number)

        return newDiscountForPromoCodeCoupon
    }, [discount_percent, yearly, cartItemsTotalPerYear, cartItemsTotalPerMonth, discount_fixed, invoiceLineItems])

    useEffect(() => {
        let calculateTaxPayload = {
            line_items: invoiceLineItems?.map(({ amount, quantity, tax_code, discounts }) => ({ amount, quantity, tax_code, discounts })) ?? [],
            first_address: watch("address"),
            city: watch("city"),
            state: watch("state"),
            zip_code: watch("zip_code"),
            country: watch("country"),
            billing_interval: yearly ? "Y" : "M",
            promo_code: promoCode
        };
        if ((((initialMount || (is_valid && discountForPromoCodeCoupon)) && calculateTaxPayload["first_address"] && calculateTaxPayload["city"] && calculateTaxPayload["state"] && calculateTaxPayload["zip_code"] && calculateTaxPayload["country"]) || (calcTax && !initialMount))) {
            dispatch(
                calculateTax(access_token, calculateTaxPayload)
            ).finally(() => {
                dispatch(hideSiteLoader())
            });
            setStateData((prev) => ({
                ...prev,
                ...(calcTax && { calcTax: !calcTax }),
                ...(initialMount && { initialMount: !initialMount }),
            }));
        } else {
            dispatch(hideSiteLoader());
        }
        // eslint-disable-next-line
    }, [calcTax, watch("address"), watch("city"), watch("state"), watch("zip_code"), watch("country"), discountForPromoCodeCoupon, is_valid]);

    useEffect(() => {
        setFormValues({
            seat_count: allowedSubUser
        }, setValue)
    }, [allowedSubUser])

    useEffect(() => {
        const getInvoiceLineItemsForChangingSeats = async () => {
            dispatch(showSiteLoader())
            /**We may need to find the new monthly cost to show on the Change Seats modal.
            The current display maps over the CompanySubscriptionItemV2 with thier amount. 
            Here I'm creating an object with key being CompanySubscriptionItemV2 id's and the
            values being the potential updated monthly cost.  */
            try {
                const res = await dispatch(createOrUpdateSubscription(access_token, previewInvoicePayload))
                if (res.status === 200 && res.data.invoice_line_item_payload) {
                    dispatch(setInvoiceLineItemsAndTotal(res.data.invoice_line_item_payload));
                    let calculateTaxPayload = {
                        line_items: res.data.invoice_line_item_payload.invoiceLineItems?.map(({ amount, quantity, tax_code, discounts }) => ({ amount, quantity, tax_code, discounts })) ?? [],
                        first_address: watch("address"),
                        city: watch("city"),
                        state: watch("state"),
                        zip_code: watch("zip_code"),
                        country: watch("country"),
                        billing_interval: yearly ? "Y" : "M"
                    };
                    await dispatch(calculateTax(access_token, calculateTaxPayload));
                }
            } catch (err) {
                console.log("the error with POST to /susbsciption for Invoice Items is", err)
            } finally {
                dispatch(hideSiteLoader())
            }

        }
        if (getValues("seat_count") && Number(getValues("seat_count")) > allowedSubUser) {
            getInvoiceLineItemsForChangingSeats()
        }
    }, [allowedSubUser, getValues("seat_count")])

    useEffect(() => {
        stateOptions.length === 0 && dispatch(getStateList(access_token));
        dispatch(fetchCompanySettingDetail(access_token, true));
        if (inviteMemberDataLoading && location.pathname === subscriptionPathname && seatModal) {
            dispatch(
                loadInviteMembers(access_token, {
                    keyword: "",
                }, true)
            );
        }
        return () => {
            dispatch(cartSelectBasinCountyActions.handlePromoCodeDetails({ reset: true }))
            dispatch(clearCartItemsTotalTax());
            dispatch(clearSavedCardDetails());
            dispatch(clearCompanySettingsDetails());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (company_id) {
            setStateData((prev) => ({
                ...prev,
                address: `${first_address}`,
            }));
            setFormValues(
                {
                    name: `${first_name} ${last_name}`,
                    billing_email: billing_email,
                    address: `${first_address}`,
                    city,
                    zip_code,
                    country: "US",
                    state,
                },
                setValue
            );
        }
        // eslint-disable-next-line
    }, [company_id]);

    useEffect(() => {
        if (stateOptions.length > 0) setValue("state", state);
        // eslint-disable-next-line
    }, [stateOptions]);

    useEffect(() => {
        setStateData((prev) => ({
            ...prev,
            ...(prev.showMembersDropDown && { showMembersDropDown: !prev.showMembersDropDown }),
            ...(prev.membersSelected.length && {
                membersSelected: []
            }),
            downgradeValidation: seat_count && Number(seat_count) < allowedSubUser ? true : false

        }))
        if (seatModal) {
            if (Number(seat_count) > 10) {
                toast.info(<>
                    Please reach out to <a
                        href={`mailto:datasales@ed.com`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#fff" }}
                    >
                        datasales@energydomain.com
                    </a> to request more than 10 seats."
                </>, {
                    toastId: "greater_10_seat_info",
                })
                return
            };
            if (Number(seat_count) < 1) {
                toast.info(`Seat selection must be at least 1.`, {
                    toastId: "less_than_1_seat_Info"
                })
                return
            }
        }
        // eslint-disable-next-line
    }, [seat_count]);
    //total seat cost
    let totalSeatCost = Number((cartTotalCost * (Number(seat_count))).toFixed(2));

    const taxesForInvoiceOrCart = useMemo(() => {
        if (cartItemsTotalTax) {
            return Number(cartItemsTotalTax)
        }
        return 0

    }, [yearly, free_trial_period_enabled, trial_expired, cartItemsTotalPerYear, tax_percentage, cartItemsTotalTax])

    const yearlyDiscountFraction = useMemo(() => {
        return (100 - yearlyDiscountPercent) / 100
    }, [yearlyDiscountPercent])

    const subtotalForInvoiceOrCart = useMemo(() => {
        if ((free_trial_period_enabled && !trial_expired) || subscription_status === "in-active") {
            return USDollar.format(
                Number((yearly ? cartItemsTotalPerYear / yearlyDiscountFraction : cartItemsTotalPerMonth).toFixed(2))
            )
        }
        if (invoiceItemsSubtotal) {
            return USDollar.format(
                Number((yearly ? Number(invoiceItemsSubtotal) / yearlyDiscountFraction : Number(invoiceItemsSubtotal)).toFixed(2))
            )
        }
        return ''
    }, [yearly, yearlyDiscountFraction, free_trial_period_enabled, trial_expired, cartItemsTotalPerYear, cartItemsTotalPerMonth, invoiceItemsSubtotal, subscription_status])

    const discountForInvoiceOrCart = useMemo(() => {
        if ((free_trial_period_enabled && !trial_expired) || subscription_status === "in-active") {
            return USDollar.format(Number(((yearly ? cartItemsTotalPerYear / yearlyDiscountFraction : cartItemsTotalPerMonth) * (1 - yearlyDiscountFraction)).toFixed(2)))
        }
        if (invoiceItemsSubtotal) {
            return USDollar.format(Number((Number((yearly ? Number(invoiceItemsSubtotal) / yearlyDiscountFraction : Number(invoiceItemsSubtotal)).toFixed(2)) * (1 - yearlyDiscountFraction)).toFixed(2)))
        }
        return ''
    }, [yearly, free_trial_period_enabled, trial_expired, cartItemsTotalPerYear, invoiceItemsSubtotal, subscription_status, yearlyDiscountFraction])


    const totalForInvoiceOrCart = useMemo(() => {
        if ((free_trial_period_enabled && !trial_expired) || subscription_status === "in-active") {
            return USDollar.format(Number(((yearly ? cartItemsTotalPerYear : cartItemsTotalPerMonth) + taxesForInvoiceOrCart - discountForPromoCodeCoupon).toFixed(2)))
        }
        if (invoiceItemsTotal) {
            return USDollar.format(Number((invoiceItemsTotal - discountForPromoCodeCoupon + taxesForInvoiceOrCart).toFixed(2)))
        }
    }, [free_trial_period_enabled, free_trial_period_enabled, trial_expired, yearly, cartItemsTotalPerYear, cartItemsTotalPerMonth, cartItemsTotalTax, invoiceItemsTotal, discountForPromoCodeCoupon, subscription_status])

    const totalMonthlyCostForSeats = useMemo(() => {
        const keys = Object.keys(invoiceMonthlyCost || {})
        if (keys) {
            return Object.values(invoiceMonthlyCost || {}).reduce((acc, value) => acc + Number(value) * Number(seat_count), 0)
        }
        return 0
    }, [invoiceMonthlyCost])

    //next billing cost after discount
    let nextBillingCost = totalSeatCost - Number(calculateDiscount(totalSeatCost, seatDiscountPercentage(seat_count as string)));
    let additionalSeatCost = (Number((cartTotalCost * (Number(seat_count) - allowedSubUser)).toFixed(2)));
    let additionalSeatCostDiscount = Number(calculateDiscount(additionalSeatCost, seatDiscountPercentage(seat_count as string)));
    let additionalSeatCostTax = Number(calculateDiscount((additionalSeatCost - additionalSeatCostDiscount), tax_percentage));

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;

        // Allow number keys, backspace, delete, tab, enter, and arrow keys
        if (
            (key >= '0' && key <= '9') ||
            key === 'Backspace' ||
            key === 'Tab'
        ) {
            // Get the current input element
            const input = e.currentTarget;

            // Get the selection start and end positions
            const selectionStart = input.selectionStart;
            const selectionEnd = input.selectionEnd;

            // Calculate what the new value would be
            let newValue = input.value;
            if (selectionStart !== null && selectionEnd !== null && selectionStart !== selectionEnd) {
                // If text is selected, replace it with the new key
                newValue = input.value.slice(0, selectionStart) + key + input.value.slice(selectionEnd);
            } else {
                // If no text is selected, concatenate as before
                newValue = input.value + key;
            }

            // Convert new value to a number and check if it is in the range 1-10
            const numValue = Number(newValue);
            if (numValue < 1 || numValue > 10) {
                toast.info("Seats value must be greater than 1 and less than 10.")
                e.preventDefault(); // Prevent input if the value is out of range
            }
            return; // Allow the key press if valid
        }

        // Prevent all other keys
        e.preventDefault();
    };

    const adminMemberList = (inviteMembersData || []).filter(
        (item) =>
            item.signin_role ===
            ADMIN_CONSTANT && item.is_active
    )

    const getPaymentMethodClassName = (paymentMethodId: string) => {
        if (failedPaymentMethodIds.includes(paymentMethodId)) {
            return 'disabled'
        }
        return ''
    }

    return (
        <>
            {!seatModal ?
                //checkout modal at cart page
                <Modal
                    className="commonModal paymentModal"
                    enforceFocus={false}
                    show={checkOutModal}
                >
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            reset();
                            onCancelBtnClick();
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <form
                        className="form-block"
                        onSubmit={handleSubmit(submitForm)}
                        autoComplete="off"
                        autoCapitalize="off"
                    >
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Billing information</h3>
                                    <div className="box">
                                        {Array.isArray(saved_card) &&
                                            !(saved_card.length > 0) ? <div className="paymentType">
                                            <ul>
                                                <li className={!creditCardTab ? "active" : ""}>
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (creditCardTab) {
                                                            ["accountType", "accountHolderType", "accountNumber", "routingNumber"].map((input_name) => (setValue(input_name, undefined)));

                                                            creditCardTab && setStateData((prev) => ({ ...prev, creditCardTab: false }))
                                                        }
                                                    }}>
                                                        <i className="fa-solid fa-building-columns"></i>
                                                        <span>ACH / US bank account</span>
                                                    </a>
                                                </li>
                                                <li className={creditCardTab ? "active" : ""}>
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!creditCardTab) {
                                                            ["cardNumber", "cardCvc", "cardExpiry"].map((input_name) => (setValue(input_name, undefined)));
                                                            setStateData((prev) => ({ ...prev, creditCardTab: true }))
                                                        }

                                                    }}>
                                                        <i className="fa-regular fa-credit-card"></i>{" "}
                                                        <span>Credit card</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> : <></>}




                                        <div className="form-group">
                                            <InputComponent
                                                label="Billing email"
                                                name="billing_email"
                                                placeholder="Enter your billing email"
                                                register={register}
                                                errorMsg={
                                                    errors?.billing_email?.message as string
                                                }
                                            />
                                        </div>
                                        {Array.isArray(saved_card) &&
                                            saved_card.length > 0 && (
                                                <div className="form-group">
                                                    <label>Select payment method</label>
                                                    <div className="paymentOption">
                                                        {saved_card.map(
                                                            (item, index) => {
                                                                return (
                                                                    <div
                                                                        className="form-check"
                                                                        key={index}
                                                                    >
                                                                        <RadioInputComponent
                                                                            className={getPaymentMethodClassName(item.id.toString())}
                                                                            value={
                                                                                item.id
                                                                            }
                                                                            name={
                                                                                "saved_card"
                                                                            }
                                                                            register={
                                                                                register
                                                                            }
                                                                            id={`${item.id}`}
                                                                            label={`XXXX XXXX XXXX ${item.cc_no}`}
                                                                            labelClassName="form-check-label"
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div className="form-check">
                                                            <RadioInputComponent
                                                                value={0}
                                                                name={"saved_card"}
                                                                register={register}
                                                                id={`0`}
                                                                label={`New`}
                                                                labelClassName="form-check-label"
                                                            />
                                                        </div>
                                                    </div>
                                                    <span className={`error`}>
                                                        {`${"saved_card" in errors
                                                            ? errors?.saved_card
                                                                ?.message
                                                            : ""
                                                            }`}
                                                    </span>
                                                </div>
                                            )}
                                        {watch("saved_card") === "0" ? <div className="paymentType">
                                            <ul>
                                                <li className={!creditCardTab ? "active" : ""}>
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (creditCardTab) {
                                                            ["accountType", "accountHolderType", "accountNumber", "routingNumber"].map((input_name) => (setValue(input_name, undefined)));

                                                            creditCardTab && setStateData((prev) => ({ ...prev, creditCardTab: false }))
                                                        }
                                                    }}>
                                                        <i className="fa-solid fa-building-columns"></i>
                                                        <span>ACH / US bank account</span>
                                                    </a>
                                                </li>
                                                <li className={creditCardTab ? "active" : ""}>
                                                    <a href="javascript:void(0)" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!creditCardTab) {
                                                            ["cardNumber", "cardCvc", "cardExpiry"].map((input_name) => (setValue(input_name, undefined)));
                                                            setStateData((prev) => ({ ...prev, creditCardTab: true }))
                                                        }

                                                    }}>
                                                        <i className="fa-regular fa-credit-card"></i>{" "}
                                                        <span>Credit card</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> : <></>}
                                        {(watch("saved_card") === "0" ||
                                            (Array.isArray(saved_card) &&
                                                saved_card.length === 0)) && (
                                                <>
                                                    {creditCardTab ? <>
                                                        <div className="form-group">
                                                            <label>Card details</label>
                                                            <div className="cardBrand">
                                                                <CardNumberInput
                                                                    control={control}
                                                                    name={"cardNumber"}
                                                                />
                                                            </div>
                                                            <span className="error">{`${"cardNumber" in errors
                                                                ? errors?.cardNumber
                                                                    ?.message
                                                                : ""
                                                                }`}</span>
                                                            <div className="cardNum">
                                                                <div className="cardInput">
                                                                    <CardExpiryInput
                                                                        control={control}
                                                                        name="cardExpiry"
                                                                        errorMsg={`${"cardExpiry" in
                                                                            errors
                                                                            ? errors
                                                                                ?.cardExpiry
                                                                                ?.message
                                                                            : ""
                                                                            }`}
                                                                    />
                                                                </div>
                                                                <div className="cardInput">
                                                                    <CardCvcInput
                                                                        name="cardCvc"
                                                                        errorMsg={`${"cardCvc" in errors
                                                                            ? errors
                                                                                ?.cardCvc
                                                                                ?.message
                                                                            : ""
                                                                            }`}
                                                                        control={control}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : <></>}
                                                    <div className="form-group">
                                                        <label>Billing address </label>
                                                        <div className="selectInput mb-1">
                                                            {/* <i className="fa-solid fa-angle-down"></i> */}
                                                            <SelectInput
                                                                placeholder="Country"
                                                                name="country"
                                                                register={register}
                                                                options={[
                                                                    {
                                                                        label: "United States",
                                                                        value: "US",
                                                                    },
                                                                ]}
                                                                errorMsg={
                                                                    errors?.country?.message as string
                                                                }
                                                            />
                                                        </div>
                                                        <LocationSearchInput
                                                            name={`address`}
                                                            valueLoc={address}
                                                            errorMsg={
                                                                errors?.address?.message as string || ""
                                                            }
                                                            onChangeLoc={addressOnChange}
                                                            onSelect={addressOnSelect}
                                                            control={control}
                                                            debounce={500}
                                                            searchOptions={{
                                                                componentRestrictions: {
                                                                    country: ["usa", "us"],
                                                                },
                                                                types: [],
                                                            }}
                                                            placeholder="Address"
                                                            shouldFetchSuggestions={
                                                                address?.length >= 3
                                                            }
                                                            title={address}
                                                        />
                                                        <div className="cardNum">
                                                            <div className="cardInput">
                                                                <InputComponent
                                                                    name="city"
                                                                    placeholder="City"
                                                                    register={register}
                                                                    errorMsg={
                                                                        errors.city?.message as string
                                                                    }
                                                                    onBlur={(
                                                                        e: React.FocusEvent<HTMLInputElement>
                                                                    ) => {
                                                                        let value =
                                                                            e.target.value.trim();
                                                                        if (
                                                                            value &&
                                                                            value !== city
                                                                        ) {
                                                                            setStateData(
                                                                                (prev) => ({
                                                                                    ...prev,
                                                                                    calcTax:
                                                                                        true,
                                                                                })
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="cardInput">
                                                                <InputComponent
                                                                    name="zip_code"
                                                                    placeholder="Zip code"
                                                                    register={register}
                                                                    errorMsg={
                                                                        errors.zip_code
                                                                            ?.message as string
                                                                    }
                                                                    onBlur={(
                                                                        e: React.FocusEvent<HTMLInputElement>
                                                                    ) => {
                                                                        let value =
                                                                            e.target.value.trim();
                                                                        if (
                                                                            value &&
                                                                            value !==
                                                                            zip_code
                                                                        ) {
                                                                            setStateData(
                                                                                (prev) => ({
                                                                                    ...prev,
                                                                                    calcTax:
                                                                                        true,
                                                                                })
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="selectInput mt-1">
                                                            {/* <i className="fa-solid fa-angle-down"></i> */}
                                                            <SelectInput
                                                                placeholder="State"
                                                                name="state"
                                                                register={register}
                                                                options={stateOptions}
                                                                errorMsg={
                                                                    errors.state?.message as string
                                                                }
                                                                onBlur={(
                                                                    e: React.FocusEvent<
                                                                        HTMLInputElement,
                                                                        Element
                                                                    >
                                                                ) => {
                                                                    let value =
                                                                        e.target.value.trim();
                                                                    setValue('state', value);
                                                                    if (
                                                                        value &&
                                                                        value !== state
                                                                    ) {
                                                                        setStateData(
                                                                            (prev) => ({
                                                                                ...prev,
                                                                                calcTax:
                                                                                    true,
                                                                            })
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>

                                    <div className={"promoCode"}>
                                        <div className="custom-checkbox mb-4">
                                            <InputComponent
                                                name="taxExempt"
                                                className="checkmark"
                                                type="checkbox"
                                                id="taxExempt"
                                                register={register}
                                                errorMsg={
                                                    errors.taxExempt?.message as string
                                                }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setValue('taxExempt', e.target.checked);
                                                    setStateData((prev) => ({ ...prev, taxExempt: e.target.checked }));
                                                }}
                                            />
                                            <label htmlFor="taxExempt" className="custom-label"> My organization is tax exempt</label>
                                        </div>
                                        {taxExempt ? <div className={"form-group"}>
                                            <InputComponent
                                                label="Sales tax exemption"
                                                htmlFor="sales_tax_id"
                                                labelClassName="salesTaxExemption"
                                                name="sales_tax_id"
                                                id="sales_tax_id"
                                                placeholder="Enter your Tax ID"
                                                register={register}
                                                errorMsg={
                                                    errors.sales_tax_id?.message as string
                                                }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setValue('sales_tax_id', e.target.value)
                                                }}
                                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                                    let value = e.target.value.trim();
                                                    const isValid = salesTaxIdRegex.test(value);
                                                    if (!isValid && value) {
                                                        toast.error(`The sales tax id is invalid.`, {
                                                            toastId: "tax_id_is_invalid",
                                                        })
                                                        return;
                                                    };
                                                    value && dispatch(saveAndUpdateTaxExemptID(value))
                                                }}
                                            />
                                        </div> : <></>}
                                        {Number(seat_count) > allowedSubUser ? <></> : <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    Add promo code
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="promoCodeinfo">
                                                        <div className={`addpromoForm ${is_valid === "Valid" ? "d-none" : ""}`}>
                                                            {/* <input type="text" {...register("promoCode")} className="form-control" placeholder="Enter code" /> */}
                                                            <InputComponent
                                                                name="promoCode"
                                                                placeholder="Enter code"
                                                                register={register}
                                                            />
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    if (!getValues('promoCode')) {
                                                                        toast.info("Please enter promo code.")
                                                                        return
                                                                    }
                                                                    if (promoCodeValidating) {
                                                                        return
                                                                    }
                                                                    if (getValues('promoCode')) {
                                                                        setStateData((prev) => ({ ...prev, promoCodeValidating: true }));
                                                                        dispatch(validatePromoCode(access_token, { promo_code: getValues('promoCode') as string, cartTotal: cartItemsTotalPerMonth })).finally(() => {
                                                                            setValue('promoCode', "")
                                                                            setStateData((prev) => ({ ...prev, promoCodeValidating: false }));
                                                                        })
                                                                    }

                                                                }}
                                                            >{promoCodeValidating ? "Validating Promo Code" : "Apply"}</button>
                                                        </div>
                                                        <div className={is_valid === "Valid" ? "blastOff" : "d-none"}>
                                                            <i className="fa-solid fa-circle-check"></i>
                                                            <div className="blast-info">
                                                                {promoCode}
                                                                <span>{`${discount_percent ? `${discount_percent}% ` : ""}`}discount (-{discount_percent ? (Number(calculateDiscount(
                                                                    (Number((cartItemsTotalPerMonth * (yearly ? MONTHS : 1)).toFixed(2))
                                                                        -
                                                                        (yearly
                                                                            ?
                                                                            Number(((cartItemsTotalPerMonth * MONTHS * yearlyDiscountPercent) / 100).toFixed(2))
                                                                            :
                                                                            0)),
                                                                    (discount_percent as number)))) : discount_fixed || minimum_amount || 0})</span>
                                                            </div>
                                                            <i className="fa-solid fa-xmark" onClick={() => {
                                                                dispatch(cartSelectBasinCountyActions.handlePromoCodeDetails({ reset: true }))
                                                            }}></i>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="flex-column-100">
                                        <h3>Order Summary</h3>
                                        <div className="box p-0 order-summary-sidebar">
                                            <div className="price-list scrollSection">
                                                {(free_trial_period_enabled && !trial_expired) || subscription_status === "in-active" ? (<ul>
                                                    {isEdit && (
                                                        <li className="header">
                                                            <div className="content">
                                                                <div className="small-map">
                                                                    &nbsp;
                                                                </div>
                                                                <div className="description">
                                                                    Geographic Area
                                                                </div>
                                                            </div>
                                                            <div className="price">
                                                                Recurring Totals
                                                            </div>
                                                            <div className="price">
                                                                Subtotal
                                                            </div>
                                                        </li>
                                                    )}
                                                    {cartListItemsMonthlyOrYearly.map((item, index) => (
                                                        <li key={index}>
                                                            <div className="content">
                                                                {item.subscription_details_id ===
                                                                    null && (
                                                                        <a
                                                                            className="cross-btn"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                let sub_total =
                                                                                    cartItemsTotalPerMonth -
                                                                                    item.price;
                                                                                removeBasinOrCounty(
                                                                                    item.id,
                                                                                    "basin_name" in
                                                                                        item
                                                                                        ? 1
                                                                                        : 2,
                                                                                    sub_total
                                                                                );
                                                                            }}
                                                                            href="void(0)"
                                                                        >
                                                                            <i className="fa-regular fa-circle-xmark"></i>
                                                                        </a>
                                                                    )}
                                                                <div className="small-map">
                                                                    <img
                                                                        src={"plan_name" in item && item.plan_name ? `${process.env.REACT_APP_ED_DATA_CDN_API}/company_logo/${item.png}` : item.png}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="description">
                                                                    <h4>
                                                                        {"plan_name" in item && item.plan_name ? item.plan_name : "basin_name" in
                                                                            item
                                                                            ? `${item.basin_name}`
                                                                            : `${item.county_name}`}
                                                                    </h4>
                                                                    <p
                                                                        className={
                                                                            isEdit
                                                                                ? item.is_deleted
                                                                                    ? "red"
                                                                                    : item.subscription_details_id
                                                                                        ? ""
                                                                                        : "green"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {isEdit === false
                                                                            ? `${yearly ? "Yearly" : "Monthly"} subscription`
                                                                            : item.is_deleted
                                                                                ? `Cancelled Active Untill: ${moment(
                                                                                    item.end_period
                                                                                ).format(
                                                                                    "MMM-DD-YYYY"
                                                                                )}`
                                                                                : item.subscription_details_id ===
                                                                                    null
                                                                                    ? `New ${yearly ? "yearly" : "monthly"} subscription`
                                                                                    : `Current ${yearly ? "yearly" : "monthly"} subscription`}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="price">
                                                                {item.is_deleted === true
                                                                    ? USDollar.format(0)
                                                                    : USDollar.format(
                                                                        yearly ? item.price / 0.9 : item.price
                                                                    )}
                                                            </div>
                                                            {isEdit && (
                                                                <div className="price">
                                                                    {item.subscription_details_id !==
                                                                        null
                                                                        ? USDollar.format(0)
                                                                        : USDollar.format(
                                                                            yearly ? item.price / 0.9 : item.price
                                                                        )}
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>) : <InventoryLineItemTable />}

                                            </div>
                                            <div className="total-block">
                                                <TotalSubtotalBillNow
                                                    isEdit={isEdit}
                                                    newItemTotalInEditCase={newItemTotalInEditCase}
                                                    newItemTotalTaxInEditCase={newItemTotalInEditCase}
                                                    totalForInvoiceOrCart={totalForInvoiceOrCart}
                                                    discountForPromoCodeCoupon={discountForPromoCodeCoupon}
                                                    taxesForInvoiceOrCart={calculateTaxLoading ? 'Calculating Taxes' : USDollar.format(Number(taxesForInvoiceOrCart))}
                                                    subtotalForInvoiceOrCart={subtotalForInvoiceOrCart}
                                                    discountForInvoiceOrCart={discountForInvoiceOrCart} />
                                                {!isEdit ? (
                                                    <button
                                                        type="submit"
                                                        className={`btn btn-primary width100 ${calculateTaxLoading || subscribingToStripeLoading || failedPaymentMethodIds.includes(watch("saved_card")) ? "disabled" : ''}`}
                                                    >
                                                        {(saved_card.length === 0 || watch("saved_card") === "0") && !creditCardTab ? 'Link your bank account' : 'Subscribe'}
                                                    </button>
                                                ) : (
                                                    <div className="action-btn">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline"
                                                            onClick={() =>
                                                                dispatch(
                                                                    hideCheckOutModal()
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type={
                                                                cartListItemsMonthlyOrYearly.filter(
                                                                    (item) =>
                                                                        item.is_deleted ===
                                                                        true
                                                                ).length ===
                                                                    cartListItemsMonthlyOrYearly.length
                                                                    ? "button"
                                                                    : "submit"
                                                            }
                                                            onClick={() => {
                                                                cartListItemsMonthlyOrYearly.filter(
                                                                    (item) =>
                                                                        item.is_deleted ===
                                                                        true
                                                                ).length ===
                                                                    cartListItemsMonthlyOrYearly.length &&
                                                                    dispatch(
                                                                        cancelSubscription(
                                                                            access_token
                                                                        )
                                                                    ).then((result) => {
                                                                        if (result) {
                                                                            const {
                                                                                status,
                                                                                msg,
                                                                            } = result;
                                                                            if (
                                                                                status ===
                                                                                200
                                                                            ) {
                                                                                toast.success(
                                                                                    msg
                                                                                );
                                                                                dispatch(
                                                                                    clearCartItemsList()
                                                                                );
                                                                                dispatch(
                                                                                    hideCheckOutModal()
                                                                                );
                                                                            } else {
                                                                                toast.error(
                                                                                    msg
                                                                                );
                                                                            }
                                                                        }
                                                                    });
                                                            }}
                                                            className={`btn btn-primary`}
                                                        >
                                                            {cartListItemsMonthlyOrYearly.filter(
                                                                (item) =>
                                                                    item.is_deleted === true
                                                            ).length ===
                                                                cartListItemsMonthlyOrYearly.length
                                                                ? "Cancel Subscription"
                                                                : "Update Subscription"}{" "}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </form>
                </Modal >
                :
                //seat count modal
                <Modal
                    className="commonModal paymentModal"
                    enforceFocus={false}
                    show={checkOutModal}
                >
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                            reset();
                            onCancelBtnClick();
                        }}
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <form
                        className="form-block"
                        onSubmit={handleSubmit(submitForm)}
                        autoComplete="off"
                        autoCapitalize="off"
                    >
                        <Modal.Body>
                            <div className="row" onClick={() => {
                                stateData["showMembersDropDown"] && setStateData((prev) => ({ ...prev, showMembersDropDown: false }))
                            }}>
                                <div className="col-md-6">
                                    <h3>Edit Seats</h3>
                                    <div className="seatsBlock">
                                        <div className="currentseats">
                                            Current Seats
                                            <strong>{allowedSubUser}
                                                <span className={Number(seat_count) > allowedSubUser ? "" : "d-none"}>+{Number(seat_count) - allowedSubUser}
                                                </span> <span className={seat_count && Number(seat_count) < allowedSubUser ? "red-clr" : "d-none"}>{Number(seat_count) - allowedSubUser}
                                                </span></strong>
                                        </div>
                                        <div className="needseats">
                                            <h5>How many seats do you need?</h5>
                                            <div className="needform">
                                                <button type="button" className="btn btn-primary active" onClick={() => {
                                                    if (seat_count) {
                                                        if (Number(seat_count) <= 1) {
                                                            toast.info(`Seat selection must be at least 1.`, {
                                                                toastId: "less_than_1_seat"
                                                            })
                                                            return
                                                        }
                                                        setStateData((prev) => ({
                                                            ...prev,
                                                            ...(prev.showMembersDropDown && { showMembersDropDown: !prev.showMembersDropDown }),
                                                            ...(prev.membersSelected.length && {
                                                                membersSelected: []
                                                            })
                                                        }));
                                                        setValue("seat_count", `${Number(seat_count) - 1}`)
                                                    }
                                                }}><i className="fa-solid fa-minus"></i></button>
                                                <InputComponent
                                                    name="seat_count"
                                                    register={register}
                                                    onKeyDown={handleKeyDown}
                                                    className={(Number(seat_count) < allowedSubUser && Number(seat_count) >= 1) ? "highlight" : ""}
                                                />
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        if (seat_count) {
                                                            if (Number(seat_count) >= 10) {
                                                                toast.info(<>
                                                                    Please reach out to <a
                                                                        href={`mailto:datasales@ed.com`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ color: "#fff" }}
                                                                    >
                                                                        datasales@energydomain.com
                                                                    </a> to request more than 10 seats."
                                                                </>, {
                                                                    toastId: "greater_10_seat",
                                                                })
                                                                return
                                                            }
                                                            setStateData((prev) => ({
                                                                ...prev,
                                                                ...(prev.showMembersDropDown && { showMembersDropDown: !prev.showMembersDropDown }),
                                                                ...(prev.membersSelected.length && {
                                                                    membersSelected: []
                                                                })
                                                            }))
                                                            setValue("seat_count", `${Number(seat_count) + 1}`)
                                                        }
                                                    }} ><i className="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                    <h3>Payment Method</h3>
                                    <>
                                        {Number(seat_count) < allowedSubUser && Number(seat_count) >= 1 ? <div className="box warningBox">
                                            <div className="warningBlock">
                                                <div className="icon"><img src="../images/warning.svg" alt="" /></div>
                                                <div className="content">To downgrade to this seat count, you must first select a team members and/or pending invites from your current plan to be removed at the end of your billing cycle.</div>
                                            </div>
                                            <div className="selectBlock show">
                                                <div className={`selectInput ${stateData['showMembersDropDown'] ? "show" : ""}`}>
                                                    <input type="button" className="form-control" value="Select a team member" onClick={toggleMembersDropDown} />
                                                    <div className="slectdropDown scrollSection">
                                                        <ul>
                                                            {
                                                                // If the user is trying to downgrade, then they need to pick who they are going to 
                                                                // remove from the next cycle.  In most cases, they will have already invited someone,
                                                                // and that someone has become a user (inviteMembersData).  But in other cases, they may
                                                                // have just sent an invitation, (which is not been accepted).  Lastly, they may have 
                                                                // added a seat, not sent an invitation, and want to just downgrade this blank space


                                                                (paidSeatSpotsWithoutMembersAndInvites || [])?.map((uninvited: number) => {
                                                                    return <li key={uninvited} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleMembersDropDown()
                                                                    }}>
                                                                        <div className="checkblock">
                                                                            <div className="custom-checkbox">
                                                                                <input className="form-control checkmark"
                                                                                    checked={uninvitedSelected.includes(uninvited) ? true : false}
                                                                                    onClick={(e) => {
                                                                                        addRemoveUnclaimedSeats(uninvited)
                                                                                    }} type="checkbox" id={`${uninvited}`} />
                                                                                <label onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                }} htmlFor={`${uninvited}`} className="custom-label"><span>(unclaimed seat)</span></label>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="warning-name">{unacceptedInvite.invite_as === ADMIN.toString() ? 'Admin' : 'Member'}</div> */}
                                                                    </li>
                                                                })
                                                            }
                                                            {
                                                                (unacceptedMemberInvitations || [])?.map((unacceptedInvite: UnacceptedMemberInvitesRowDataType, index: number) => {
                                                                    return <li key={index + unacceptedInvite['id']} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleMembersDropDown()
                                                                    }}>
                                                                        <div className="checkblock">
                                                                            <div className="custom-checkbox">
                                                                                <input className="form-control checkmark"
                                                                                    checked={unacceptedInvitesSelected.includes(unacceptedInvite['id']) ? true : false}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        addRemoveUnacceptedInvites(unacceptedInvite['id'])
                                                                                    }} type="checkbox" id={`${unacceptedInvite['id']}`} />
                                                                                <label onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                }} htmlFor={`${unacceptedInvite['id']}`} className="custom-label">{`${unacceptedInvite['first_name']} ${unacceptedInvite['last_name']} `} <span>(pending)</span></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="warning-name">{unacceptedInvite.invite_as === ADMIN.toString() ? 'Admin' : 'Member'}</div>
                                                                    </li>
                                                                })
                                                            }
                                                            {
                                                                (inviteMembersData || [])?.filter(_members => _members.is_active).map((members: InviteMembersRowDataType, index: number) => {
                                                                    return <li key={index + members['id']} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleMembersDropDown()
                                                                    }}>
                                                                        <div className="checkblock">
                                                                            <div className="custom-checkbox">
                                                                                <input className="form-control checkmark"
                                                                                    checked={membersSelected.includes(members['id']) ? true : false}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (
                                                                                            members.signin_role ===
                                                                                            ADMIN_CONSTANT &&
                                                                                            (adminMemberList.length === 1 || adminMemberList.filter((adminMember) => membersSelected.includes(adminMember.id) && adminMember.id !== members['id']).length)
                                                                                        ) {
                                                                                            toast.info(
                                                                                                `Please assign the admin role to another member before deactivating your account.`, {
                                                                                                toastId: "one_admin"
                                                                                            }
                                                                                            );
                                                                                            return;
                                                                                        }
                                                                                        addRemoveMembers(members['id'])
                                                                                    }} type="checkbox" id={`${members['id']}`} />
                                                                                <label onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                }} htmlFor={`${members['id']}`} className="custom-label"> {members['name']} {members['email'] === email ? <span>(you)</span> : <></>}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="warning-name">{members['signin_role']}</div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className="box">
                                                <div className="boxScroll scrollSection">
                                                    {Array.isArray(saved_card) &&
                                                        saved_card.length > 0 && (
                                                            <div className="form-group">
                                                                <label>Select payment method</label>
                                                                <div className="paymentOption">
                                                                    {saved_card.map(
                                                                        (item, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="form-check"
                                                                                    key={index}
                                                                                >
                                                                                    <RadioInputComponent
                                                                                        className={getPaymentMethodClassName(item.id.toString())}
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                        name={
                                                                                            "saved_card"
                                                                                        }
                                                                                        register={
                                                                                            register
                                                                                        }
                                                                                        id={`${item.id}`}
                                                                                        label={`XXXX XXXX XXXX ${item.cc_no}`}
                                                                                        labelClassName="form-check-label"
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <div className="form-check">
                                                                        <RadioInputComponent
                                                                            value={0}
                                                                            name={"saved_card"}
                                                                            register={register}
                                                                            id={`0`}
                                                                            label={`New`}
                                                                            labelClassName="form-check-label"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <span className={`error`}>
                                                                    {`${"saved_card" in errors
                                                                        ? errors?.saved_card
                                                                            ?.message
                                                                        : ""
                                                                        }`}
                                                                </span>
                                                                {watch("saved_card") === "0" ? <div className="paymentType">
                                                                    <ul>
                                                                        <li className={!creditCardTab ? "active" : ""}>
                                                                            <a href="javascript:void(0)" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (creditCardTab) {
                                                                                    ["accountType", "accountHolderType", "accountNumber", "routingNumber"].map((input_name) => (setValue(input_name, "")));

                                                                                    creditCardTab && setStateData((prev) => ({ ...prev, creditCardTab: false }))
                                                                                }
                                                                            }}>
                                                                                <i className="fa-solid fa-building-columns"></i>
                                                                                <span>ACH / US bank account</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className={creditCardTab ? "active" : ""}>
                                                                            <a href="javascript:void(0)" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (!creditCardTab) {
                                                                                    ["cardNumber", "cardCvc", "cardExpiry"].map((input_name) => (setValue(input_name, "")));
                                                                                    setStateData((prev) => ({ ...prev, creditCardTab: true }))
                                                                                }

                                                                            }}>
                                                                                <i className="fa-regular fa-credit-card"></i>{" "}
                                                                                <span>Credit card</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div> : <></>}
                                                            </div>
                                                        )}
                                                    {(watch("saved_card") === "0" ||
                                                        (Array.isArray(saved_card) &&
                                                            saved_card.length === 0)) && (
                                                            <>
                                                                {
                                                                    creditCardTab ? <div className="form-group">
                                                                        <label>Card details</label>
                                                                        <div className="cardBrand">
                                                                            <CardNumberInput
                                                                                control={control}
                                                                                name={"cardNumber"}
                                                                            />
                                                                        </div>
                                                                        <span className="error">{`${"cardNumber" in errors
                                                                            ? errors?.cardNumber
                                                                                ?.message
                                                                            : ""
                                                                            }`}</span>
                                                                        <div className="cardNum">
                                                                            <div className="cardInput">
                                                                                <CardExpiryInput
                                                                                    control={control}
                                                                                    name="cardExpiry"
                                                                                    errorMsg={`${"cardExpiry" in
                                                                                        errors
                                                                                        ? errors
                                                                                            ?.cardExpiry
                                                                                            ?.message
                                                                                        : ""
                                                                                        }`}
                                                                                />
                                                                            </div>
                                                                            <div className="cardInput">
                                                                                <CardCvcInput
                                                                                    name="cardCvc"
                                                                                    errorMsg={`${"cardCvc" in errors
                                                                                        ? errors
                                                                                            ?.cardCvc
                                                                                            ?.message
                                                                                        : ""
                                                                                        }`}
                                                                                    control={control}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div> : <></>
                                                                }

                                                                {creditCardTab ? (<div className="form-group">
                                                                    <InputComponent
                                                                        label={`Name on ${creditCardTab ? "card" : "bank account"}`}
                                                                        name="name"
                                                                        placeholder={`Enter your name on ${creditCardTab ? "card" : "bank account"}`}
                                                                        register={register}
                                                                        errorMsg={errors?.name?.message as string}
                                                                    />
                                                                </div>) : ''}

                                                                <div className="form-group">
                                                                    <label>Billing address</label>
                                                                    <div className="selectInput mb-1">
                                                                        <SelectInput
                                                                            placeholder="Country"
                                                                            name="country"
                                                                            register={register}
                                                                            options={[
                                                                                {
                                                                                    label: "United States",
                                                                                    value: "US",
                                                                                },
                                                                            ]}
                                                                            errorMsg={
                                                                                errors?.country?.message as string
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <LocationSearchInput
                                                                        name={`address`}
                                                                        valueLoc={address}
                                                                        errorMsg={
                                                                            errors?.address?.message as string ||
                                                                            ""
                                                                        }
                                                                        onChangeLoc={addressOnChange}
                                                                        onSelect={addressOnSelect}
                                                                        control={control}
                                                                        debounce={500}
                                                                        searchOptions={{
                                                                            componentRestrictions: {
                                                                                country: ["usa", "us"],
                                                                            },
                                                                            types: [],
                                                                        }}
                                                                        placeholder="Address"
                                                                        shouldFetchSuggestions={
                                                                            address?.length >= 3
                                                                        }
                                                                        title={address}
                                                                    />
                                                                    <div className="cardNum">
                                                                        <div className="cardInput">
                                                                            <InputComponent
                                                                                name="city"
                                                                                placeholder="City"
                                                                                register={register}
                                                                                errorMsg={
                                                                                    errors.city?.message as string
                                                                                }
                                                                                onBlur={(
                                                                                    e: React.FocusEvent<HTMLInputElement>
                                                                                ) => {
                                                                                    let value =
                                                                                        e.target.value.trim();
                                                                                    if (
                                                                                        value &&
                                                                                        value !== city
                                                                                    ) {
                                                                                        setStateData(
                                                                                            (prev) => ({
                                                                                                ...prev,
                                                                                                calcTax:
                                                                                                    true,
                                                                                            })
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="cardInput">
                                                                            <InputComponent
                                                                                name="zip_code"
                                                                                placeholder="Zip code"
                                                                                register={register}
                                                                                errorMsg={
                                                                                    errors.zip_code
                                                                                        ?.message as string
                                                                                }
                                                                                onBlur={(
                                                                                    e: React.FocusEvent<HTMLInputElement>
                                                                                ) => {
                                                                                    let value =
                                                                                        e.target.value.trim();
                                                                                    if (
                                                                                        value &&
                                                                                        value !==
                                                                                        zip_code
                                                                                    ) {
                                                                                        setStateData(
                                                                                            (prev) => ({
                                                                                                ...prev,
                                                                                                calcTax:
                                                                                                    true,
                                                                                            })
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="selectInput mt-1">
                                                                        <SelectInput
                                                                            placeholder="State"
                                                                            name="state"
                                                                            register={register}
                                                                            options={stateOptions}
                                                                            errorMsg={
                                                                                errors.state?.message as string
                                                                            }
                                                                            onBlur={(
                                                                                e: React.FocusEvent<
                                                                                    HTMLInputElement,
                                                                                    Element
                                                                                >
                                                                            ) => {
                                                                                let value =
                                                                                    e.target.value.trim();
                                                                                setValue('state', value);
                                                                                if (
                                                                                    value &&
                                                                                    value !== state
                                                                                ) {
                                                                                    setStateData(
                                                                                        (prev) => ({
                                                                                            ...prev,
                                                                                            calcTax:
                                                                                                true,
                                                                                        })
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    <div className={"promoCode"}>
                                                        <div className="custom-checkbox mb-4">
                                                            <InputComponent
                                                                name="taxExempt"
                                                                className="checkmark"
                                                                type="checkbox"
                                                                id="taxExempt"
                                                                register={register}
                                                                errorMsg={
                                                                    errors.taxExempt?.message as string
                                                                }
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setValue('taxExempt', e.target.checked);
                                                                    setStateData((prev) => ({ ...prev, taxExempt: e.target.checked }));
                                                                }}
                                                            />
                                                            <label htmlFor="taxExempt" className="custom-label"> My organization is tax exempt</label>
                                                        </div>
                                                        {taxExempt ? <div className={"form-group"}>
                                                            <InputComponent
                                                                label="Sales tax exemption"
                                                                htmlFor="sales_tax_id"
                                                                labelClassName="salesTaxExemption"
                                                                name="sales_tax_id"
                                                                id="sales_tax_id"
                                                                placeholder="Enter your Tax ID"
                                                                register={register}
                                                                errorMsg={
                                                                    errors.sales_tax_id?.message as string
                                                                }
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setValue('sales_tax_id', e.target.value)
                                                                }}
                                                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                                                    let value = e.target.value.trim();
                                                                    const isValid = salesTaxIdRegex.test(value);
                                                                    if (!isValid && value) {
                                                                        toast.error(`The sales tax id is invalid.`, {
                                                                            toastId: "tax_id_is_invalid",
                                                                        })
                                                                        return;
                                                                    };
                                                                    value && dispatch(saveAndUpdateTaxExemptID(value))
                                                                }}
                                                            />
                                                        </div> : <></>}
                                                        {Number(seat_count) <= allowedSubUser ? <></> : <Accordion defaultActiveKey="0">
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    Add promo code
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="promoCodeinfo">
                                                                        <div className={`addpromoForm ${is_valid === "Valid" ? "d-none" : ""}`}>
                                                                            <InputComponent
                                                                                name="promoCode"
                                                                                placeholder="Enter code"
                                                                                register={register}
                                                                            />
                                                                            <button type="button" className="btn btn-primary"
                                                                                onClick={() => {
                                                                                    if (!getValues('promoCode')) {
                                                                                        toast.info("Please enter promo code.")
                                                                                        return
                                                                                    }
                                                                                    if (promoCodeValidating) {
                                                                                        return
                                                                                    }
                                                                                    if (getValues('promoCode')) {
                                                                                        setStateData((prev) => ({ ...prev, promoCodeValidating: true }));
                                                                                        dispatch(validatePromoCode(access_token, { promo_code: getValues('promoCode') as string, cartTotal: cartItemsTotalPerMonth })).finally(() => {
                                                                                            setValue('promoCode', "")
                                                                                            setStateData((prev) => ({ ...prev, promoCodeValidating: false }));
                                                                                        })
                                                                                    }

                                                                                }}
                                                                            >{promoCodeValidating ? "Validating Promo Code" : "Apply"}</button>
                                                                        </div>
                                                                        <div className={is_valid === "Valid" ? "blastOff" : "d-none"}>
                                                                            <i className="fa-solid fa-circle-check"></i>
                                                                            <div className="blast-info">
                                                                                {promoCode}
                                                                                <span>{`${discount_percent ? `${discount_percent}% ` : ""}`}discount (-{discount_percent ? (Number(calculateDiscount(
                                                                                    (Number((cartItemsTotalPerMonth * (yearly ? MONTHS : 1)).toFixed(2))
                                                                                        -
                                                                                        (yearly
                                                                                            ?
                                                                                            Number(((cartItemsTotalPerMonth * MONTHS * yearlyDiscountPercent) / 100).toFixed(2))
                                                                                            :
                                                                                            0)),
                                                                                    (discount_percent as number)))) : discount_fixed || minimum_amount || 0})</span>
                                                                            </div>
                                                                            <i className="fa-solid fa-xmark" onClick={() => {
                                                                                dispatch(cartSelectBasinCountyActions.handlePromoCodeDetails({ reset: true }))
                                                                            }}></i>
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>

                                    <div className="links">
                                        <ul>
                                            <li>
                                                <Link to={"/privacy-policy"} target={"_blank"}>Privacy Policy</Link>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <h3>Current Subscriptions</h3>
                                    <div className="subsbox mb-4">
                                        <div className="headertop">
                                            Subscriptions <span>    {`${subscriptionData && subscriptionData[
                                                "subscription"
                                            ][
                                                "billing_frequency_display"
                                            ] ===
                                                "Monthly"
                                                ? "Monthly"
                                                : "Annual"
                                                }`} Cost</span>
                                        </div>
                                        <div className="insidebox">
                                            <div className="price-list scrollSection">
                                                <ul>
                                                    {subscriptionData &&
                                                        [
                                                            ...subscriptionData.details.filter(
                                                                (item) =>
                                                                    !item.unsubscribe_status
                                                            ),
                                                            ...subscriptionData.details.filter(
                                                                (item) =>
                                                                    item.unsubscribe_status
                                                            )
                                                        ].map((item, index) => {
                                                            return <li key={index}>
                                                                <div className="content">
                                                                    <div className="small-map">
                                                                        <img src={item.image_data} alt="" />
                                                                    </div>
                                                                    <div className="description">
                                                                        <h4>
                                                                            {
                                                                                item.line_item_name
                                                                            }{" "}
                                                                            {item.line_item_type ===
                                                                                2
                                                                                ? "County"
                                                                                : ""}
                                                                        </h4>
                                                                        <h6 className={
                                                                            item.line_item_type ===
                                                                                1
                                                                                ? ""
                                                                                : "d-none"
                                                                        }>{
                                                                                item.total_counties
                                                                            }{" "}
                                                                            Counties</h6>
                                                                        <p className="">
                                                                            {USDollar.format(
                                                                                Number(
                                                                                    item.total_cost
                                                                                ) / (subscriptionData.subscription.allowed_user ?? 1)
                                                                            )}&nbsp;
                                                                            per seat/
                                                                            {`${subscriptionData[
                                                                                "subscription"
                                                                            ][
                                                                                "billing_frequency_display"
                                                                            ] ===
                                                                                "Monthly"
                                                                                ? "month"
                                                                                : "year"
                                                                                }`}
                                                                            -
                                                                            Billed{" "}
                                                                            {`${subscriptionData[
                                                                                "subscription"
                                                                            ][
                                                                                "billing_frequency_display"
                                                                            ] ===
                                                                                "Monthly"
                                                                                ? "monthly"
                                                                                : "yearly"
                                                                                }`}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="price">{USDollar.format(
                                                                    Number(
                                                                        item.total_cost
                                                                    )
                                                                )}
                                                                    <span>{subscriptionData.subscription.allowed_user} {subscriptionData.subscription.allowed_user === 1 ? "Seat" : "Seats"}</span>
                                                                </div>
                                                            </li>
                                                        })}
                                                </ul>
                                            </div>
                                            <div className="total-block">
                                                <ul className="subtotal">
                                                    <li>
                                                        <label>{`${subscriptionData && subscriptionData[
                                                            "subscription"
                                                        ][
                                                            "billing_frequency_display"
                                                        ] ===
                                                            "Monthly"
                                                            ? "Monthly"
                                                            : "Annual"
                                                            }`} total </label>
                                                        <span>
                                                            {USDollar.format(
                                                                cartTotalCost
                                                            )}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {(Number(seat_count) > allowedSubUser || Number(seat_count) < allowedSubUser) && !siteLoader ? (<>
                                        <h3>Order Summary</h3>
                                        <div className="box p-0">
                                            {(Number(seat_count) > allowedSubUser) ? <InventoryLineItemTable /> : <></>}
                                            {Number(seat_count) >= allowedSubUser ? (<div className="total-block">
                                                <TotalSubtotalBillNow
                                                    isEdit={isEdit}
                                                    newItemTotalInEditCase={newItemTotalInEditCase}
                                                    newItemTotalTaxInEditCase={newItemTotalInEditCase}
                                                    totalForInvoiceOrCart={totalForInvoiceOrCart}
                                                    discountForPromoCodeCoupon={discountForPromoCodeCoupon}
                                                    taxesForInvoiceOrCart={USDollar.format(Number(taxesForInvoiceOrCart))}
                                                    subtotalForInvoiceOrCart={subtotalForInvoiceOrCart}
                                                    discountForInvoiceOrCart={discountForInvoiceOrCart} />
                                            </div>) : ''}

                                            <div className="btn-action">
                                                {Number(seat_count) && Number(seat_count) > allowedSubUser && Number(seat_count) <= 10 ?
                                                    <button type={"submit"} className={`btn btn-primary width100 ${failedPaymentMethodIds.includes(watch("saved_card")) ? 'disabled' : ''}`}>{(saved_card.length === 0 || watch("saved_card") === "0") && !creditCardTab ? 'Link your bank account' : totalForInvoiceOrCart ? `Pay ${totalForInvoiceOrCart} now` : `Pay $0.00 now`}</button>

                                                    :
                                                    <button type={seat_count && Number(seat_count) < allowedSubUser ? "submit" : "button"} className={`btn btn-primary width100 ${(membersSelected.length + unacceptedInvitesSelected.length + uninvitedSelected.length) !== (allowedSubUser - Number(seat_count)) || failedPaymentMethodIds.includes(watch("saved_card")) ? 'disabled' : ''} ${seat_count && Number(seat_count) < allowedSubUser ? "" : "initial"}`}>Pay $0.00 now</button>
                                                }

                                            </div>
                                        </div></>) : ''}

                                </div>
                            </div>
                        </Modal.Body>
                    </form>
                </Modal >
            }
        </>

    );
}

export default PaymentModal;
