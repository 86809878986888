import React, { useEffect, useLayoutEffect, useState } from "react";
import CartBasinApiCols from "./CartBasinApiCols";
import GlobalTable from "../common/GlobalTable";
import { GlobalTableProps } from "../models/page-props";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    contWithoutSavingApiList,
    handleWellApiListAfterCsvUpload,
    saveApiList,
    uploadShapeFileOrCsv,
} from "../store/actions/cart-basin-to-county-actions";
import { toast } from "react-toastify";
import InputComponent from "../common/InputComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    ApiListTableFormData,
    OptionType,
    SaveAsAoiFormData,
    csvApiDataObj,
} from "../models/submit-form";
import {
    apiListTableNotRequiredValidation,
    apiListTableValidation,
    saveAsAoiValidation,
} from "../../Helper/validation";
import { hideSiteLoader } from "../store/actions/modal-actions";
import { logUserAction } from "../store/actions/auth-actions";
import { actionType } from "../../utils/helper";
import { File } from "../models/redux-models";
import { checkDuplicateAOIName, clearAoiList, getDefaultAOIName } from "../store/actions/aoi-actions";
import { setOpenModalAFterUploadModal, setGeometry, setEpsg } from "../store/actions/cart-basin-to-filter-actions";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../common/Spinner";
import exportFromJSON from "export-from-json";
import moment from "moment";
import xlsx from "json-as-xlsx";
import { handleSelectedAoiData } from "../store/actions/wells-rigs-action";

export const CartBasinOpenModalAfterAPiModal = () => {

    const access_token = useAppSelector(state => state.auth.user.access_token);
    const csvApiFileData = useAppSelector(state => state.cartBasinToFilterSlice.csvApiFileData);
    const csvApiFileName = useAppSelector(state => state.cartBasinToFilterSlice.csvApiFileName);


    const dispatch = useAppDispatch();

    const [state, setState] = useState({
        page: 1,
        totalPage: 1,
        page_size: 100,
        activeTabIndex: 1,
        save: true,
        tableData: [] as csvApiDataObj[],
        hasMore: false
    });
    const {
        page,
        totalPage,
        page_size,
        activeTabIndex,
        save,
        tableData,
        hasMore
    } = state;

    const {
        handleSubmit,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm<ApiListTableFormData>({
        resolver:
            // activeTabIndex === 0
            //     ? yupResolver(apiListTableValidation)
            // : 
            yupResolver(apiListTableNotRequiredValidation),
    });
    const matchedApiList = Array.from(csvApiFileData).filter((item) => item.match && item.in_plan)
        ;
    const unMatchedApiList = Array.from(csvApiFileData).filter((item) => !item.match)
        ;
    const unSubscribedApiList = Array.from(csvApiFileData).filter((item) => item.in_plan === false)
        ;
    let data =
        activeTabIndex === 2
            ? [...matchedApiList, ...unMatchedApiList, ...unSubscribedApiList]
            : activeTabIndex === 0
                ? unMatchedApiList
                : activeTabIndex === 1
                    ? matchedApiList
                    : unSubscribedApiList;


    useEffect(() => {
        data.slice(0, page_size).forEach((item, index) => {
            setValue(`wellMatching.${index}.name`, item.wellMatching);
        });
        let tempPage = Math.floor(data.length / page_size) +
            (data.length % page_size > 0 ? 1 : 0);
        setState((prev) => ({
            ...prev,
            data,
            tableData: Array.from(data).slice(0, page * page_size),
            totalPage: tempPage,
            hasMore: tempPage === 1 ? false : 1 < tempPage ? true : false,
            page: 1,
        }));
        //closing the loader 
        dispatch(hideSiteLoader())
        // eslint-disable-next-line
    }, [activeTabIndex, csvApiFileData]);

    const onPageChange = (page: number) => {
        setState((prev) => ({ ...prev, page: page }));
    };

    const onSubmit = (data: ApiListTableFormData | null) => {
        let tempApiList: OptionType[] = [];

        let tempFilterData = matchedApiList.map(item => {

            tempApiList.push({
                label: item.matched_api,
                value: item.matched_api,
            });

            return {
                "well_name": item.well_name,
                "api": item.api,
                "state": item.state,
                "county": item.county,
                "matched_api": item.matched_api,
                "matched_well_name": item.matched_well_name,
                "matched_basin_name": item.matched_basin_name,
                "matched_county": item.matched_county,
                "matched_state": item.matched_state,
                "match": item.match,
                "match_type": item.match_type,
                "match_score": item.match_score,
                "in_plan": item.in_plan
            }
        });

        (
            save
                ?
                dispatch(
                    saveApiList(access_token, {
                        file_name: csvApiFileName,
                        filter_data: tempFilterData
                    })
                )
                :
                dispatch(
                    contWithoutSavingApiList(access_token)
                )
        ).then((res) => {
            const { status, msg } = res;
            if (status === 200) {
                toast.success(msg);
                dispatch(handleWellApiListAfterCsvUpload(tempApiList));
                dispatch(setOpenModalAFterUploadModal(false))
            } else {
                toast.error(msg);
            }
        });


    };

    const status = [
        {
            label: "Matched",
            className: "matched",
            tabIndex: 1,
            count: matchedApiList
                .length,
        },
        {
            label: "Unmatched",
            className: "unmatched",
            tabIndex: 0,
            count: unMatchedApiList
                .length,
        },
        ...(unSubscribedApiList
            .length
            ? [
                {
                    label: "Unsubscribed",
                    className: "notplan",
                    tabIndex: 3,
                    count: unSubscribedApiList.length,
                },
            ]
            : []),

        {
            label: "View All",
            className: "view-all",
            tabIndex: 2,
            count: csvApiFileData.length,
        },

    ];


    const fetchData = () => {
        setState((prev) => ({
            ...prev,
            ...(page + 1 < totalPage && {
                page: page + 1,
                hasMore: true,
                tableData: data.splice(0, (page + 1) * page_size)
            }),
            ...(page + 1 === totalPage && {
                hasMore: false,
                page: page + 1,
                tableData: data.splice(0, (page + 1) * page_size)
            }),
        }))
    };
    return (
        <>
            <h3>
                <i className="fa-solid fa-cloud-arrow-up"></i> Uploaded API’s
            </h3>
            <form
                className="form-block"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCapitalize="off"
            >
                <div className="saveUploadedApi">
                    <p>
                        Your API's were uploaded. We matched all wells in our
                        database and provided suggestions for the partially
                        matching ones. Please confirm or delete the suggestions
                        and enter any missing wells manually.
                    </p>
                </div>
                <div className="filterLable saveUploadedApi">
                    <ul className="clickable">
                        {status.map((item, index) => {
                            const { label, className, count, tabIndex } = item;
                            return (
                                <li
                                    className={
                                        activeTabIndex === tabIndex
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        if (activeTabIndex !== tabIndex) {
                                            clearErrors();
                                            setState((prev) => ({
                                                ...prev,
                                                page: 1,
                                                activeTabIndex: tabIndex,
                                                data: [],
                                                tableData: [],
                                                hasMore: false
                                            }));
                                        }
                                    }}
                                    key={index}
                                >
                                    {label}
                                    <span className={className}>{count}</span>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="save">
                        <button
                            type="button"
                            onClick={() => {
                                if (matchedApiList.length === 0) {
                                    toast.info("No matching wells data found.");
                                    return
                                }
                                // exportFromJSON({
                                //     data: tempData,
                                //     fileName: `ED_Data_Export_${moment(new Date()).format(
                                //         "MMM-DD-YYYY, h:mm:ss a"
                                //     )}`,
                                //     // delimiter: ";",
                                //     exportType: "csv",
                                // })
                                let excel_data = [

                                    {
                                        sheet: `ED_Data_Export`,
                                          columns: [
                                            {
                                                label: "well_name", value: "well_name"
                                            },
                                            {
                                                label: "api", value: "api"
                                            },
                                            {
                                                label: "state", value: "state"
                                            },
                                            {
                                                label: "county", value: "county"
                                            },
                                            {
                                                label: "matched_api", value: "matched_api"
                                            },
                                            {
                                                label: "matched_well_name", value: "matched_well_name"
                                            },
                                            {
                                                label: "matched_basin_name", value: "matched_basin_name"
                                            },
                                            {
                                                label: "matched_county", value: "matched_county"
                                            },
                                            {
                                                label: "matched_state", value: "matched_state"
                                            },
                                            {
                                                label: "match", value: "match"
                                            },
                                            {
                                                label: "match_type", value: "match_type"
                                            },
                                            {
                                                label: "match_score", value: "match_score"
                                            },
                                            {
                                                label: "in_plan", value: "in_plan"
                                            },
                                          ],
                                        content: matchedApiList.map(item => ({
                                            "well_name": item.well_name,
                                            "api": item.api,
                                            "state": item.state,
                                            "county": item.county,
                                            "matched_api": item.matched_api,
                                            "matched_well_name": item.matched_well_name,
                                            "matched_basin_name": item.matched_basin_name,
                                            "matched_county": item.matched_county,
                                            "matched_state": item.matched_state,
                                            "match": item.match,
                                            "match_type": item.match_type,
                                            "match_score": item.match_score,
                                            "in_plan": item.in_plan
                                        })),
                                    },
                                ]

                                xlsx(excel_data, {
                                    fileName: `ED_Data_Export_${moment(new Date()).format(
                                        "MMM-DD-YYYY, h:mm:ss a")}`,
                                    extraLength: 5,
                                })
                            }}
                            className="btn btn-primary"
                        >
                            <i className="fa-solid fa-download"></i>
                        </button>
                        <button
                            type="submit"
                            className="btn btn-green"
                            onClick={() => {
                                !save && setState((prev) => ({ ...prev, save: true }))
                            }}
                        >
                            <i className="fa-solid fa-floppy-disk"></i> Continue and save results to file
                        </button>
                        <button
                            type="submit"
                            className="btn btn-secondary"
                            onClick={() => {
                                save && setState((prev) => ({ ...prev, save: false }))
                            }}
                            style={{ marginLeft: "1rem" }}
                        >
                            Continue without saving
                        </button>
                    </div>
                </div>

                <div className="CartSearchCon" style={{ display: "block" }}>
                    <div
                        id="apiListing"
                        className="searchList popTable scrollSection">
                        <InfiniteScroll
                            dataLength={tableData.length}
                            next={fetchData}
                            hasMore={hasMore}
                            scrollThreshold={0.8}
                            style={{ overflow: "hidden", minHeight: "270px" }}
                            loader={<Spinner />}
                            scrollableTarget="apiListing"
                        >
                            <GlobalTable
                                tableStyle={{
                                    border: 0,
                                    cellPadding: 0,
                                    cellSpacing: 0,
                                }}
                                cols={
                                    CartBasinApiCols(
                                        control,
                                        errors,
                                        setValue,
                                    ) as GlobalTableProps["cols"]
                                }
                                data={tableData}
                            />
                        </InfiniteScroll>
                    </div>

                </div>
            </form >
        </>
    );
};

export const CartBasinOpenModalAfterShapeFileModal = ({
    file,
    handleStateKey,
}: {
    file: File | null;
    handleStateKey: (obj: { [x: string]: string }) => void;
}) => {
    const dispatch = useAppDispatch();
    const {
        auth: {
            user: { access_token },
        },
    } = useAppSelector((state) => state);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<SaveAsAoiFormData>({
        resolver: yupResolver(saveAsAoiValidation),
    });
    const [aoiDefaultName, setAoiDefaultName] = useState("");

    const uploadFile = (aoi_name?: string, buffer_distance?: string) => {
        if (file !== null) {
            dispatch(
                uploadShapeFileOrCsv(access_token, {
                    file,
                    ...(aoi_name && { aoi_name }),
                    ...(buffer_distance && { buffer_distance }),
                }, 'upload_shapefile')
            ).then((res) => {
                const {
                    data: { filter_data, id },
                    status,
                    msg,
                    file_name,
                    epsg
                } = res;
                if (status === 200) {
                    //log user actions
                    dispatch(
                        logUserAction({
                            action_type: actionType['upload_shapefile'],
                            action_log_detail: JSON.stringify({
                                ...(file_name && { file_name }),
                                // ...(aoi_name && { aoi_name: aoi_name }),
                                // ...(buffer_distance && { buffer_distance: buffer_distance }),
                                ...(!file_name && { message: "User adopted continue without saving method." })
                            })
                        })
                    );
                    toast.success(msg);
                    dispatch(setOpenModalAFterUploadModal(false))
                    dispatch(setGeometry(filter_data as string))
                    dispatch(setEpsg(epsg as string))
                    dispatch(clearAoiList());
                    dispatch(handleSelectedAoiData({ aoi_id: id }));
                } else {
                    if (status === 422) {
                        file_name &&
                            aoi_name &&
                            handleStateKey({ file_name, aoi_name });
                        dispatch(setOpenModalAFterUploadModal(false))
                        toast.error("An error occurred while uploading the file:" + msg);
                    }
                }
            });
        }
    };

    useLayoutEffect(() => {
        dispatch(getDefaultAOIName()).then((res) => {
            if (res.status === 200) {
                setValue('aoi_name', res.aoi_name)
                setAoiDefaultName(res.aoi_name)
            }
        })
    }, [])

    const onSubmit = (data: SaveAsAoiFormData) => {
        const { aoi_name, bufferDistance } = data;
        dispatch(checkDuplicateAOIName(access_token, { aoi_name }, true)).then((res) => {
            const { status } = res;
            if (status === 200) {
                uploadFile(aoi_name, bufferDistance);
            }
        })
    };

    return (
        <>
            <h3>
                <i className="fa-regular fa-floppy-disk"></i> Save as AOI
            </h3>
            <p>
                Save shapefiles as Areas of Interest (AOI) to receive alerts when changes occur within the AOI
            </p>
            <form
                className="form-block"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCapitalize="off"
            >
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-group">
                            <InputComponent
                                label="Name your AOI"
                                name="aoi_name"
                                placeholder="Enter AOI name"
                                defaultValue={aoiDefaultName}
                                register={register}
                                errorMsg={errors.aoi_name?.message}
                            />
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-group">
                            <InputComponent
                                label="Buffer Distance (miles)"
                                name="bufferDistance"
                                type={"number"}
                                placeholder="Enter miles"
                                register={register}
                                defaultValue={0}
                                errorMsg={"bufferDistance" in errors ? errors.bufferDistance?.message : ''}
                            />
                            <span className="maxtext">Add a buffer around your AOI</span>
                        </div>
                    </div>
                </div>
                <br />
                <div className="action-footer">
                    <button type="submit" className="btn btn-green">
                        Save as AOI
                    </button>
                </div>
            </form >
        </>
    );
};
