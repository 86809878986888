import React, { useLayoutEffect, useState, useEffect, useRef, useMemo } from "react";
import { CartSelectBasinViewProps } from "../models/page-props";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import Card from "./Card";
import BasinTabContent from "./BasinTabContent";
import CountyTabContent from "./CountyTabContent";
import CardBottom from "./CardBottom";
import { CartSelectBasinViewState } from "../models/stateModel";
import SearchComponent from "../common/SearchComponent";
import {
    // eslint-disable-next-line
    clearCartItemsList,
    clearSearchList,
    getCartDetails,
    handleYearlyAndMonthlyTab,
    loadSearchList,
    removeCartItems,
    handleCartModified
} from "../store/actions/cart-select-basin-county-actions";
import SearchList from "./SearchList";
import DeleteConfirmationModal from "../common/Modal/DeleteConfirmationModal";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import PaymentModal from "../common/Modal/PaymentModal";
import { handleMonthlyToYearlyUpgradeMsgModal, hideCheckOutModal } from "../store/actions/modal-actions";
import { loadStripe } from "@stripe/stripe-js";
import Scrollbars from "react-custom-scrollbars";

import { basinsUpdated, countiesUpdated, setSelectedType } from "../map/redux/locations";
// import Map from "../map/Map";
import { MapComponent, MapType } from "../common/MapComponent";
import { USDollar, calculateDiscount, formatUSDCurrency } from "../../utils/helper";
import GoNational from "./GoNational";
import MonthlyToYearlyUpgradeMsgModal from "../common/Modal/MonthlyToYearlyUpgradeMsgModal";
import { BASINS, COUNTIES } from "../map/redux/types";
import RedirectToBilling from "../common/Modal/RedirectToBilling";

const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);
const CartSelectBasinView = ({ activate }: CartSelectBasinViewProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const checkOutModal = useAppSelector((state) => state.modal.checkOutModal)
    const recentUnpaidInvoiceId = useAppSelector((state) => state.subscriptionSettings.recent_unpaid_invoice_id)
    const subscriptionData = useAppSelector((state) => state.subscriptionSettings.subscriptionData)
    const upgrade_subscription = useAppSelector((state) => state.subscriptionSettings.upgrade_subscription)
    const selectedType = useAppSelector((state) => state.locations.selectedType)
    const access_token = useAppSelector((state) => state.auth.user.access_token)
    const company_id = useAppSelector((state) => state.auth.user.company_data.company_id)
    const is_trial_never_end = useAppSelector((state) => state.auth.user.company_configs.is_trial_never_end)
    const free_trial_period_enabled = useAppSelector((state) => state.auth.user.company_configs.free_trial_period_enabled)
    const no_of_free_days_allowed = useAppSelector((state) => state.auth.user.company_configs.no_of_free_days_allowed)
    const trial_expired = useAppSelector((state) => state.auth.user.company_configs.trial_expired)
    const cartListItems = useAppSelector((state) => state.cartSelectBasinCounty.cartListItems)
    const cartListItemsYearly = useAppSelector((state) => state.cartSelectBasinCounty.cartListItemsYearly)
    const cartItemsTotalPerMonth = useAppSelector((state) => state.cartSelectBasinCounty.cartItemsTotalPerMonth)
    const cartItemsTotalPerYear = useAppSelector((state) => state.cartSelectBasinCounty.cartItemsTotalPerYear)
    const basinSearchList = useAppSelector((state) => state.cartSelectBasinCounty.basinSearchList)
    const countySearchList = useAppSelector((state) => state.cartSelectBasinCounty.countySearchList)
    const yearlyDiscountPercent = useAppSelector((state) => state.cartSelectBasinCounty.yearlyDiscountPercent)
    const yearly = useAppSelector((state) => state.cartSelectBasinCounty.yearly)
    const cartModified = useAppSelector((state) => state.cartSelectBasinCounty.cartModified)



    const [state, setState] = useState<CartSelectBasinViewState>({
        isOpen: false,
        deleteCartItemModal: false,
        deleteItemId: null,
        deleteItemType: null,
        sub_id: null,
        sub_total: 0,
        showRedirectToBillingModal: true
    });
    const {
        isOpen,
        deleteCartItemModal,
        deleteItemId,
        deleteItemType,
        sub_id,
        sub_total,
        showRedirectToBillingModal
    } = state;

    const toggleSettingDropDown = () => {
        setState((prev) => ({ ...prev, isOpen: !isOpen }));
    };

    const cancelBasinOrCounty = () => {
        /**Passing in true as the 3 arg to getCartDetails, clears the
         * cart and updates the left had card
         */
        dispatch(getCartDetails(
            access_token,
            0,
            true
        ));

        sub_id && setState((prev) => ({ ...prev, sub_id: null }));
    };

    const removeBasinOrCounty = (
        id: number,
        item_type: number,
        sub_total = 0
    ) => {
        setState((prev) => ({
            ...prev,
            deleteItemId: id,
            deleteCartItemModal: true,
            deleteItemType: item_type,
            ...(sub_total && { sub_total }),
        }));
    };

    const getSearchList = (value: string) => {
        dispatch(
            loadSearchList(access_token, {
                category: selectedType === BASINS ? "basin" : "county",
                search: value,
            })
        );
    };

    const onSearchChange = (value: string) => {
        if (value?.length === 0) {
            dispatch(clearSearchList());
            return;
        }

        if (value && value.length >= 2) {
            dispatch(clearSearchList());
            getSearchList(value);
        }
    };

    const clearCartOnFirstRender = useRef(true)
    const showRedirectToBillingModalFirstRender = useRef(true)

    const onSearchFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const { value } = e.target;
        if (
            basinSearchList.length === 0 &&
            countySearchList.length === 0 &&
            value &&
            value?.length >= 2
        ) {
            dispatch(clearSearchList());
            getSearchList(value);
        }
    };

    useLayoutEffect(() => {
        dispatch(clearCartItemsList());
        console.log({ location })
        if (location?.state?.sub_id) {
            dispatch(getCartDetails(access_token, location.state.sub_id, clearCartOnFirstRender.current));
            setState((prev) => ({ ...prev, sub_id: location.state.sub_id }));
            window.history.replaceState({}, document.title);
        } else {
            company_id && dispatch(getCartDetails(access_token, 0, clearCartOnFirstRender.current));
        }
        if (clearCartOnFirstRender.current) {
            clearCartOnFirstRender.current = false
        }
        return () => {
            // cleaning the cart on component unmounting
            if (cartListItemsSelected.length) {
                dispatch(clearCartItemsList());
                dispatch(getCartDetails(access_token, 0, true));
            }

        }
        // eslint-disable-next-line
    }, []);

    const clickOutSideSearch = () => {
        (document.getElementById("search") as HTMLInputElement).value = "";
        dispatch(clearSearchList());
    };

    useEffect(() => {
        if (selectedType === BASINS) {
            dispatch(basinsUpdated([]))
        } else {
            dispatch(countiesUpdated([]))
        }
        // eslint-disable-next-line
    }, [selectedType]);



    const tabList = [
        {
            label: "Pay monthly", amount: `${USDollar.format(cartItemsTotalPerMonth)}/mo`,
            active: !yearly,
            onClick: () => {
                if (subscriptionData && subscriptionData['subscription']['billing_frequency_display'] === "Yearly" && !subscriptionData['subscription']['is_canceled']) {
                    return
                }
                if (yearly) {
                    dispatch(handleYearlyAndMonthlyTab(false))
                }
            },
            class: subscriptionData && subscriptionData['subscription']['billing_frequency_display'] === "Yearly" && !subscriptionData['subscription']['is_canceled'] ? "disabled" : "cursor"
        },
        {
            label: "Pay annually", saveLabel: `Save ${yearlyDiscountPercent}%`, amount: cartItemsTotalPerMonth ? `${formatUSDCurrency(cartItemsTotalPerMonth - Number(calculateDiscount(cartItemsTotalPerMonth, yearlyDiscountPercent)))}/mo` : `${USDollar.format(cartItemsTotalPerMonth)}/mo`, active: yearly, onClick: () => {
                if (!yearly && subscriptionData && subscriptionData['subscription']['billing_frequency_display'] === "Monthly" && !subscriptionData['subscription']['is_canceled']) {
                    dispatch(handleMonthlyToYearlyUpgradeMsgModal(true));
                    return
                }
                if (!yearly) {
                    dispatch(handleYearlyAndMonthlyTab(true))
                }
            },
            // class: subscriptionData && subscriptionData['subscription']['billing_frequency_display'] === "Monthly" ? "disabled" : "cursor"
            class: "cursor"

        }
    ]
    const basinClasses = ["tab-pane show ", selectedType === BASINS ? "active" : ""].join("")
    const countyClasses = ["tab-pane ", selectedType === COUNTIES ? "active" : ""].join("")

    const cartListItemsSelected = useMemo(() => {
        if (yearly) {
            if (cartListItemsYearly && cartListItemsYearly.length > 0 && !cartModified) {
                dispatch(handleCartModified(true))
            }
            return cartListItemsYearly
        }
        return cartListItems
    }, [yearly, cartListItems, cartListItemsYearly])

    const canShowRedirectToBillingModal = useMemo(() => {
        if (recentUnpaidInvoiceId && showRedirectToBillingModalFirstRender.current) {
            showRedirectToBillingModalFirstRender.current = false
            return true
        }
        return false
    }, [recentUnpaidInvoiceId])

    return (

        <div className="cart-map" onClick={() => {
            isOpen && toggleSettingDropDown();
        }}>
            {showRedirectToBillingModal && canShowRedirectToBillingModal && <RedirectToBilling show={showRedirectToBillingModal} onClose={setState} />}
            <div className="CartSelectCon">
                <div
                    className="cart-select-left"
                    onClick={clickOutSideSearch}
                >
                    <div className="selected-Counties">
                        <img src="images/cart.svg" alt="Cart" /> Cart
                    </div>
                    <div className={(!free_trial_period_enabled || upgrade_subscription) ? "subscriptionTab" : "d-none"}>
                        <ul>
                            {
                                tabList.map((_item, _index) => {
                                    return <li className={`${_item.active ? "active" : ""} ${_item['class']}`} key={_index} onClick={_item.onClick}>
                                        <i className="fa-solid fa-check"></i>
                                        <div className="pay">{_item.label}</div>
                                        <div className={"price"}>
                                            {_item.amount} {_item.saveLabel ? <span>{_item.saveLabel}</span> : ""}
                                        </div>
                                    </li>
                                })

                            }
                        </ul>
                    </div>
                    <div className={`selectbasinCon selectbasintotal ${free_trial_period_enabled && !upgrade_subscription ? "" : "bill_now"}  ${cartListItemsSelected.length ? `selectbasin-cart ${yearly ? "selectbasinYearly" : "selectbasinMonthly"}` : ""}`}>
                        {(free_trial_period_enabled && !is_trial_never_end && !upgrade_subscription) ? <div className="headerTitle"><h3 className="heading-free-trial">{no_of_free_days_allowed} Days Free Trial</h3><p className="nocommit"><strong>No Commitments</strong> - Upgrade to a paid subscription at any time during the trial</p></div> : <></>}
                        <Scrollbars
                            className={`tabcontent-scroll ${!upgrade_subscription && free_trial_period_enabled && Array.isArray(cartListItemsSelected) && cartListItemsSelected.length > 0 ? "tabcontentTrial" : ""}`}
                            autoHeightMin={0}
                            renderThumbVertical={props => < div {...props} className="thumb-vertical" />}
                            renderTrackVertical={props => < div {...props} className="track-vertical" />}
                        >
                            <div
                                className="tab-content"
                                id="pills-tabContent"
                            >
                                <div
                                    className={basinClasses}
                                    id="pills-basin"
                                    role="tabpanel"
                                    aria-labelledby="pills-by-basin-tab"
                                >
                                    {Array.isArray(cartListItemsSelected) &&
                                        cartListItemsSelected.length ? (
                                        cartListItemsSelected.map((item, index) => (
                                            <Card
                                                key={index}
                                                item={item}
                                                removeItem={removeBasinOrCounty}
                                                free_trial_period_enabled={free_trial_period_enabled}
                                                trial_expired={trial_expired}
                                            />
                                        ))
                                    ) : (
                                        <BasinTabContent freeTrial={free_trial_period_enabled && !trial_expired ? true : false} />
                                    )}
                                </div>
                                <div
                                    className={countyClasses}
                                    id="pills-county"
                                    role="tabpanel"
                                    aria-labelledby="pills-by-county-tab"
                                >
                                    {Array.isArray(cartListItemsSelected) &&
                                        cartListItemsSelected.length ? (
                                        cartListItemsSelected.map((item, index) => (
                                            <Card
                                                key={index}
                                                item={item}
                                                removeItem={removeBasinOrCounty}
                                                free_trial_period_enabled={free_trial_period_enabled}
                                                trial_expired={trial_expired}
                                            />
                                        ))
                                    ) : (
                                        <CountyTabContent freeTrial={free_trial_period_enabled && !trial_expired ? true : false} />
                                    )}
                                </div>
                            </div>
                        </Scrollbars>
                        {Array.isArray(cartListItemsSelected) && cartListItemsSelected.length ? <></> : <GoNational />}
                        <div className="totalandProfileboth">
                            {Array.isArray(cartListItemsSelected) &&
                                cartListItemsSelected.length > 0 && (
                                    <CardBottom
                                        cancelClick={cancelBasinOrCounty}
                                        total={yearly ? cartItemsTotalPerYear : cartItemsTotalPerMonth}
                                    />
                                )}
                            {checkOutModal && (
                                <Elements stripe={stripePromise}>
                                    <PaymentModal
                                        onCancelBtnClick={() => {
                                            dispatch(hideCheckOutModal());
                                            activate();
                                        }}
                                        isEdit={
                                            sub_id
                                                ? true
                                                : cartListItemsSelected.filter(
                                                    (item) =>
                                                        item.subscription_details_id !==
                                                        null
                                                ).length > 0
                                                    ? true
                                                    : false
                                        }
                                        removeBasinOrCounty={
                                            removeBasinOrCounty
                                        }
                                    />
                                </Elements>
                            )}
                        </div>
                        {deleteCartItemModal && (
                            <DeleteConfirmationModal
                                show={deleteCartItemModal}
                                handleClose={() => {
                                    setState((prev) => ({
                                        ...prev,
                                        deleteItemId: null,
                                        deleteCartItemModal: false,
                                        deleteItemType: null,
                                    }));
                                }}
                                confirmBtnClick={() => {
                                    deleteItemId &&
                                        dispatch(
                                            removeCartItems(access_token, {
                                                item_id: deleteItemId,
                                                item_type:
                                                    deleteItemType as number,
                                                sub_total,
                                            })
                                        ).then((result) => {
                                            setState((prev) => ({
                                                ...prev,
                                                deleteItemId: null,
                                                deleteCartItemModal: false,
                                                deleteItemType: null,
                                            }));
                                        });
                                }}
                                content={
                                    <p>
                                        Are you sure you want to remove this
                                        item from the cart?
                                    </p>
                                }
                            />
                        )}
                    </div>
                </div>
                <div className="cart-select-right">
                    <div className="search-header-form">
                        <div className={`tab-nav ${free_trial_period_enabled && !trial_expired && !upgrade_subscription ? "d-none" : ""}`}>
                            <ul
                                className="nav nav-pills"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li
                                    onClick={() => {
                                        clickOutSideSearch();
                                        dispatch(setSelectedType(BASINS));
                                    }}
                                >
                                    <a
                                        href="void:(0)"
                                        onClick={(e) => e.preventDefault()}
                                        id="pills-by-basin-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-basin"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                        className={basinClasses}
                                    >
                                        By Basin
                                    </a>
                                </li>
                                <li
                                    onClick={() => {
                                        clickOutSideSearch();
                                        dispatch(setSelectedType(COUNTIES));
                                    }}
                                >
                                    <a
                                        href="void:(0)"
                                        onClick={(e) => e.preventDefault()}
                                        id="pills-by-county-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-county"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                        className={countyClasses}
                                    >
                                        By County
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="search-form">
                            <SearchComponent
                                placeholder={`Search for ${selectedType === BASINS ? "Basin" : "County"
                                    }...`}
                                onSearchChange={onSearchChange}
                                onSearchBtnClick={getSearchList}
                                onFocus={onSearchFocus}
                            />
                            <SearchList />
                        </div>
                    </div>

                    <figure onClick={clickOutSideSearch}>
                        <MapComponent disablePopupPointerEvents mapType={MapType.Cart} allowSelection={true} zoomType="large" />
                    </figure>
                </div>
            </div>
            <MonthlyToYearlyUpgradeMsgModal />
        </div >
    );
};

export default CartSelectBasinView;
