import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    getAnalyticsData,
    handleAnalyticsProdFilters,
    handleNormalize,
    handleResizableWidth,
    toggleViewAnalytics,
    clearSelectedWellsRigs,
    setGraphDataLoading
} from "../store/actions/wells-rigs-action";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    LINE_CHART_MAX_ITEM_ALLOWED, ANALYTICS_MONTHLY_TAB,
    VERTICAL, ANALYTICS_DEFAULT_WIDTH,
} from "../../utils/helper";
import AnalyticsRightSidebarProductionTab from "./AnalyticsRightSidebarProductionTab"
import AnalyticsRightSidebarWellAttributesTab from "./AnalyticsRightSidebarWellAttributesTab"
import { getSelectedWell } from "./AoiUtils"


const AnalyticsRightSidebar = ({ resizableWidth, draggableRef, startDrag }: { resizableWidth: number, draggableRef: React.RefObject<HTMLDivElement>, startDrag: (event: React.MouseEvent<HTMLDivElement>) => void }) => {
    const access_token = useAppSelector((state) => state.auth.user.access_token);
    const wellsAndRigs = useAppSelector((state) => state.wellsAndRigs);
    const dispatch = useAppDispatch();

    {/* The goal here is have the sidebar open with well information when:
        * the well is clicked in the table 
        * the well is clicked on the map
        
        The first condition is handled in the components/common/WellsAndRigs/Filter
        whereas, the second condition is handled when selectedWellRigOrPermitUIDList is updated via a map click. 
        We hook into this state change here
         */}
    useEffect(() => {
        let selectedWell: WellsAndPermitsObject | null = null
        if (wellsAndRigs.selectedWellRigOrPermitUIDList.length === 1) {
            selectedWell = getSelectedWell(wellsAndRigs.wellsData.data, wellsAndRigs.selectedWellRigOrPermitUIDList[0])
        }
    }, [wellsAndRigs.selectedWellRigOrPermitUIDList])

    useEffect(() => {
        // We should not show the Analytics sidebar if there are no wells in the list.  However,
        // we should show the sidebar if the selected tab is not 0 to show well attributes
        if (wellsAndRigs.selectedWellRigOrPermitList.length === 0 && wellsAndRigs.viewAnalytics && wellsAndRigs.analyticsTabIndex === 0) {
            dispatch(toggleViewAnalytics(false));
            dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));
            return
        }

        if (wellsAndRigs.selectedWellRigOrPermitList.length > 0 && wellsAndRigs.viewAnalytics) {
            // let tempList = (wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? [...wellsAndRigs.selectedWellRigOrPermitList].slice(0, LINE_CHART_MAX_ITEM_ALLOWED) : wellsAndRigs.selectedWellRigOrPermitList)
            let tempList = wellsAndRigs.selectedWellRigOrPermitList

            if (JSON.stringify(tempList) === JSON.stringify(wellsAndRigs.analyticsData.apiListObj)) {
                return
            }

            let tempApiListObjLength = tempList.filter((_item) => {
                return wellsAndRigs.tabIndex === 1 ? (_item as RigsDataObj)['profile']?.toLocaleLowerCase() === VERTICAL : (_item as WellsAndPermitsObject)["drill_type"]?.toLocaleLowerCase() === VERTICAL
            }).length
            // we need to set the loading to true to get the updated production from the getAnalyticsData call
            // in the useEffect below that triggers after an update to the apiList
            dispatch(setGraphDataLoading(true))
            dispatch(handleAnalyticsProdFilters({
                apiList: tempList?.map((item) => (wellsAndRigs.tabIndex === 0 || wellsAndRigs.tabIndex === 2) ? (item as WellsAndPermitsObject).well_api : wellsAndRigs.tabIndex === 3 ? (item as ProductionDataObj).well_api : (item as RigsDataObj).api),
                apiListObj: tempList,
                apiListObjLength: tempApiListObjLength
            }))

            if (wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED) {
                setState((prev) => ({
                    ...prev,
                    // showDropDownOption: true,
                    closeOilMaxMsg: false, closeGasMaxMsg: false
                }))
            }
            dispatch(handleAnalyticsProdFilters({ action: wellsAndRigs.selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? 'average' : 'none', type: wellsAndRigs.analyticsData.type }));
            tempList.length === tempApiListObjLength && dispatch(handleNormalize(false))

        }
        // eslint-disable-next-line
    }, [wellsAndRigs.selectedWellRigOrPermitList, wellsAndRigs.viewAnalytics])

    const [state, setState] = useState<{
        //  dowData: DowData[], 
        showDropDownOption: boolean, closeOilMaxMsg: boolean, closeGasMaxMsg: boolean
    }>({
        //  dowData: [],
        showDropDownOption: false, closeOilMaxMsg: false, closeGasMaxMsg: false
    })

    const { showDropDownOption } = state;
    useEffect(() => {
        if (wellsAndRigs.analyticsData.apiList.length) {
            //only selecting number (LINE_CHART_MAX_ITEM_ALLOWED) max allowed items api to send to backend
            wellsAndRigs.analyticsData.graphDataLoading && dispatch(getAnalyticsData(access_token, { api_id: wellsAndRigs.analyticsData.apiList, type: ANALYTICS_MONTHLY_TAB, action: wellsAndRigs.analyticsData.action, XAxis: wellsAndRigs.analyticsData.xAxisFilter }, false));
        }
        // eslint-disable-next-line    
    }, [JSON.stringify(wellsAndRigs.analyticsData.apiList)])


    return (
        <>
            <div className={`${wellsAndRigs.viewAnalytics ? "analyticsCon open" : "d-none"}`}
                onClick={() => {
                    showDropDownOption && setState((prev) => ({ ...prev, showDropDownOption: false }))
                }}
                id="Resizable2"
                style={{
                    ...({ width: `${100 - (resizableWidth)}%` }),
                }}
            >
                <div className="column-full-height">
                    <div
                        ref={draggableRef}
                        onMouseDown={startDrag}
                        className="draggableEle"
                    ></div>
                    <div className="analytics-header">{wellsAndRigs.analyticsTabIndex === 0 ? (<span><img src="images/analytics.svg" alt="" /> Analytics</span>) : (<span>
                        <img src="images/pumpjack.svg" alt="Pumpjack" className="margin-bottom-point-3" /> Well Attributes
                    </span>)}

                        <span className="close-btn"
                            onClick={(e) => {
                                if (wellsAndRigs.analyticsTabIndex === 0) {
                                    dispatch(toggleViewAnalytics(false))
                                }
                                dispatch(clearSelectedWellsRigs())
                                dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));

                            }}
                        >
                            <i className="fa-solid fa-xmark" />
                        </span>
                    </div>
                    {wellsAndRigs.analyticsTabIndex === 0 ? <AnalyticsRightSidebarProductionTab /> :
                        wellsAndRigs.analyticsTabIndex === 1 ? <AnalyticsRightSidebarWellAttributesTab /> : <></>}</div>
            </div>

        </>
    );
};

export default AnalyticsRightSidebar;
