import { getStripeInvoiceStatus } from "../components/store/actions/subscription-settings-actions";

export const resetValue = (id: string) => {
    let element = document.getElementById(id)
    if (element) (element as HTMLSelectElement | HTMLInputElement).value = ""
}

export const hasInvoiceBeenCreated = async (stripeInvoiceID: string, dispatch: Function) => {
    let attempts = 0;
    const maxAttempts = 20;
    const delay = 2000;

    const attemptConfirmInvoice: Function = async () => {
        attempts++

        const invoiceResponse = await dispatch(getStripeInvoiceStatus(stripeInvoiceID))
        let hasInvoice: boolean = invoiceResponse?.hasInvoice || false
        let invoiceError: string = invoiceResponse?.invoiceError || ''
        let invoiceId: string = invoiceResponse?.invoiceId || ''

        if (!hasInvoice) {
            if (attempts < maxAttempts) {
                // Wait for the delay before attempting again
                await new Promise(resolve => setTimeout(resolve, delay));
                return await attemptConfirmInvoice(); // Recursive call
            } else {
                console.log(`Max attempts (${maxAttempts}) reached without success.`);
                return { hasInvoice, invoiceError, invoiceId };
            }
        } else {
            // If the status is not processing, just return the result
            return { hasInvoice, invoiceError, invoiceId };
        }
    };

    const confirmInvoiceResult = await attemptConfirmInvoice();
    return confirmInvoiceResult;
};