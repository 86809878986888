import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import billingSettingsSlice from "../reducers/billing-settings-slice";
import { hideSiteLoader, showSiteLoader } from "./modal-actions";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import {
    ClientSecretReturn,
    AttachPaymentReturn,
    FetchPaymentHistoryDataSubmitForm,
    UpdateBankAccountDetails,
    UpdateBillingAddressModalSubmitForm,
    UpdateBillingAddressReturnType,
    UpdateCardDetailsFormData,
    UpdateCardDetailsReturnType,
} from "../../models/submit-form";
import { config, errToast, tokenIsValid } from "../../../utils/helper";
import { AxiosError } from "axios";

export const billingSettingsActions = billingSettingsSlice.actions;

// fetchPaymentMethodsData
export const fetchPaymentMethodsData =
    (token: string): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(showSiteLoader());
            await tokenIsValid(token);
            try {
                let response = await axios.get(
                    "api-subscriptions/update-payment-info",
                    config
                );
                let { data, status, msg } = response.data;
                if (status === 200) {
                    if (data?.stripe_cust_id) {
                        const {
                            company_name,
                            billing_email,
                            cc_details: {
                                cc_no,
                                name_on_card,
                                cc_exp_month,
                                cc_exp_year,
                                cc_brand,
                                payment_mode,
                            },
                            billing_address: {
                                first_address,
                                second_address,
                                city,
                                state,
                                zip_code,
                                country,
                            },
                            sale_tax_id,
                        } = data;
                        dispatch(
                            billingSettingsActions.fetchPaymentMethodsData({
                                company_name,
                                billing_email,
                                cc_no,
                                name_on_card,
                                cc_exp_month,
                                cc_exp_year,
                                cc_brand,
                                first_address,
                                second_address,
                                city,
                                state,
                                zip_code,
                                country,
                                sale_tax_id: sale_tax_id ? sale_tax_id : "",
                                payment_mode,
                            })
                        );
                    }
                } else {
                    toast.error(msg);
                }

                dispatch(hideSiteLoader());
            } catch (err) {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            }
        };

// clearSubscriptionData
export const clearPaymentMethodsData =
    (): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(billingSettingsActions.clearPaymentMethodsData());
        };

//updateCardDetails
export const updateCardDetails = (
    token: string,
    formData: UpdateCardDetailsFormData | UpdateBankAccountDetails,
    showLoader = false
): ThunkAction<
    Promise<UpdateCardDetailsReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        showLoader && dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/update-payment-info",
                formData,
                config
            );
            showLoader && dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            showLoader && dispatch(hideSiteLoader());
        }
    };
};

//updateBillingDetails
export const updateBillingDetail = (
    token: string,
    formData: UpdateBillingAddressModalSubmitForm,
    showLoader = false
): ThunkAction<
    Promise<UpdateBillingAddressReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        showLoader && dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/update-billing",
                formData,
                config
            );
            showLoader && dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            showLoader && dispatch(hideSiteLoader());
        }
    };
};

//fetchPaymentHistoryData
export const fetchPaymentHistoryData = (
    token: string,
    formData: FetchPaymentHistoryDataSubmitForm
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        const { page, recent } = formData;
        try {
            const res = await axios.get(
                `/api-subscriptions/invoice-details?page=${page}${typeof recent !== "undefined" ? `&recent=${recent}` : ""
                }`,
                config
            );
            const { status, msg, data } = res.data;
            if (status === 200) {
                if (data) {
                    dispatch(
                        billingSettingsActions.fetchPaymentHistoryData(data)
                    );
                }
            } else {
                toast.error(msg);
            }
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

// clearPaymentHistoryData
export const clearPaymentHistoryData =
    (removeLatest = false): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(
                billingSettingsActions.clearPaymentHistoryData({ removeLatest })
            );
        };


export const attachPaymentMethodToSubscription = (
    token: string,
    stripe_details_id: number,
    payment_method_id: string,
): ThunkAction<Promise<AttachPaymentReturn>, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/attach-payment-method-to-subscription",
                { stripe_details_id, payment_method_id },
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            console.log("the error from financial connections is ", err);
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};
export const fetchFinancialConnectionsURL = (
    token: string,
): ThunkAction<Promise<ClientSecretReturn>, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.get(
                "/api-subscriptions/financial-connections",
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            console.log("the error from financial connections is ", err);
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};


//get client secret
export const fetchClientSecret = (
    token: string,
    formData?: { invoice_id: string, payment_method_id: string }
): ThunkAction<Promise<ClientSecretReturn>, RootState, unknown, AnyAction> => {

    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-subscriptions/get-setup-or-payment-intent",
                formData,
                config
            );
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};

export const setBillingSetttingsTabIndex = (
    tabIndex: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(billingSettingsActions.setBillingSetttingsTabIndex(tabIndex));
    };
};