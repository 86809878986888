import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import wellsAndRigs, {
    wellColumnInitialData,
    rigColumnInitialState,
} from "../reducers/wells-rigs-slice";
import {
    ActionType,
    ColPayload,
    CountyStatObject,
    ForecastFilterObj,
    ForecastingFormObj,
    HeatmapGridStatObject,
    ProductionDataObj,
    RigsDataObj,
    WellLineObject,
    WellsAndPermitsObject,
    WellsRigsModel,
    XAxisFilterType,
    tableColObje,
} from "../../models/redux-models";
import {
    FetchStateAdvFilterReturnType,
    ReturnMsgAndStatus,
    SaveSegmentAdvFilterFormData,
    WellsOrRigsReturnType,
    ReturnProductionDataByLease,
} from "../../models/submit-form";
import {
    hideSiteLoader,
    showSiteLoader,
    toggleDownloadColMsgModal,
} from "./modal-actions";
import {
    SetPanelFilter,
    UpdateHeatmapGridTotals,
} from "../../map/redux/filters";
import {
    ANALYTICS_CUM_TAB,
    LINE_CHART_MAX_ITEM_ALLOWED,
    ANALYTICS_MONTHLY_TAB,
    OPERATOR,
    config,
    errToast,
    tokenIsValid,
    capitalize,
    actionType,
    rigs,
    jsonToString,
} from "../../../utils/helper";
import axios from "../../../utils/axios";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { logUserAction } from "./auth-actions";
import {
    toggleLeftTab,
    handleSavedFilterColumnProperties,
} from "../actions/cart-basin-to-county-actions";
import { isInstanceOfWellsAndPermitsObject } from "../../common/Map/helper";

export const wellsAndRigsActions = wellsAndRigs.actions;

export const showHideComp = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.showHideComp());
    };
};

export const showHideAdvFilter = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.showHideAdvFilter());
    };
};

export const showHideColProperties = (
    actionPayload?: boolean | undefined
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        let actionState = !getState().wellsAndRigs.colProperties;
        if (typeof actionPayload !== "undefined") {
            actionState = actionPayload;
        }
        dispatch(wellsAndRigsActions.showHideColProperties({ actionState }));
    };
};

export const showHideFullScreen = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.showHideFullScreen());
    };
};

export const showHideCsvDownOpt = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.showHideCsvDownOpt());
    };
};

export const updateTableColName = (
    tableColName: WellsRigsModel["tableColName"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.updateTableColName({ tableColName }));
    };
};

export const updateWellsOrRigsTableColSelectedList = (
    tableCol:
        | WellsRigsModel["tableCol"]
        | WellsRigsModel["rigsTableCol"]
        | WellsRigsModel["productionCol"],
    tabIndex: WellsRigsModel["tabIndex"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        if (tabIndex === 0) {
            dispatch(wellsAndRigsActions.updateWellsTableCol(tableCol));
        }
        if (tabIndex === 1) {
            dispatch(wellsAndRigsActions.updateRigsTableCol(tableCol));
        }
    };
};
export const updateWellsOrRigsTableCol = (
    tableCol:
        | WellsRigsModel["tableColFullList"]
        | WellsRigsModel["rigsTableColFullList"]
        | WellsRigsModel["productionCol"],
    tabIndexArg?: WellsRigsModel["tabIndex"],
    tableColNameArg?: WellsRigsModel["tableColName"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        let tabIndex = tabIndexArg;
        let tableColName = tableColNameArg;
        const state = getState();
        if (typeof tableColName === "undefined") {
            tableColName = state.wellsAndRigs.tableColName;
        }
        if (typeof tabIndex === "undefined") {
            tabIndex = state.wellsAndRigs.tabIndex || 0;
        }

        let newTableCols = tableCol.filter(
            (item: tableColObje) =>
                item.tableColName === tableColName && item.tabIndex === tabIndex
        );
        if (!newTableCols || newTableCols.length == 0) {
            if (tabIndex === 0) {
                newTableCols = wellColumnInitialData;
            }
            if (tabIndex === 1) {
                newTableCols = rigColumnInitialState;
            }
            dispatch(updateTableColName("default"));
        }
        if (tabIndex === 0) {
            dispatch(wellsAndRigsActions.updateWellsTableCol(newTableCols));
        }
        if (tabIndex === 1) {
            dispatch(wellsAndRigsActions.updateRigsTableCol(newTableCols));
        }
        dispatch(
            wellsAndRigsActions.updateWellsOrRigsTableColFullList({
                data: tableCol,
                tabIndex,
                tableColName,
            })
        );
    };
};

export const resetWellsAndRigsSliceToInitial = (
    holdPrevVal = false
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(
            wellsAndRigsActions.resetWellsAndRigsSliceToInitial({
                holdPrevVal,
            })
        );
    };
};

export const toggleChooseColExportToCsvModal = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.toggleChooseColExportToCsvModal());
    };
};

export const toggleExportOtherCsvModal = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.toggleExportOtherCsvModal());
    };
};

export const loadWellsData = (data: {
    data: WellsAndPermitsObject[];
    total_permit: number;
    total_rigs: number;
    total_production: number;
    total_count: number;
    page_size: number;
    notConCatData?: boolean;
    download_threshold?: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    /*  Load wells data is called in getWellsAndPermitList, which is triggered by a map selection. 
    To step back, we have originally have 1,000 wells in the wellsData, but the user may select
    wells on the map outside of this list.  That triggers clearWellsData in TabSection, sets wellsLoading
    to true, the calls getWellsAndPermitList and finally loadWellsData.

    However, now there are selected well ids in selectedWellRigOrPermitUIDList that couldn't be
    in selectedWellRigOrPermitList until we got the updated list. 
    */

    return async (dispatch, getState) => {
        const state = getState();
        const selectedWells =
            data.data?.filter(
                (
                    wellRigOrPermit:
                        | ProductionDataObj
                        | RigsDataObj
                        | WellsAndPermitsObject
                ) =>
                    state.wellsAndRigs.selectedWellRigOrPermitUIDList.includes(
                        wellRigOrPermit.id.toString()
                    )
            ) || [];

        if (!state.wellsAndRigs.dont_update_selected_wells_rigs) {
            dispatch(handleSelectedWellRigOrPermitList(selectedWells));
            dispatch(wellsAndRigsActions.loadWellsData(data));
        } else {
            /* The issue that we were having is that scrolling down and paginating the list
            of 1000 wells or rigs was nuking the selected wells/rigs.  So if we set this flag
            dont_update_selected_wells_rigs then we keep the selected wells/rigs in the list*/
            dispatch(wellsAndRigsActions.loadWellsData(data));
            dispatch(setDontUpdateSelectedWellsRigs(false));
        }
    };
};

export const loadPermitsData = (data: {
    data: WellsAndPermitsObject[];
    total_rigs: number;
    total_well: number;
    total_production: number;
    total_count: number;
    page_size: number;
    notConCatData?: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.loadPermitsData(data));
    };
};

export const loadRigsData = (data: {
    data: RigsDataObj[];
    total_permit: number;
    total_well: number;
    total_production: number;
    total_count: number;
    page_size: number;
    notConCatData?: boolean;
    download_threshold?: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.loadRigsData(data));
    };
};

export const loadProductionData = (data: {
    data: ProductionDataObj[];
    total_permit: number;
    total_rigs: number;
    total_well: number;
    total_count: number;
    page_size: number;
    notConCatData?: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.loadProductionData(data));
    };
};

//getWells
export const getWellsAndPermitList = (
    token: string,
    formData: {
        page?: number;
        search_type?: string;
        search_param?: string;
        aoi_id?: number;
        sort_order?: string;
        sort_by?: string;
        uid?: string[];
        rig_id_query_string?: boolean;
        [x: string]: any;
        export_well_header_or_production?: WellsRigsModel["export_well_header_or_production"];
    },
    showLoader = true
): ThunkAction<
    Promise<WellsOrRigsReturnType>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        showLoader && dispatch(handleTableLoader(true));
        const {
            wellsAndRigs: {
                wellsData: {
                    data: wellsDataList,
                    total_count: wellsTotalCount,
                },
                rigsData: { data: rigsDataList, total_count: rigsTotalCount },
                permitsData: {
                    data: permitDataList,
                    total_count: permitTotalCount,
                },
                productionData: {
                    data: productionDataList,
                    total_count: productionTotalCount,
                },
                tabIndex,
                selectedWellRigOrPermitUIDList,
                dont_update_selected_wells_rigs,
                export_well_header_or_production,
                selected_or_all_rows,
                rapidAPIFromInput
            },
        } = getState();
        let rig_id_query_string =
            "rig_id_query_string" in formData
                ? formData.rig_id_query_string
                : null;
        delete formData["rig_id_query_string"];
        let tempData =
            tabIndex === 0
                ? wellsDataList || []
                : tabIndex === 1
                    ? rigsDataList || []
                    : tabIndex === 2
                        ? permitDataList
                        : productionDataList;
        tempData = tempData === null ? [] : tempData;
        if ("download" in formData) {
            formData.export_well_header_or_production = export_well_header_or_production;
            if (selected_or_all_rows === 2) {
                formData.selected_uids = selectedWellRigOrPermitUIDList;
            }
        }
        await tokenIsValid(token);


        try {
            if (dont_update_selected_wells_rigs) {
                delete formData.uid;

            }
            const res = await axios.post(
                `/api-aoi/get-wells`,
                formData,
                config
            );

            if ("filter_param" in formData) {
                dispatch(
                    handleAdvFilterSearch({
                        filter: "",
                        filter_param: [],
                        segment_id: 0,
                    })
                );
            }
            if ("well_type" in formData) {
                dispatch(SetPanelFilter(formData));
            }
            if (
                "filter" in formData &&
                formData.filter === "advanced" &&
                formData.search_type !== "well_count_by_county"
            ) {
                //log advanced filter
                dispatch(
                    logUserAction({
                        action_type: actionType["execute_advanced_filter"],
                        action_log_detail: JSON.stringify(formData),
                        // action_log_detail: "Advanced filter search."
                    })
                );
            }
            const { data: dataObj, status, msg, download_limit } = res.data;
            if ("download" in formData) {
                dispatch(handleDownloadCol({ downloadCol: 0, allCol: 0 }));
                dispatch(handleDownloadColMsg(dataObj.message));
                dispatch(toggleDownloadColMsgModal());
                showLoader && dispatch(handleTableLoader(false));
                //log table data when data is greater than limit
                dispatch(
                    logUserAction({
                        action_type: actionType["download_docs"],
                        // action_log_detail:
                        //     "Table data, when data is greater than download limit.",
                        action_log_detail: JSON.stringify(formData),
                    })
                );
                return;
            }
            //reset the downloadCol and allCol
            status === 200 &&
                "downloadCol" in formData &&
                dispatch(
                    handleDownloadCol({
                        downloadCol: 0,
                        allCol: 0,
                        excel_format: false,
                    })
                );

            if (!dataObj) {
                if (formData.search_type === "well_count_by_county") {
                    return;
                }
                if (formData.search_type === "permit") {
                    dispatch(
                        loadPermitsData({
                            data: [],
                            total_count: 0,
                            page_size: 0,
                            total_rigs: 0,
                            total_well: 0,
                            total_production: 0,
                            notConCatData: true,
                        })
                    );
                    // dispatch(hideSiteLoader());
                    showLoader && dispatch(handleTableLoader(false));
                    return;
                }

                if (formData.search_type === "rigs") {
                    dispatch(
                        loadRigsData({
                            data: [],
                            total_count: 0,
                            page_size: 0,
                            total_permit: 0,
                            total_well: 0,
                            total_production: 0,
                            notConCatData: true,
                        })
                    );
                    // dispatch(hideSiteLoader());
                    showLoader && dispatch(handleTableLoader(false));
                    return;
                }

                if (formData.search_type === "production") {
                    dispatch(
                        loadProductionData({
                            data: [],
                            total_count: 0,
                            page_size: 0,
                            total_permit: 0,
                            total_well: 0,
                            total_rigs: 0,
                            notConCatData: true,
                        })
                    );
                    // dispatch(hideSiteLoader());
                    showLoader && dispatch(handleTableLoader(false));
                    return;
                }

                dispatch(
                    loadWellsData({
                        data: [],
                        total_count: 0,
                        page_size: 0,
                        total_permit: 0,
                        total_rigs: 0,
                        total_production: 0,
                        notConCatData: true,
                    })
                );
                // dispatch(hideSiteLoader());
                showLoader && dispatch(handleTableLoader(false));
                return;
            }
            if (status === 200 && dataObj) {
                const {
                    data,
                    page_size,
                    total_count,
                    total_permit,
                    total_rigs,
                    total_well,
                    total_production,
                } = dataObj;
                const { search_type, search_param, page } = formData;

                if (search_type) {
                    search_type === "well_count_by_grid_id" &&
                        Array.isArray(dataObj) &&
                        dispatch(
                            UpdateHeatmapGridTotals(
                                dataObj.map((item: HeatmapGridStatObject) => ({
                                    ...item,
                                }))
                            )
                        );

                    search_type === "permit" &&
                        dispatch(
                            loadPermitsData({
                                data:
                                    "uid" in formData
                                        ? [
                                            ...data.map(
                                                (
                                                    item: WellsAndPermitsObject
                                                ) => ({
                                                    ...item,
                                                    id: item.uid,
                                                    checked: true,
                                                })
                                            ),
                                            ...(
                                                tempData as WellsAndPermitsObject[]
                                            ).map(
                                                (
                                                    _item: WellsAndPermitsObject
                                                ) =>
                                                    JSON.stringify(
                                                        formData.uid
                                                    ).includes(`${_item.uid}`)
                                                        ? {
                                                            ..._item,
                                                            checked: true,
                                                        }
                                                        : _item
                                            ),
                                        ]
                                        : data.map(
                                            (
                                                item: WellsAndPermitsObject
                                            ) => ({
                                                ...item,
                                                checked: false,
                                                id: item.uid,
                                            })
                                        ),
                                total_count:
                                    "uid" in formData
                                        ? permitTotalCount
                                        : // + total_count
                                        total_count,
                                page_size,
                                total_rigs,
                                total_well,
                                total_production,
                                ...(search_param !== "undefined" && {
                                    notConCatData: page === 1 ? true : false,
                                }),
                            })
                        );
                    if (search_type === "rigs") {
                        /* First we see if the current rig data already has the UID.  And if so,
                        then just set it to that. In that way, it will maintain the order of the list
                        in the row, rather than bump the uid to the top.  
                        If that uid is not in the list, then bump them to the top*/
                        let newRigsList: RigsDataObj[] = [];
                        const selectedRigsAlreadyInTheList =
                            data?.filter((rig: RigsDataObj | null) =>
                                selectedWellRigOrPermitUIDList.includes(
                                    rig?.id ? rig.id.toString() : ""
                                )
                            ) || [];

                        if (
                            selectedRigsAlreadyInTheList.length ===
                            selectedWellRigOrPermitUIDList.length
                        ) {
                            // if all the selected data is already in the list, then keep the same order
                            newRigsList = data;
                        } else {
                            // else move the new data to the front
                            const newSelectedRigsInTheFront = data.filter(
                                (
                                    rig:
                                        | RigsDataObj
                                        | WellsAndPermitsObject
                                        | ProductionDataObj
                                ) =>
                                    selectedWellRigOrPermitUIDList.includes(
                                        rig?.id ? rig?.id.toString() : ""
                                    ) &&
                                    !selectedRigsAlreadyInTheList.includes(
                                        rig?.id ? rig?.id.toString() : ""
                                    )
                            );
                            const theOtherRigs = data.filter(
                                (
                                    rig:
                                        | RigsDataObj
                                        | WellsAndPermitsObject
                                        | ProductionDataObj
                                ) =>
                                    !(
                                        selectedWellRigOrPermitUIDList.includes(
                                            rig?.id ? rig?.id.toString() : ""
                                        ) &&
                                        !selectedRigsAlreadyInTheList.includes(
                                            rig?.id ? rig?.id.toString() : ""
                                        )
                                    )
                            );

                            newRigsList = [
                                ...newSelectedRigsInTheFront,
                                ...theOtherRigs,
                            ];
                        }
                        // we had problems with setting the total number of wells to 0
                        // on load rigs, so we're only updating total wells count if theres a value
                        const newWellsCount = total_well || wellsTotalCount;
                        dispatch(
                            loadRigsData({
                                data: [
                                    ...newRigsList,
                                    ...("aoi_id" in formData
                                        ? (tempData as RigsDataObj[]).filter(
                                            (item) =>
                                                !JSON.stringify(
                                                    newRigsList
                                                ).includes(`${item["id"]}`)
                                        )
                                        : []),
                                ],
                                total_count:
                                    "uid" in formData || "aoi_id" in formData
                                        ? rigsTotalCount === 0 &&
                                            total_count !== 0
                                            ? total_count
                                            : rigsTotalCount
                                        : // + total_count
                                        total_count,
                                page_size,
                                total_permit,
                                total_well:
                                    "aoi_id" in formData
                                        ? wellsTotalCount
                                        : newWellsCount,
                                total_production,
                                ...(search_param !== "undefined" && {
                                    notConCatData:
                                        page === 1 ||
                                            "uid" in formData ||
                                            "aoi_id" in formData
                                            ? true
                                            : false,
                                }),
                                ...(download_limit && {
                                    download_threshold: Number(download_limit),
                                }),
                            })
                        );
                        rig_id_query_string !== null &&
                            rig_id_query_string &&
                            Array.isArray(data) &&
                            "uid" in formData &&
                            dispatch(
                                handleSelectedWellRigOrPermitList(
                                    data.filter((rig: RigsDataObj) =>
                                        formData.uid?.includes(`${rig.id}`)
                                    )
                                )
                            );
                    }

                    search_type === "production" &&
                        dispatch(
                            loadProductionData({
                                data:
                                    "uid" in formData
                                        ? [
                                            ...data.map(
                                                (
                                                    item: WellsAndPermitsObject
                                                ) => ({
                                                    ...item,
                                                    id: item.uid,
                                                    checked: true,
                                                })
                                            ),
                                            ...(
                                                tempData as WellsAndPermitsObject[]
                                            ).map(
                                                (
                                                    _item: WellsAndPermitsObject
                                                ) =>
                                                    JSON.stringify(
                                                        formData.uid
                                                    ).includes(`${_item.uid}`)
                                                        ? {
                                                            ..._item,
                                                            checked: true,
                                                        }
                                                        : _item
                                            ),
                                        ]
                                        : data.map(
                                            (
                                                item: WellsAndPermitsObject
                                            ) => ({
                                                ...item,
                                                id: item.uid,
                                                checked: false,
                                            })
                                        ),
                                total_count:
                                    "uid" in formData
                                        ? productionTotalCount
                                        : // + total_count
                                        total_count,
                                page_size,
                                total_permit,
                                total_well,
                                total_rigs,
                                ...(search_param !== "undefined" && {
                                    notConCatData:
                                        page === 1 || "uid" in formData
                                            ? true
                                            : false,
                                }),
                            })
                        );
                } else {
                    let temp_wells_data =
                        "uid" in formData
                            ? [
                                ...data.map(
                                    (item: WellsAndPermitsObject) => ({
                                        ...item,
                                        id: item.uid,
                                        checked: true,
                                    })
                                ),
                                ...(
                                    tempData as WellsAndPermitsObject[]
                                ).filter(
                                    (_item: WellsAndPermitsObject) =>
                                        !JSON.stringify(
                                            formData.uid
                                        ).includes(`${_item.uid}`)
                                ),
                            ]
                            : [
                                ...(rapidAPIFromInput ? (
                                    tempData as WellsAndPermitsObject[]
                                ).filter(
                                    (item) =>
                                        item.well_api.trim() === rapidAPIFromInput.trim()
                                ) : []),
                                ...(rapidAPIFromInput ? (
                                    data as WellsAndPermitsObject[]
                                ).filter(
                                    (item) =>
                                        item.well_api.trim() !== rapidAPIFromInput.trim()
                                ) : data),
                                ...("aoi_id" in formData
                                    ? (
                                        tempData as WellsAndPermitsObject[]
                                    ).filter(
                                        (item) =>
                                            !JSON.stringify(data).includes(
                                                `${item["uid"]}`
                                            )
                                    )
                                    : []),
                            ].map((item: WellsAndPermitsObject) => ({
                                ...item,
                                id: item.uid,
                                //   fracfocus_detail:
                                //       item.fracfocus_detail
                                //           ? JSON.stringify(
                                //                 item.fracfocus_detail
                                //             )
                                //           : item.fracfocus_detail,
                                checked: false,
                            }));

                    dispatch(
                        loadWellsData({
                            data: temp_wells_data,
                            total_count:
                                "uid" in formData || "aoi_id" in formData
                                    ? wellsTotalCount === 0 && total_count !== 0
                                        ? total_count
                                        : wellsTotalCount
                                    : // + total_count
                                    total_count,
                            page_size,
                            total_permit,
                            total_rigs:
                                "aoi_id" in formData
                                    ? rigsTotalCount
                                    : total_rigs,
                            total_production,
                            ...(search_param !== "undefined" && {
                                notConCatData:
                                    page === 1 ||
                                        "uid" in formData ||
                                        "aoi_id" in formData
                                        ? true
                                        : false,
                            }),
                            ...(download_limit && {
                                download_threshold: Number(download_limit),
                            }),
                        })
                    );

                    if (
                        rig_id_query_string !== null &&
                        !rig_id_query_string &&
                        Array.isArray(data) &&
                        "uid" in formData
                    ) {
                        dispatch(
                            handleSelectedWellRigOrPermitList(
                                temp_wells_data.filter(
                                    (well: WellsAndPermitsObject) =>
                                        formData.uid?.includes(`${well.uid}`)
                                )
                            )
                        );
                    }
                }
            } else {
                status !== 200 && toast.error(msg);
            }
            // showLoader && dispatch(hideSiteLoader());
            showLoader && dispatch(handleTableLoader(false));
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            // showLoader && dispatch(hideSiteLoader());
            showLoader && dispatch(handleTableLoader(false));
        }
    };
};

//handleTabIndex
export const handleTabIndex = (
    index: WellsRigsModel["tabIndex"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const state = getState();
        if (state.wellsAndRigs.tabIndex !== index) {
            dispatch(updateTableColName("default"));
            dispatch(
                handleSavedFilterColumnProperties({
                    openSavedFilter: false,
                })
            );
        }
        dispatch(wellsAndRigsActions.handleTabIndex(index));
    };
};

//handleSelectedAoiData
// pass zero to reset to intial
export const handleSelectedAoiData = (data: {
    aoi_id: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleSelectedAoiData(data));
    };
};

export const clearWellsData = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.clearWellsData());
    };
};

export const clearRigsData = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.clearRigsData());
    };
};

export const clearPermitData = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.clearPermitData());
    };
};

export const clearProductionData = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.clearProductionData());
    };
};

export const handlePageChange = (
    page: WellsRigsModel["wellsPage"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handlePageChange(page));
    };
};

export const setFilterSearch = (
    keyword: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setFilterSearch(keyword));
    };
};

export const clearFilterSearch = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.clearFilterSearch());
    };
};

//handleSortAndOrderBy
export const handleSortAndOrderBy = (data: {
    sort_by?: WellsRigsModel["sort_by"];
    sort_order: WellsRigsModel["sort_order"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleSortAndOrderBy(data));
    };
};

//toggle view Analytics
export const toggleViewAnalytics = (
    open?: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const {
            cartBasinToCounty: { leftTab },
        } = getState();
        if (leftTab && open) {
            dispatch(toggleLeftTab(false));
        }
        dispatch(wellsAndRigsActions.toggleViewAnalytics(open));
    };
};

// save segment in advanced filter
export const saveSegmentAdvFilter = (
    token: string,
    formData: SaveSegmentAdvFilterFormData
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-segment/segment",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            const { status, msg } = res.data;
            if (status === 200) {
                toast.success(msg);
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//handle advanced filter search
export const handleAdvFilterSearch = (data: {
    filter: WellsRigsModel["filter"];
    filter_param: WellsRigsModel["filter_param"];
    segment_id: WellsRigsModel["segment_id"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleAdvFilterSearch(data));
    };
};

//state options
export const fetchStateInAdvFilter =
    (
        token: string,
        data: { county: string }
    ): ThunkAction<
        Promise<FetchStateAdvFilterReturnType>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch) => {
            const { county } = data;
            await tokenIsValid(token);
            try {
                const response = await axios.get(
                    `/api-segment/get-state?county_name=${county}`,
                    config
                );
                const { status, msg } = response.data;
                if (status !== 200) toast.error(msg);
                return response.data;
            } catch (err) {
                errToast(err as AxiosError);
            }
        };

// update segment in advanced filter
export const updateSegmentAdvFilter = (
    token: string,
    formData: SaveSegmentAdvFilterFormData
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        await tokenIsValid(token);
        try {
            const res = await axios.put(
                "/api-segment/segment",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            const { status, msg } = res.data;
            if (status === 200) {
                toast.success(msg);
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//toggle table loader
export const handleTableLoader = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleTableLoader(val));
    };
};

//download col
export const handleDownloadCol = (data: {
    downloadCol: WellsRigsModel["downloadCol"];
    allCol?: WellsRigsModel["allCol"];
    excel_format?: WellsRigsModel["excel_format"];
    download_without_api_call?: WellsRigsModel["download_without_api_call"];
    dataToExport?: WellsRigsModel["export_well_header_or_production"];
    rowsToExport?: WellsRigsModel["selected_or_all_rows"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleDownloadCol(data));
    };
};

//download col msg
export const handleDownloadColMsg = (
    val: WellsRigsModel["downloadColMsg"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleDownloadColMsg(val));
    };
};

//toggle table
export const handleShowAndHideTable = (
    val: WellsRigsModel["showTable"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleShowAndHideTable(val));
    };
};

//toggle segment dropdown
export const handleShowAndHideSegmentDropDown = (
    val: WellsRigsModel["showSegmentDropDown"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleShowAndHideSegmentDropDown(val));
    };
};

// get analytics data
export const getAnalyticsData = (
    token: string,
    formData: {
        api_id: string[];
        type: typeof ANALYTICS_MONTHLY_TAB | typeof ANALYTICS_CUM_TAB;
        action: ActionType;
        XAxis: XAxisFilterType;
    },
    showToast?: boolean
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        // dispatch(showSiteLoader());
        await tokenIsValid(token);
        const path = "/api-search/graph-data";
        try {
            const res = await axios.post(
                path,
                {
                    api_id: formData.api_id,
                },
                config
            );
            const {
                status,
                msg,
                data: {
                    prod_liq,
                    prod_gas,
                    gas_agg_date,
                    gas_agg_month,
                    liq_agg_date,
                    liq_agg_month,
                    prod_well,
                    prod_lease,
                    lease_ids,
                    has_well_prod,
                    has_lease_prod,
                    allocated,
                },
            } = res.data;
            if (status === 200) {
                //log use view_analytics
                dispatch(
                    logUserAction({
                        action_type: actionType["view_analytics"],
                        action_log_detail: `api_id: ${formData["api_id"]}`,
                    })
                );
                dispatch(
                    handleAnalyticsData({
                        prodLiq: prod_liq || [],
                        prodGas: prod_gas || [],
                        gasAggDate: gas_agg_date || [],
                        gasAggMonth: gas_agg_month || [],
                        liqAggDate: liq_agg_date || [],
                        liqAggMonth: liq_agg_month || [],
                        singleWellProd: prod_well || [],
                        singleWellLeaseProd: prod_lease || [],
                        leaseIDs: lease_ids || [],
                        hasWellProd: has_well_prod,
                        hasLeaseProd: has_lease_prod,
                        allocated,
                        graphDataLoading: false,
                    })
                );

                dispatch(handleSetProductionDataLoading(false));
            } else {
                toast.error(msg);
                dispatch(wellsAndRigsActions.setGraphDataLoading(false));
                dispatch(handleSetProductionDataLoading(false));
            }
            // dispatch(hideSiteLoader());
            showToast &&
                toast.info(
                    `Graph is limited to production data from your first ${LINE_CHART_MAX_ITEM_ALLOWED} selections in the list below.`,
                    { autoClose: 5000 }
                );
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            // dispatch(hideSiteLoader());
        }
    };
};

//handle analytics data
export const handleAnalyticsData = (data: {
    prodLiq: WellsRigsModel["analyticsData"]["prodLiq"];
    prodGas: WellsRigsModel["analyticsData"]["prodGas"];
    gasAggDate: WellsRigsModel["analyticsData"]["gasAggDate"];
    gasAggMonth: WellsRigsModel["analyticsData"]["gasAggMonth"];
    liqAggDate: WellsRigsModel["analyticsData"]["liqAggDate"];
    liqAggMonth: WellsRigsModel["analyticsData"]["liqAggMonth"];
    singleWellProd: WellsRigsModel["analyticsData"]["singleWellProd"];
    singleWellLeaseProd: WellsRigsModel["analyticsData"]["singleWellLeaseProd"];
    leaseIDs: WellsRigsModel["analyticsData"]["leaseIDs"];
    hasWellProd: WellsRigsModel["analyticsData"]["hasWellProd"];
    hasLeaseProd: WellsRigsModel["analyticsData"]["hasLeaseProd"];
    allocated: WellsRigsModel["analyticsData"]["allocated"];
    graphDataLoading?: WellsRigsModel["analyticsData"]["graphDataLoading"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleAnalyticsData(data));
    };
};

//handle full screen analytics view
export const handleFullScrnAnalytics = (val: {
    fullScrnAnalytics: WellsRigsModel["fullScrnAnalytics"];
    fullScrnAnalyticsType?: WellsRigsModel["fullScrnAnalyticsType"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(toggleViewAnalytics(!val.fullScrnAnalytics));
        dispatch(wellsAndRigsActions.handleFullScrnAnalytics(val));
    };
};

//handle checked Item List
export const handleSelectedWellRigOrPermitUIDList = (
    data: WellsRigsModel["selectedWellRigOrPermitUIDList"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const state = getState();

        // Unify the well/rig/permit object list with the UID list
        /* I'm assuming that rigs and wells dont have overlapping ids
        we first look and see if the selected id would return at least 1 well, if not,
        see if it would return at least 1 rig. */
        let selectedWellRigOrPermitList: (
            | ProductionDataObj
            | RigsDataObj
            | WellsAndPermitsObject
        )[] =
            state.wellsAndRigs.wellsData.data?.filter(
                (
                    wellRigOrPermit:
                        | ProductionDataObj
                        | RigsDataObj
                        | WellsAndPermitsObject
                ) => data.includes(wellRigOrPermit.id.toString())
            ) || [];
        if (selectedWellRigOrPermitList.length === 0) {
            selectedWellRigOrPermitList =
                state.wellsAndRigs.rigsData.data?.filter(
                    (
                        wellRigOrPermit:
                            | ProductionDataObj
                            | RigsDataObj
                            | WellsAndPermitsObject
                    ) => data.includes(wellRigOrPermit.id.toString())
                ) || [];
        }

        const newUIDList = [...new Set(data)];
        // if the right side card is closed and we selected 1 well, then have
        // well card be opened up (not production).  This is a special case,
        // because if the right card is already open, and on the analytics tab
        // and the user switches to another well, we want to stay on analytics
        if (newUIDList.length === 1 && !state.wellsAndRigs.viewAnalytics) {
            dispatch(wellsAndRigsActions.handleAnalyticsTabIndex(1));
        }
        // close/open the right well sidecard if there is 1 well selected and it's the well card (not the analytics)
        if (
            state.wellsAndRigs.analyticsTabIndex === 1 ||
            !state.wellsAndRigs.viewAnalytics
        ) {
            dispatch(toggleViewAnalytics(newUIDList.length === 1));
        }
        if (selectedWellRigOrPermitList.length === 1) {
            dispatch(
                wellsAndRigsActions.handleSelectedRowId(
                    selectedWellRigOrPermitList[0].id
                )
            );
        }

        dispatch(
            wellsAndRigsActions.handleSelectedWellRigOrPermitList(
                selectedWellRigOrPermitList || []
            )
        );
        if (newUIDList.length === 1) {
            // If there is a new selected well then we need to clear the production data
            dispatch(
                handleAnalyticsData({
                    prodLiq: [],
                    prodGas: [],
                    gasAggDate: [],
                    gasAggMonth: [],
                    liqAggDate: [],
                    liqAggMonth: [],
                    singleWellProd: [],
                    singleWellLeaseProd: [],
                    leaseIDs: [],
                    hasWellProd: false,
                    hasLeaseProd: false,
                    allocated: false,
                })
            );
            dispatch(
                handleAnalyticsProdFilters({
                    apiList: [],
                    apiListObj: [],
                    apiListObjLength: 0,
                })
            );
            dispatch(wellsAndRigsActions.handleProductionTabIndex(0));
        }
        dispatch(
            wellsAndRigsActions.handleSelectedWellRigOrPermitUIDList(newUIDList)
        );
    };
};

export const handleSelectedWellRigOrPermitList = (
    data: WellsRigsModel["selectedWellRigOrPermitList"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const state = getState();
        /* 

        Pre-August 2024, we had 2 states for 'selected' in the table (checked and highlighted).
        There were separate variables for selected wells on the map. 
        This resulted in a bunch of different edge case bugs, from trying to sync these 2 states
        on the map and the table.

        We decide to join as many of these separate variables in only a handful. 

        OLD checkedItemList: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[]   
            The checked item list originally was only if an item on the table was checked (not necessarily selected).
            This state was to group some wells for production analytics.  Around Aug 1, 2024, we conbined the
            checked and selected states into 1.
        NEW selectedWellRigOrPermitList:: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[]

        OLD handleCheckedItemList: Function
            A function to set the checkedItemList
        NEW handleSelectedWellRigOrPermitList: Function

        OLD featuresForStatistics: string[]
            The featuresForStatistics is the list of uids of the wells that selected on the map
        NEW selectedWellRigOrPermitUIDList: string[]

        OLD hightSelectedWell: Function
            This function set the uids for featuresForStatistics. In an ideal world, the selectedWellRigOrPermitUIDList
            could be 100% derived from the selectedWellRigOrPermitList objects.  However, in AoiMap.tsx, the feature
            layer is queried for uids, so we need a way to independently set the selectedWellRigOrPermitUIDList. However,
            selectedWellRigOrPermitList abd selectedWellRigOrPermitUIDList are interconnected.  If the wells or rig objects are 
            set, then the uid version of the is list is set to selectedWellRigOrPermitUIDList and vice versa.
        NEW selectedWellRigOrPermitUIDList

        OLD uid
            Another variable to keep track of uid selected that is now combined
        NEW selectedWellRigOrPermitUIDList

        OLD selectedRowId: string
            Originally, this variable was the UID of the 1 selected well on the table or the map.  If it was populated
            with a value, then that would trigger the well card to open and populate with the well in the wellsList
            associated with that id.  
        NEW selectedRowId

        OLD handleSelectedRowId: Function
            This function will be removed, and the selectedRowId will only be set when updating selectedWellRigOrPermitList
            and it has one item.  That item's uid will be the selected row id. 
        NEW none, only set internally when the length of selectedWellRigOrPermitList is 1

        OLD selectedFeature: string
            This uid was used in AoiMap.tsx to choose when to zoom to a well
        NEW selectedRowId
        */
        // Remove any duplicates that may exist
        const removeDuplicates = (
            items: (ProductionDataObj | RigsDataObj | WellsAndPermitsObject)[]
        ) => {
            const seen = new Set<string>();
            return items.filter((item) => {
                if (seen.has(item.id.toString())) {
                    return false;
                } else {
                    seen.add(item.id.toString());
                    return true;
                }
            });
        };
        data = removeDuplicates(data);
        // Unify the UID list with the well/rig/permit object list
        const selectedWellRigOrPermitUIDList = data.map(
            (
                wellRigOrPermit:
                    | ProductionDataObj
                    | RigsDataObj
                    | WellsAndPermitsObject
            ) => wellRigOrPermit.id.toString()
        );
        dispatch(
            wellsAndRigsActions.handleSelectedWellRigOrPermitUIDList([
                ...new Set(selectedWellRigOrPermitUIDList),
            ])
        );
        /* If the selected list is 1, then this uid will become the selectedWellId in wellsAndRigsActions.handleSelectedWellRigOrPermitList 
        There's other behavior that we need to also handle for just 1 selected well:
            - open the right side card if the selected object is a well (not a rig or permit)
            - set production and lease data to loading so it can get the correct data
            - close the left tab (if opened)
        If the selected list is empty:
            - set the right side card back to the 'Analytics' tab if we don't have a selected well
        */

        if (data.length === 1) {
            dispatch(wellsAndRigsActions.handleSelectedRowId(data[0].id));
            // we only apply these changes of opening/closing the right-side well card
            // if a well is selected (not a rig or permit)
            if (isInstanceOfWellsAndPermitsObject(data[0])) {
                /** We used to always switch to the well card if there was just 1 well selected
                 * but now that we have the toggle button, that's no longer necessary.
                 * We only force switch to the well card tab, if the right side card
                 * wasn't already open.  If it was open to analytics, then user switches
                 * to a new well, then it should stay on analytics
                 */
                if (!state.wellsAndRigs.viewAnalytics) {
                    dispatch(wellsAndRigsActions.handleAnalyticsTabIndex(1));
                }
                dispatch(toggleViewAnalytics(true));
                dispatch(toggleLeftTab(false));
                // If there is a new selected wells then we need to clear the production data
                dispatch(
                    handleAnalyticsData({
                        prodLiq: [],
                        prodGas: [],
                        gasAggDate: [],
                        gasAggMonth: [],
                        liqAggDate: [],
                        liqAggMonth: [],
                        singleWellProd: [],
                        singleWellLeaseProd: [],
                        leaseIDs: [],
                        hasWellProd: false,
                        hasLeaseProd: false,
                        allocated: false,
                    })
                );
                dispatch(
                    handleAnalyticsProdFilters({
                        apiList: [],
                        apiListObj: [],
                        apiListObjLength: 0,
                    })
                );
                dispatch(wellsAndRigsActions.handleProductionTabIndex(0));
            }

            dispatch(wellsAndRigsActions.setProductionDataLoading(true));
        } else if (data.length === 0) {
            // If there is not any selected objects, we need to close the sidebar
            dispatch(toggleViewAnalytics(false));
            dispatch(wellsAndRigsActions.handleSelectedRowId(0));
        } else if (state.wellsAndRigs.analyticsTabIndex === 1) {
            // close the right well sidecard if there is more than 1 well selected (and not on the analytics tab)
            dispatch(toggleViewAnalytics(false));
        }
        dispatch(wellsAndRigsActions.handleSelectedWellRigOrPermitList(data));
    };
};

// get analytics data
export const getAnalyticsDonutData = (
    token: string,
    formData: { api_id: string[]; type?: typeof OPERATOR }
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-search/production-count",
                formData,
                config
            );
            const { status, msg, data: resData } = res.data;
            if (status === 200) {
                if (formData.type) {
                    let tempObj: { [x: string]: number } = {};
                    resData.forEach(
                        (obj: { operator_name: string; total: number }) => {
                            const { operator_name, total } = obj;
                            Object.assign(tempObj, { [operator_name]: total });
                        }
                    );
                    dispatch(
                        handleOperatorPieChart({
                            data: tempObj,
                        })
                    );
                } else {
                    dispatch(
                        handleDonutChart({
                            data: Array.isArray(resData)
                                ? resData.map((item) => ({
                                    label: item.production_type,
                                    value: item.total,
                                }))
                                : [],
                        })
                    );
                }
            } else {
                toast.error(msg);
            }

            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
        }
    };
};

export const getMapData = (
    token: string,
    abort: AbortController,
    formData: {
        tile: number;
        spatialReference: { wkid: number };
        xmin: number;
        xmax: number;
        ymin: number;
        ymax: number;
        geometry: string;
    }
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await Promise.all([
                axios.post("/api-search/map-query", formData, {
                    ...config,
                    signal: abort.signal,
                }),
                axios.post("/api-search/get-line-geometry", formData, {
                    ...config,
                    signal: abort.signal,
                }),
            ]);

            const { status, msg, data: mapData } = res[0].data;
            const { statusLine, msgLine, data: mapLineData } = res[1].data;
            if (status === 200) {
                dispatch(
                    handleUpdatedMapData({
                        data: mapData,
                        lineData: mapLineData,
                        tile: formData.tile,
                    })
                );
            } else {
                toast.error(msg);
            }

            return res[0].data;
        } catch (err) {
            //errToast(err as AxiosError);
        }
    };
};

// get analytics data
export const getMapLineData = (
    token: string,
    formData: { geometry: string; tile: number }
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                "/api-search/get-line-geometry",
                formData,
                config
            );
            const { status, msg, data: mapData } = res.data;
            if (status === 200) {
                dispatch(
                    handleUpdatedMapLineData({
                        data: mapData,
                        tile: formData.tile,
                    })
                );
            } else {
                toast.error(msg);
            }

            return res.data;
        } catch (err) {
            //errToast(err as AxiosError);
        }
    };
};

//handle donut chart
export const handleDonutChart = (data: {
    data: WellsRigsModel["donutChart"]["dataList"];
    dataLoading?: WellsRigsModel["donutChart"]["dataLoading"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleDonutChart(data));
    };
};

//handle map updates
export const handleUpdatedMapData = (data: {
    data: WellsAndPermitsObject[];
    lineData: WellLineObject[];
    tile: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleUpdatedMapData(data));
    };
};

//handle map line updates
export const handleUpdatedMapLineData = (data: {
    data: WellLineObject[];
    tile: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleUpdatedMapLineData(data));
    };
};

//handle operator pie chart
export const handleOperatorPieChart = (data: {
    data: WellsRigsModel["operatorPieChart"]["operatorPieChartDataList"];
    operatorPieChartDataLoading?: WellsRigsModel["operatorPieChart"]["operatorPieChartDataLoading"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleOperatorPieChart(data));
    };
};

//handle Analytics tab index
export const handleAnalyticsTabIndex = (
    val: WellsRigsModel["analyticsTabIndex"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleAnalyticsTabIndex(val));
    };
};

//handle production tab index
export const handleProductionTabIndex = (
    val: WellsRigsModel["productionTabIndex"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleProductionTabIndex(val));
    };
};
//handle Analytics production tab filter
export const handleAnalyticsProdFilters = (val: {
    type?: WellsRigsModel["analyticsData"]["type"];
    xAxisFilterVal?: WellsRigsModel["analyticsData"]["xAxisFilter"];
    action?: WellsRigsModel["analyticsData"]["action"];
    apiList?: WellsRigsModel["analyticsData"]["apiList"];
    apiListObj?: WellsRigsModel["analyticsData"]["apiListObj"];
    apiListObjLength?: WellsRigsModel["analyticsData"]["apiListObjLength"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleAnalyticsProdFilters(val));
    };
};

//handle normalized checkbox
export const handleNormalize = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleNormalize(val));
    };
};

//handle forecast screen opening
export const handleForecast = (
    val: WellsRigsModel["openForeCast"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleForecast(val));
    };
};
// handle resize
export const handleResizableWidth = (
    resizableWidth: WellsRigsModel["resizableWidth"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleResizableWidth(resizableWidth));
    };
};

// get forecasting data
export const forecastingData = (
    token: string,
    formData: ForecastFilterObj & {
        data: ForecastingFormObj[];
        sample_data: ForecastingFormObj[];
    },
    typeCurve = false
): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        const {
            wellsAndRigs: {
                analyticsData: { apiListObj },
                tabIndex,
            },
        } = getState();
        await tokenIsValid(token);
        try {
            const res = await axios.post(
                typeCurve ? "/api-search/type-curves" : "/api-search/forecast",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            const {
                status,
                msg,
                data,
                eur,
                qi,
                b,
                ai,
                tlim,
                start_date_select,
                peakmo,
            } = res.data;
            if (status === 200 && data) {
                let tempString = "";
                apiListObj.forEach((_i) => {
                    tempString += jsonToString({
                        api:
                            tabIndex === 1
                                ? (_i as RigsDataObj)["api"]
                                : (_i as WellsAndPermitsObject)["well_api"],
                        well_name: _i["well_name"],
                        county: _i["county"],
                        state: _i["state_abbr"],
                        operator: _i["operator_name"],
                    });
                });
                dispatch(
                    logUserAction({
                        action_type: typeCurve
                            ? actionType["run_type_curve"]
                            : actionType["run_forecast"],
                        action_log_detail: tempString,
                    })
                );
                type obj = {
                    production_date: string;
                    api: string;
                    production_quantity: string;
                    DCA: number;
                    product_stream: number;
                    data_type: string;
                };
                type typeCurveObj = {
                    producing_month: number;
                    production_quantity: number;
                    production_quantity_ft: string;
                    DCA: number;
                };
                let tempForecastValues: {
                    production_date?: string;
                    production_qty: number;
                    production_month?: number;
                    highlight: boolean;
                }[] = [];

                let temp_data = data.map(
                    (item: obj | typeCurveObj, index: number) => {
                        if (item.DCA) {
                            tempForecastValues.push({
                                ...(!typeCurve && {
                                    production_date: (item as obj)
                                        .production_date,
                                }),
                                production_qty: item.DCA,
                                ...(!typeCurve && {
                                    production_month: index + 1,
                                }),
                                ...(typeCurve && {
                                    production_month: (item as typeCurveObj)
                                        .producing_month,
                                }),
                                highlight: true,
                            });
                        }
                        return {
                            ...item,
                            ...(item.production_quantity && {
                                production_quantity: Number(
                                    item.production_quantity
                                ),
                            }),
                            ...(item.DCA && { DCA: Number(item.DCA) }),
                            ...((item as obj).product_stream && {
                                product_stream: Number(
                                    (item as obj).product_stream
                                ),
                            }),
                            ...((item as typeCurveObj)
                                .production_quantity_ft && {
                                production_quantity_ft: Number(
                                    (item as typeCurveObj)
                                        .production_quantity_ft
                                ),
                            }),
                        };
                    }
                );

                tempForecastValues.length &&
                    dispatch(
                        handleForecastingData({
                            data: [
                                {
                                    name: typeCurve ? "Type Curve" : "Forecast",
                                    values: tempForecastValues,
                                },
                            ],
                            forecastingCompleteDataFrame:
                                JSON.stringify(temp_data),
                            eur: Math.trunc(Number(eur)).toString(),
                            ...(qi !== 0 && { qi }),
                            ...(b !== 0 && { b }),
                            // ...(ai !== 0 && { ai: Math.trunc(ai) }),
                            ...(ai !== 0 && { ai }),
                            ...(tlim !== 0 && {
                                tlim: Math.ceil(Number(tlim)).toString(),
                            }),
                            ...(start_date_select && { start_date_select }),
                            ...(peakmo !== 0 && typeCurve && { peakmo }),
                        })
                    );
            } else {
                toast.error(msg);
            }
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

// handle forecasting data
export const handleForecastingData = (val: {
    data: WellsRigsModel["analyticsData"]["forecastingData"]["dataList"];
    dataLoading?: WellsRigsModel["analyticsData"]["forecastingData"]["dataLoading"];
    forecastingCompleteDataFrame?: WellsRigsModel["analyticsData"]["forecastingData"]["forecastingCompleteDataFrame"];
    eur?: WellsRigsModel["analyticsData"]["forecastingData"]["eur"];
    ai?: WellsRigsModel["analyticsData"]["forecastingData"]["ai"];
    b?: WellsRigsModel["analyticsData"]["forecastingData"]["b"];
    qi?: WellsRigsModel["analyticsData"]["forecastingData"]["qi"];
    tlim?: WellsRigsModel["analyticsData"]["forecastingData"]["tlim"];
    start_date_select?: WellsRigsModel["analyticsData"]["forecastingData"]["start_date_select"];
    peakmo?: WellsRigsModel["analyticsData"]["forecastingData"]["peakmo"];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleForecastingData(val));
    };
};

// handle selected forecast point data
export const handleSelectedForecastPoints = (val: {
    data: WellsRigsModel["analyticsData"]["selectedForecastPoints"];
    doNotConCat?: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleSelectedForecastPoints(val));
    };
};

//handle rearrange of table row
export const handleRearrange = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleRearrange());
    };
};

const updateTableSettingData = (
    data: ColPayload[],
    rigsTableCol: tableColObje[],
    tableCol: tableColObje[],
    tableColName: string,
    dispatch: Function,
    onDelete?: undefined | boolean
) => {
    // let tempRigsCols: tableColObje[] = onDelete ? [] : [...rigsTableCol];
    // let tempTableCol: tableColObje[] = onDelete ? [] : [...tableCol];
    let tempRigsCols: tableColObje[] = [];
    let tempTableCol: tableColObje[] = [];
    let tableColNamesWell: string[] = [];
    let tableColNamesRig: string[] = [];
    /* The goal here is to set the full list of all table columns into tableColFullList
    which would include the default and named lists.  Then tableCol and rigsTableCol
    have just the selected list. 
    One wrinkle is that we may add new columns in the future, so if a column is missing from a 
    saved, list we add it to the end and assume it's unselected */

    data.forEach((_item: ColPayload) => {
        let currentItem = (_item.tabIndex === 1 ? rigsTableCol : tableCol).find(
            (obj) =>
                obj.label === _item.column_key &&
                obj.tableColName === tableColName
        );

        if (_item.tabIndex === 1) {
            if (
                _item.saved_filter_name &&
                !tableColNamesRig.includes(_item.saved_filter_name)
            ) {
                tableColNamesRig.push(_item.saved_filter_name);
            }
            tempRigsCols.push({
                ...(currentItem as tableColObje),
                status: _item.is_visible,
                tableColName: _item.saved_filter_name,
                id: _item.id?.toString(),
                header: _item.header,
                label: _item.column_key,
                tabIndex: _item.tabIndex,
            });
        } else {
            if (
                _item.saved_filter_name &&
                !tableColNamesWell.includes(_item.saved_filter_name)
            ) {
                tableColNamesWell.push(_item.saved_filter_name);
            }
            tempTableCol.push({
                ...(currentItem as tableColObje),
                status: _item.is_visible,
                tableColName: _item.saved_filter_name,
                id: _item.id?.toString(),
                header: _item.header,
                label: _item.column_key,
                tabIndex: _item.tabIndex,
            });
        }
    });

    // Here we check to make sure that new columns have not been added since the last save
    const addMissingItems = (
        originalList: tableColObje[],
        tableName: string,
        initialData: tableColObje[]
    ) => {
        initialData.forEach((item) => {
            // Check if the current item from initialData exists in the original list
            const exists = originalList
                .filter(
                    (colItem: tableColObje) =>
                        colItem.tableColName === tableName
                )
                .find((originalItem) => originalItem.label === item.label);
            if (item.label === "spud_date") {
            }
            // If the item does not exist in the original list, add it
            if (!exists) {
                originalList.push({
                    ...item,
                    tableColName: tableName,
                    status: false,
                });
            }
        });
    };
    tableColNamesWell.forEach((wellTableName: string) => {
        if (
            tempTableCol.filter(
                (colItem: tableColObje) =>
                    colItem.tableColName === wellTableName
            ).length !== wellColumnInitialData.length
        ) {
            addMissingItems(tempTableCol, wellTableName, wellColumnInitialData);
        }
    });
    tableColNamesRig.forEach((rigTableName: string) => {
        if (
            tempRigsCols.filter(
                (colItem: tableColObje) => colItem.tableColName === rigTableName
            ).length !== rigColumnInitialState.length
        ) {
            addMissingItems(tempRigsCols, rigTableName, rigColumnInitialState);
        }
    });
    if (tempRigsCols.length || onDelete) {
        dispatch(updateWellsOrRigsTableCol(tempRigsCols, 1));
    }
    if (tempTableCol.length || onDelete) {
        dispatch(updateWellsOrRigsTableCol(tempTableCol, 0));
    }
};
// Fetch column properties
export const fetchColumnProperties = (): ThunkAction<
    Promise<void>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        const {
            auth: {
                user: { access_token },
            },
            wellsAndRigs: { rigsTableCol, tableCol, tableColName },
        } = getState();

        await tokenIsValid(access_token);

        axios
            .get("/api-user/search-settings", config)
            .then((res) => {
                const { status, msg, data } = res.data;
                if (status === 200) {
                    if (data) {
                        updateTableSettingData(
                            data,
                            rigsTableCol,
                            tableCol,
                            tableColName,
                            dispatch
                        );
                    }
                    // toast.success(msg);
                } else {
                    toast.error(msg);
                }
            })
            .catch((err) => {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            });
    };
};

//update the filter name
export const updateColumnPropertiesName = (formData: {
    saved_filter_name: string;
    id: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(
            handleSavedFilterColumnProperties({
                saveFiltersDataLoading: true,
            })
        );
        const {
            auth: {
                user: { access_token },
            },
            wellsAndRigs: { rigsTableCol, tableCol, tabIndex },
        } = getState();
        await tokenIsValid(access_token);
        try {
            const res = await axios.put(
                "/api-user/search-settings",
                formData,
                config
            );
            const { status, msg, data, saved_filter_name } = res.data;
            if (status === 200) {
                dispatch(updateTableColName(saved_filter_name || ""));
                updateTableSettingData(
                    data,
                    rigsTableCol,
                    tableCol,
                    saved_filter_name,
                    dispatch
                );

                dispatch(
                    handleSavedFilterColumnProperties({
                        saveFiltersDataLoading: false,
                        filterID: "-1",
                    })
                );
            } else {
                toast.error(msg);
                dispatch(
                    handleSavedFilterColumnProperties({
                        saveFiltersDataLoading: false,
                        filterID: "-1",
                    })
                );
            }
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
            dispatch(
                handleSavedFilterColumnProperties({
                    saveFiltersDataLoading: false,
                })
            );
        }
    };
};

//delete  saved search filters
export const deleteSavedColumnProperties = (formData: {
    id: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(
            handleSavedFilterColumnProperties({
                saveFiltersDataLoading: true,
            })
        );
        const {
            auth: {
                user: { access_token },
            },
            wellsAndRigs: { rigsTableCol, tableCol, tableColName },
        } = getState();
        await tokenIsValid(access_token);
        const config = {
            data: {
                ...formData,
            },
            headers: { "Content-Type": "application/json" },
        };
        try {
            const res = await axios.delete("/api-user/search-settings", config);
            const { status, msg, data } = res.data;
            if (status === 200) {
                updateTableSettingData(
                    data,
                    rigsTableCol,
                    tableCol,
                    tableColName,
                    dispatch,
                    true
                );
            } else {
                toast.error(msg);
            }
            dispatch(
                handleSavedFilterColumnProperties({
                    saveFiltersDataLoading: false,
                    filterID: "-1",
                })
            );
        } catch (err) {
            dispatch(hideSiteLoader());
            errToast(err as AxiosError);
            dispatch(
                handleSavedFilterColumnProperties({
                    saveFiltersDataLoading: false,
                    filterID: "-1",
                })
            );
        }
    };
};

// set column properties
export const setColumnProperties = (
    savedFilterName: string | null,
    setLoading?: boolean | undefined
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const {
            auth: {
                user: { access_token },
            },
            wellsAndRigs: { tabIndex, rigsTableCol, tableCol, tableColName },
        } = getState();

        await tokenIsValid(access_token);
        if (setLoading) {
            dispatch(
                handleSavedFilterColumnProperties({
                    saveFiltersDataLoading: true,
                })
            );
        }

        // we only want to send the selected table columns to update on the backend
        axios
            .post(
                "/api-user/search-settings",
                {
                    tab: tabIndex,
                    data:
                        tabIndex === 0
                            ? tableCol
                                .filter(
                                    (_item: tableColObje) =>
                                        _item.tableColName == tableColName ||
                                        (tableColName === "default" &&
                                            typeof _item.tableColName ===
                                            "undefined")
                                )
                                .map((_item) => ({
                                    label: _item.label,
                                    status: _item.status,
                                    header: _item.header,
                                    tabIndex: _item.tabIndex,
                                }))
                            : rigsTableCol
                                .filter(
                                    (_item: tableColObje) =>
                                        _item.tableColName == tableColName ||
                                        (tableColName === "default" &&
                                            typeof _item.tableColName ===
                                            "undefined")
                                )
                                .map((_item) => ({
                                    label: _item.label,
                                    status: _item.status,
                                    header: _item.header,
                                    tabIndex: _item.tabIndex,
                                })),
                    saved_filter_name: savedFilterName,
                },
                config
            )
            .then((res) => {
                const { status, msg, data, saved_filter_name } = res.data;
                if (status === 200) {
                    dispatch(updateTableColName(saved_filter_name || ""));
                    updateTableSettingData(
                        data,
                        rigsTableCol,
                        tableCol,
                        saved_filter_name,
                        dispatch
                    );
                } else {
                    toast.error(msg);
                }
            })
            .catch((err) => {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            })
            .finally(() => {
                if (setLoading) {
                    dispatch(
                        handleSavedFilterColumnProperties({
                            saveFiltersDataLoading: false,
                            filterID: "-1",
                        })
                    );
                }
            });
    };
};

// handle resize
export const handleResizableHeight = (
    resizableHeight: WellsRigsModel["resizableHeight"]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleResizableHeight(resizableHeight));
    };
};

//handle uid
export const handleUIDList = (val: {
    selectedWellRigOrPermitUIDList: WellsRigsModel["selectedWellRigOrPermitUIDList"];
    reset?: boolean;
    data?: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[];
    doNotRemoveRow?: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(
            wellsAndRigsActions.handleSelectedWellRigOrPermitUIDList(
                val.selectedWellRigOrPermitUIDList
            )
        );
        dispatch(wellsAndRigsActions.handleUIDList(val));
    };
};

export const handleSetProductionDataLoading = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setProductionDataLoading(val));
    };
};

export const getSingleWellData = (
    wellAPI: string,
    moveWellToFront: Boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        try {
            dispatch(showSiteLoader());
            const state = getState();

            await tokenIsValid(state.auth.user.access_token);
            const res = await axios.get(
                `/api-aoi/get-single-well-data/${wellAPI}`,
                config
            );
            if (res.data.msg !== "success") {
                dispatch(setRapidAPIMessage(res.data.msg));
                return;
            }
            const well_data_res = res.data.data;
            const well_data = { ...well_data_res, id: well_data_res.uid };
            //Update the request for filtering the single rapid api id.
            const tmpPanelFilter = {
                single_well_uid: well_data_res.uid,
                single_well_api: well_data_res.well_api,
            };
            const newPanelFilter = state?.filters?.panelFilters
                ? { ...state.filters.panelFilters, ...tmpPanelFilter }
                : tmpPanelFilter;

            let oldWellAPIs = [];
            let newWellsDataList: WellsAndPermitsObject[] = [well_data];
            /* We want to move the well to the front of the list */
            if (
                state?.wellsAndRigs?.wellsData?.data &&
                state?.wellsAndRigs?.wellsData?.data.length > 0
            ) {
                oldWellAPIs = state?.wellsAndRigs?.wellsData?.data.map(
                    (well: WellsAndPermitsObject) => well.well_api
                );
                if (!oldWellAPIs.includes(well_data.well_api)) {
                    newWellsDataList = [
                        well_data,
                        ...state?.wellsAndRigs?.wellsData?.data,
                    ];
                } else if (moveWellToFront) {
                    newWellsDataList = [
                        well_data,
                        ...state?.wellsAndRigs?.wellsData?.data.filter(
                            (well: WellsAndPermitsObject) =>
                                well.id !== well_data.id
                        ),
                    ];
                } else {
                    newWellsDataList = [
                        ...state?.wellsAndRigs?.wellsData?.data,
                    ];
                }
            }
            dispatch(wellsAndRigsActions.updateWellsDataList(newWellsDataList));
            dispatch(
                handleSelectedWellRigOrPermitList(
                    newWellsDataList.filter(
                        (well: WellsAndPermitsObject) =>
                            well.well_api === wellAPI
                    )
                )
            );
            dispatch(setRapidAPIMessage(res.data.msg));

            dispatch(SetPanelFilter(newPanelFilter));
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(setRapidAPIMessage(""));
        } finally {
            dispatch(hideSiteLoader());
        }
    };
};

export const clearSelectedWellsRigs = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.handleSelectedRowId(0));
        dispatch(handleSelectedWellRigOrPermitList([]));
        dispatch(toggleViewAnalytics(false));
    };
};

export const setGraphDataLoading = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        if (val) {
            dispatch(
                handleAnalyticsData({
                    prodLiq: [],
                    prodGas: [],
                    gasAggDate: [],
                    gasAggMonth: [],
                    liqAggDate: [],
                    liqAggMonth: [],
                    singleWellProd: [],
                    singleWellLeaseProd: [],
                    leaseIDs: [],
                    hasWellProd: false,
                    hasLeaseProd: false,
                    allocated: false,
                })
            );
        }
        dispatch(wellsAndRigsActions.setGraphDataLoading(val));
    };
};

export const setRapidAPIMessage = (
    val: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setRapidAPIMessage(val));
    };
};

export const setFullScreenAnalyticsGraphTitle = (
    val: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setFullScreenAnalyticsGraphTitle(val));
    };
};

export const setRapidAPIFromInput = (
    val: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setRapidAPIFromInput(val));
    };
};
export const setRapidAPIOnBlur = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setRapidAPIOnBlur(val));
    };
};
export const setDontUpdateSelectedWellsRigs = (
    val: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.setDontUpdateSelectedWellsRigs(val));
    };
};
export const updateRigsDataList = (
    val: RigsDataObj[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(wellsAndRigsActions.updateRigsDataList(val));
    };
};
