import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import subscriptionSettingsSlice from "../reducers/subscription-settings-slice";
import { hideSiteLoader, showSiteLoader } from "./modal-actions";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import {
    CancelSubscriptionReturnType,
    ReturnMsgAndStatus
} from "../../models/submit-form";
import {
    actionType,
    config,
    errToast,
    tokenIsValid,
} from "../../../utils/helper";
import { AxiosError } from "axios";
import { UpdateSubscriptionEditModalFormData } from "../../models/redux-models";
import { logUserAction } from "./auth-actions";
import { handleYearlyAndMonthlyTab } from "./cart-select-basin-county-actions";

export const subscriptionSettingsActions = subscriptionSettingsSlice.actions;

// fetchSubscriptionData
export const fetchSubscriptionData =
    (
        token: string,
        doNotHideLoader?: boolean
    ): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch, getState) => {
            dispatch(showSiteLoader());
            await tokenIsValid(token);
            try {
                let response = await axios.get(
                    "/api-subscriptions/get-details",
                    config
                );
                let { data, status, msg } = response.data;

                if (status === 200) {
                    dispatch(
                        subscriptionSettingsActions.handleGoNation(data.go_nation)
                    );
                    // if (Object.keys(data).length > 0) {
                    if (
                        Object.keys(data).includes("subscription") &&
                        Object.keys(data).includes("details")
                    ) {
                        data.subscription.billing_frequency_display &&
                            dispatch(
                                handleYearlyAndMonthlyTab(
                                    data.subscription.billing_frequency_display ===
                                        "-None-" ||
                                        data.subscription
                                            .billing_frequency_display === "Yearly"
                                        ? true
                                        : false
                                )
                            );
                        dispatch(
                            subscriptionSettingsActions.fetchSubscriptionData({
                                subscription: {
                                    ...data.subscription,
                                    ...(!data.subscription?.allowed_user && {
                                        allowed_user: 1,
                                    }),
                                    ...("is_upgrade_subscription" in
                                        data?.company_data && {
                                        is_upgrade_subscription:
                                            data.company_data
                                                .is_upgrade_subscription,
                                    }),
                                    ...("is_trial_never_end" in
                                        data?.company_data && {
                                        is_trial_never_end:
                                            data.company_data.is_trial_never_end,
                                    }),
                                    recent_unpaid_invoice_id: data.recent_unpaid_invoice_id,
                                },
                                details: Array.isArray(data.details)
                                    ? [...data.details]
                                    : [data.details],

                            })
                        );
                    } else if (
                        Object.keys(data).includes("recent_unpaid_invoice_id")
                    ) {
                        dispatch(subscriptionSettingsActions.setRecentUnpaidInvoiceId(data.recent_unpaid_invoice_id))
                        dispatch(subscriptionSettingsActions.noSubscriptionData());
                    } else {
                        dispatch(subscriptionSettingsActions.noSubscriptionData());
                    }
                } else {
                    toast.error(msg);
                }

                !doNotHideLoader && dispatch(hideSiteLoader());
            } catch (err) {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            }
        };

// cancelSubscriptionData
export const cancelSubscription =
    (
        token: string
    ): ThunkAction<
        Promise<CancelSubscriptionReturnType>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch, getState) => {
            dispatch(showSiteLoader());
            await tokenIsValid(token);
            const { subscriptionSettings } = getState();
            try {
                let response = await axios.get(
                    "api-subscriptions/cancel-subscription",
                    config
                );
                const { status } = response;
                //log user action
                if (status === 200) {
                    dispatch(
                        logUserAction({
                            action_type: actionType["cancelled_subscription"],
                            action_log_detail: `subscription_id: ${subscriptionSettings.subscriptionData?.subscription.id}`,
                        })
                    );
                }
                dispatch(hideSiteLoader());
                return response.data;
            } catch (err) {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            }
        };

// clearSubscriptionData
export const clearSubscriptionData =
    (): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(subscriptionSettingsActions.clearSubscriptionData());
        };

// updateSubscription
export const updateSubscription =
    (
        token: string,
        formData: UpdateSubscriptionEditModalFormData
    ): ThunkAction<
        Promise<ReturnMsgAndStatus>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch) => {
            dispatch(showSiteLoader());
            await tokenIsValid(token);
            const config = {
                data: {
                    ...formData,
                },
                headers: { "Content-Type": "application/json" },
            };
            try {
                let response = await axios.delete(
                    "/api-subscriptions/cancel-subscription",
                    config
                );

                dispatch(hideSiteLoader());
                return response.data;
            } catch (err) {
                errToast(err as AxiosError);
                dispatch(hideSiteLoader());
            }
        };

// payment modal during trial period
export const handlePaymentModalDuringTrial =
    (val: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(
                subscriptionSettingsActions.handlePaymentModalDuringTrial(val)
            );
        };

//Free trial for n days subscription
export const freeTrialSubscription = (formData: {
    amount: number;
    seat_count: number;
}): ThunkAction<Promise<ReturnMsgAndStatus>, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        const {
            auth: {
                user: { access_token },
            },
        } = getState();
        await tokenIsValid(access_token);
        try {
            const res = await axios.post(
                "/api-subscriptions/subscribe-free",
                formData,
                config
            );
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

//End Free Trial
export const endFreeTrialSubscription = (): ThunkAction<
    Promise<ReturnMsgAndStatus>,
    RootState,
    unknown,
    AnyAction
> => {
    return async (dispatch, getState) => {
        dispatch(showSiteLoader());
        const {
            auth: {
                user: { access_token },
            },
        } = getState();
        await tokenIsValid(access_token);

        try {
            const res = await axios.get("/api-subscriptions/end-trial", config);
            dispatch(hideSiteLoader());
            return res.data;
        } catch (err) {
            errToast(err as AxiosError);
            dispatch(hideSiteLoader());
        }
    };
};

// upgrade subscription
export const handleUpgradeSubscription =
    (val: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
        async (dispatch) => {
            dispatch(subscriptionSettingsActions.handleUpgradeSubscription(val));
        };



// save and update tax exempt id
export const saveAndUpdateTaxExemptID =
    (
        val: string,
        showLoader?: boolean
    ): ThunkAction<
        Promise<ReturnMsgAndStatus>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch, getState) => {
            showLoader && dispatch(showSiteLoader());
            const {
                auth: {
                    user: { access_token },
                },
            } = getState();
            await tokenIsValid(access_token);

            try {
                const res = await axios.post(
                    "/api-user/manage-sales-tax",
                    { tax_id: val },
                    config
                );
                const { status, msg } = res.data;
                if (status !== 200) {
                    toast.error(msg);
                }
                showLoader && dispatch(hideSiteLoader());
                return res.data;
            } catch (err) {
                showLoader && dispatch(hideSiteLoader());
                errToast(err as AxiosError);
            }
        };



// save and update tax exempt id
export const getStripeInvoiceStatus =
    (
        stripe_invoice_id: string,
    ): ThunkAction<
        Promise<{ hasInvoice: boolean, invoiceError: string, invoiceId: string }>,
        RootState,
        unknown,
        AnyAction
    > =>
        async (dispatch, getState) => {
            const {
                auth: {
                    user: { access_token },
                },
            } = getState();
            await tokenIsValid(access_token);

            try {
                const res = await axios.get(
                    `/api-subscriptions/get-stripe-invoice-status/${stripe_invoice_id}/`,
                    config
                );
                const { status, msg, has_invoice, invoice_error, invoice_id } = res.data;
                if (status !== 200) {
                    toast.error(msg);
                }
                return { hasInvoice: has_invoice, invoiceError: invoice_error, invoiceId: invoice_id };
            } catch (err) {
                errToast(err as AxiosError);
                return { hasInvoice: false, invoiceError: err as string, invoiceId: '' }
            }
        };

export const setRecentUnpaidInvoiceId = (invoiceId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(subscriptionSettingsActions.setRecentUnpaidInvoiceId(invoiceId));
    }
}