import React from "react";
import {
    ApiListTableFormData,
    OptionType,
    PotentialWellMatchOption,
    WellMatchingOptionType,
    csvApiDataObj,
} from "../models/submit-form";
import AsyncSelect from "../common/AsyncSelect";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { GroupBase, OptionProps, OptionsOrGroups, components } from "react-select";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { fetchSuggestionWellName } from "../store/actions/cart-select-basin-county-actions";
import { setCsvApiFileData } from "../store/actions/cart-basin-to-filter-actions";
// import { showCheckOutModal } from "../store/actions/modal-actions";
// import { addAllBasinOrCountyToCart } from "../store/actions/cart-basin-to-county-actions";
import { AxiosError } from "axios"
import { errToast } from "../../utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { reactSelectProps } from "../models/page-props";

const HtmlCheckBox = (
    props: OptionProps<reactSelectProps, boolean, GroupBase<reactSelectProps>>
) => {
    return <components.Option {...props}>

        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip" className="custom-tooltip dark-tooltip">
                {props.label}
            </Tooltip>}
        >
            <span>
                {props.label}
            </span>
        </OverlayTrigger>
    </components.Option>;
};

const CartBasinApiCols = (
    control: Control<ApiListTableFormData, any>,
    errors: FieldErrors<ApiListTableFormData>,
    setValue: UseFormSetValue<ApiListTableFormData>,
) => {
    const dispatch = useAppDispatch();

    const csvApiFileData = useAppSelector((state) => state.cartBasinToFilterSlice.csvApiFileData);
    const access_token = useAppSelector((state) => state.auth.user.access_token);

    const loadOptions = async (
        search: string,
        prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
        { page }: any,
        csvDataId: string | number,
    ) => {
        let hasMore = false,
            dataList: WellMatchingOptionType[] = [];
        // const dataItem = csvApiFileData.filter((item: csvApiDataObj) => item.id === csvDataId)
        // if (!search && dataItem.length > 0 && dataItem[0].potentialMatches && dataItem[0].potentialMatches.length > 0) {
        //     dataList = dataItem[0].potentialMatches.map((_item: PotentialWellMatchOption) => {
        //         return {
        //             label: `API: ${_item.api} - ${_item.well_name}`,
        //             value: _item.api,
        //         }
        //     })
        // }
        if (search.length >= 2) {
            try {
                const res = await dispatch(
                    fetchSuggestionWellName(access_token, { wellName: search })
                );
                if (res && res.status === 200) {
                    const { data } = res;
                    dataList = data.map((_item) => ({
                        label: `API: ${_item.api} - ${_item.well_name}`,
                        value: _item.api,
                        basin_name: _item.basin_name,
                        state_abbr: _item.state_abbr,
                        county: _item.county
                    }));
                }
            } catch (err) {
                (err as AxiosError)["code"] !== "ERR_CANCELED" && errToast(err as AxiosError);
            }


        }
        return {
            options: dataList,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    };
    const getMatchPercentage = (dataObj: csvApiDataObj) => {
        if (!dataObj.match) {
            return "-"
        }
        if (dataObj.potentialMatches && dataObj.potentialMatches.length > 0) {
            const wellInOptions = dataObj.potentialMatches.filter((potentialMatch: PotentialWellMatchOption) => potentialMatch.api === dataObj.api)
            if (wellInOptions.length > 0) {
                return wellInOptions[0].match_percentage
            }
        }
        return "100"
    }
    return [
        {
            title: "#",
            thId: "id_col",
            tdClassName: "id_col",
            render: (props: csvApiDataObj, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "API",
            thId: "api_col_head",
            tdClassName: "api_col",
            render: (props: csvApiDataObj) => {
                return <span>{props.api === "na" ? "-" : props.api}</span>;
            },
        },
        {
            title: "Well Name",
            thId: "api_col_head",
            tdClassName: "maxWidthWellName",
            render: (props: csvApiDataObj) => {
                return (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className="custom-tooltip">
                            {props.well_name ? props.well_name : "-"}
                        </Tooltip>}
                    >
                        <span>{props.well_name ? props.well_name : "-"}</span>
                    </OverlayTrigger>

                )
            },
        },
        {
            title: "COUNTY",
            thId: "county_col_head",
            tdClassName: "county_col",
            render: (props: csvApiDataObj) => {
                return <span>{props.county}</span>;
            },
        },
        {
            title: "STATE",
            thId: "state_col_head",
            tdClassName: "state_col",
            render: (props: csvApiDataObj) => {
                return <span>{props.state}</span>;
            },
        },
        {
            title: "STATUS",
            thId: "status_col_head",
            tdClassName: "status_col",
            render: (props: csvApiDataObj) => {
                return (
                    <span
                        className={
                            props.in_plan === false ?
                                "unsubscribed" :
                                props.match === false
                                    ? "unmatched"
                                    :
                                    "matched"
                        }
                    >
                        <i className="fa-solid fa-circle-exclamation"></i>{" "}
                        {props.in_plan === false ?
                            "not in plan" : props.match === false
                                ? "Unmatched"
                                : "Matched"}
                    </span>
                );
            },
        },
        {
            title: "MATCH %",
            thId: "status_col_head",
            tdClassName: "status_col",
            render: (props: csvApiDataObj) => {
                return (
                    // <span>{getMatchPercentage(props)}</span>
                    <span>{Number(props.match_score).toFixed(2)}%</span>
                );
            },
        },
        {
            title: "WELL MATCHING",
            thId: "well_matching_col_head",
            tdClassName: "well_matching_col",
            render: (props: csvApiDataObj, index: number) => {
                return (
                    <div className="wellmatching" key={props.id}>
                        {((props.match && props.in_plan) || (!props.match && props.in_plan === "")) ? (
                            <React.Fragment key={props.id}>
                                <AsyncSelect
                                    name={`wellMatching[${index}].name`}
                                    key={props.id}
                                    debounceTimeout={500}
                                    placeholder="Choose a well"
                                    control={control}
                                    isMulti={false}
                                    className={
                                        props.match
                                            ? "active"
                                            : ""
                                    }
                                    errorMsg={
                                        Array.isArray(errors.wellMatching)
                                            ? errors.wellMatching[index]?.[
                                            "name"
                                            ]?.["message"]
                                            : ""
                                    }
                                    value={props.wellMatching}
                                    additional={{
                                        page: 1,
                                    }}
                                    onChange={(val: WellMatchingOptionType | null) => {
                                        setValue(
                                            `wellMatching.${index}.name`,
                                            val
                                        );
                                        let newWellValue = ""

                                        let splitWellName: string[] = []

                                        if (val && val.label) {
                                            splitWellName = val.label.toString().split(" - ")
                                        }
                                        if (splitWellName.length > 1) {

                                            newWellValue = [splitWellName.slice(1).join(", ")].join(" ")
                                        }
                                        const newData = csvApiFileData.map(
                                            (item) =>
                                                props.id === item.id
                                                    ? {
                                                        ...item,
                                                        ...(!val
                                                            && {
                                                            match: false,
                                                            in_plan: "",
                                                            matched_api: "",
                                                            matched_well_name: "",
                                                            wellMatching: null,
                                                            matched_basin_name: '',
                                                            matched_county: '',
                                                            matched_state: '',
                                                            match_type: '',
                                                            match_score: ''
                                                        }),
                                                        ...(val && {
                                                            match: true,
                                                            in_plan: true,
                                                            matched_api: val.value.toString(),
                                                            matched_well_name: newWellValue,
                                                            wellMatching: val,
                                                            matched_basin_name: val.basin_name,
                                                            matched_county: val.county,
                                                            matched_state: val.state_abbr,
                                                            match_type: "user_defined",
                                                            ...(item.match_score && {
                                                                match_score: ''
                                                            })
                                                        }),
                                                    }
                                                    : item
                                        )
                                        dispatch(setCsvApiFileData(newData))
                                    }}
                                    components={{
                                        DropdownIndicator: null,
                                        Option: HtmlCheckBox,
                                    }}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    backspaceRemovesValue={false}
                                    loadOptions={async (search: string,
                                        prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
                                        { page }: any) => await loadOptions(search, prevOptions, page, props.id)}
                                />
                                {/* {props?.wellMatching &&
                                    Object.keys(props.wellMatching).length > 0 && (
                                        <button
                                            type="button"
                                            className="btn btn-del"
                                        >
                                            <i
                                                className="fa-solid fa-trash"
                                                onClick={() => {
                                                    setValue(
                                                        `wellMatching.${index}.name`,
                                                        null
                                                    );
                                                    dispatch(setCsvApiFileData(csvApiFileData.map(
                                                        (item) =>
                                                            props.id ===
                                                                item.id
                                                                ? {
                                                                    ...item,
                                                                    wellMatching:
                                                                        null,
                                                                    match: false,
                                                                }
                                                                : item
                                                    ),))
                                                }}
                                            ></i>
                                        </button>

                                    )} */}
                            </React.Fragment >
                        ) : (
                            <></>
                            // <button
                            //     type="button"
                            //     className={
                            //         !JSON.stringify(cartListItems).includes(
                            //             JSON.stringify(props.county)
                            //         )
                            //             ? "btn btn-addplan"
                            //             : "btn btn-addedplan"
                            //     }
                            //     onClick={() => {
                            //         if (
                            //             !JSON.stringify(cartListItems).includes(
                            //                 JSON.stringify(props.county)
                            //             )
                            //         ) {
                            //             dispatch(
                            //                 addAllBasinOrCountyToCart(
                            //                     access_token,
                            //                     {
                            //                         cat: "county",
                            //                         search: [
                            //                             {
                            //                                 state_abbr:
                            //                                     props.state,
                            //                                 county: props.county,
                            //                             },
                            //                         ],
                            //                     }
                            //                 )
                            //             ).then((res) => {
                            //                 const { status } = res;
                            //                 if (status === 200) {
                            //                     dispatch(showCheckOutModal());
                            //                 }
                            //             });
                            //         } else {
                            //             dispatch(showCheckOutModal());
                            //         }
                            //     }}
                            // >
                            //     {!JSON.stringify(cartListItems).includes(
                            //         JSON.stringify(props.county)
                            //     ) ? (
                            //         "Add to my plan"
                            //     ) : (
                            //         <>
                            //             {" "}
                            //             <span>
                            //                 <i className="fa-solid fa-circle-exclamation"></i>
                            //             </span>
                            //             &nbsp;Added
                            //         </>
                            //     )}
                            // </button>
                        )}
                    </div>
                );
            },
        },
    ];
};

export default CartBasinApiCols;
