import { useRef, useEffect, useState, useCallback } from "react";

import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Sketch from "@arcgis/core/widgets/Sketch";
import Expand from "@arcgis/core/widgets/Expand";
import Compass from "@arcgis/core/widgets/Compass";
import Legend from "@arcgis/core/widgets/Legend";

import Print from "@arcgis/core/widgets/Print";
import Basemap from "@arcgis/core/Basemap.js";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery.js";
import Zoom from "@arcgis/core/widgets/Zoom";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as projection from "@arcgis/core/geometry/projection.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect.js";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine.js";
import * as colorRendererCreator from "@arcgis/core/smartMapping/renderers/color.js";
import * as colorSchemes from "@arcgis/core/smartMapping/symbology/color.js";

import { arcgisToGeoJSON, geojsonToArcGIS } from "@terraformer/arcgis"
import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import esriConfig from "@arcgis/core/config";
import Color from "@arcgis/core/Color.js";
import { IBaseMapConfig, AoIBaseMapConfig, APIKey, AssetPath } from "./config/BaseMapConfig";
import LayerIds from "./config/layers/LayerIds";

import { INIT_MAP, NEW_AOI, NEW_PROJECT, clearCurrentAoi } from "./redux/esri";

import "../../App.css";


import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { Extent, Multipoint, Polygon, SpatialReference } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import { fetchSubscriptionData } from "../store/actions/subscription-settings-actions";
import Renderers from "./config/carto/Renderers";
import FieldNames from "./config/layers/FieldNames";
import { hideSiteLoader, showSiteLoader } from "../store/actions/modal-actions";
import FilterPanel from "./FilterPanel";
import { fetchAoiList, fetchAoiStats, setLastAddedAoiName } from "../store/actions/aoi-actions";
import { AoiListObject, IColorByItem } from "../models/redux-models";
import { drillTypeOption, productType, wellStatusOption, wellTypeOption } from "../cartBasinToCounty/CartBasinConstant";
import { IFilter, SetPanelFilter, UpdateColorByStats } from "./redux/filters";
import { getMapData, handleSelectedAoiData, handleSelectedWellRigOrPermitUIDList, handleRearrange, handleTabIndex } from "../store/actions/wells-rigs-action";
import BaseLayers from "./config/layers/BaseLayers";
import { isInstanceOfRigsDataObj } from "../common/Map/helper"
import { updateMapGraphicLayer } from "./utility/mapUtilities";
import { useLocation } from "react-router-dom";
import { aoiPathname } from "../../utils/helper";
import { toast } from "react-toastify";


esriConfig.apiKey = APIKey;
esriConfig.assetsPath = AssetPath;
let webmap: WebMap;
let mapview: MapView;
let print: Print;
let sketch: Sketch;
let mapKey: Expand;
let wellsLayerView: __esri.FeatureLayerView;


const defaultQuery = "1=1";
const excludedEffect = "drop-shadow(1px, 1px, 2px) grayscale(90%) opacity(30%) contrast(200%)";

interface IHighlight {
    attributes: any,
    remove: any
}

interface IAWSEXtent {
    tile: number,
    xmin: number,
    xmax: number,
    ymin: number,
    ymax: number,
    spatialReference: { wkid: number };
}

interface ISubscriptionMapData {
    type: number,
    name: string,
    state: string
}

interface IAoIMapProps {
    allowCreateAoI: boolean;
}

function AoIMap(props: IAoIMapProps) {
    const { allowCreateAoI } = props;
    const dispatch = useAppDispatch();
    const aoiMapDiv = useRef(null);
    const [config, setConfig] = useState({});
    const [mapSet, setMapSet] = useState(false);
    const [layersLoaded, setLayersLoaded] = useState(false);
    const [wellLayerLoaded, setWellLayerLoaded] = useState(false);
    const [screenPoint, setScreenPoint] = useState({});
    const prevScreenPoint = useRef({});
    const [highlights, setHighlights] = useState<IHighlight[]>([]);
    const [selectedHighlight, setSelectedHighlight] = useState<IHighlight | null>(null);
    const [currentConfirmSketch, setCurrentConfirmSketch] = useState<Graphic>();
    const [currentSubscriptions, setCurrentSubscriptions] = useState<ISubscriptionMapData[]>([]);
    const [subscriptionQuery, setSubscriptionQuery] = useState<string>('')
    const [wellDataQuery, setWellDataQuery] = useState<string>('')
    const [saveCurrentType, setSaveCurrentType] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [heatmapLoaded, setHeatmapLoaded] = useState(false);
    const [mapLoading, setMapLoading] = useState(true);
    const [popupSelectedIndex, setPopupSelectedIndex] = useState(0);
    const [awsSmallerExtents, setAWSSmallerExtents] = useState<IAWSEXtent[]>([]);
    const [mapKeyVisible, setMapKeyVisible] = useState<boolean>(true);
    const [zoomToWells, setZoomToWells] = useState<boolean>(false);
    const [graphicLayersLoading, setGraphicLayersLoading] = useState<boolean>(false);
    const [checkAndSetFeatures, setCheckandSetFeatures] = useState<string[] | undefined>(undefined);
    const [mapHasLoadedOnce, setMapHasLoadedOnce] = useState<boolean>(false);
    const [currentSelectedAoi, setCurrentSelectedAoi] = useState<number>(0);
    const [previousColorByColors, setPreviousColorByColors] = useState<{ [key: string]: string }>({});
    const [isKeepDrawingActive, setIsKeepDrawingActive] = useState<boolean>(false);
    const [currentBasemapType, setCurrentBasemapType] = useState<'dark' | 'light'>('dark');
    const previousSketchTypeRef = useRef<string>('');
    const filterPanelRef = useRef(null);
    const createHandlerRef = useRef<any>(null);
    const deleteHandlerRef = useRef<any>(null);
    const zoomRef = useRef<__esri.Zoom | null>(null);
    const mapViewNavigationHandlersRef = useRef<__esri.Handle[]>([]);
    const location = useLocation();

    const access_token = useAppSelector(state => state.auth.user.access_token);
    const aoiDataLoading = useAppSelector(state => state.aoi.aoiDataLoading);
    const savedAoiData = useAppSelector(state => state.aoi.savedAoiData);
    const lastAddedAoiName = useAppSelector(state => state.aoi.lastAddedAoiName);
    const createAoiModal = useAppSelector(state => state.modal.createAoiModal);
    const loadSubscriptionData = useAppSelector(state => state.subscriptionSettings.loadSubscriptionData);
    const subscriptionData = useAppSelector(state => state.subscriptionSettings.subscriptionData);
    const layers = useAppSelector(state => state.filters.layers);
    const panelFilters = useAppSelector(state => state.filters.panelFilters);
    const heatmapGridTotals = useAppSelector(state => state.filters.heatmapGridTotals);
    const wellsEnabled = useAppSelector(state => state.filters.wellsEnabled);
    const rigsEnabled = useAppSelector(state => state.filters.rigsEnabled);
    const aoiEnabled = useAppSelector(state => state.filters.aoiEnabled);
    const heatmapEnabled = useAppSelector(state => state.filters.heatmapEnabled);
    const colorByField = useAppSelector(state => state.filters.colorByField);
    const colorByStats = useAppSelector(state => state.filters.colorByStats);
    const wellsDataList = useAppSelector(state => state.wellsAndRigs.wellsData.data);
    const rigsDataList = useAppSelector(state => state.wellsAndRigs.rigsData.data);
    const wellsAndRigs = useAppSelector(state => state.wellsAndRigs);
    const getDontGrayOutOtherWells = () => {
        let dontGrayOutOtherWells = false
        /* If the selected uid is a rig, rather than a well, then we do not want to gray
        out the wells.  Here, I'm looking at the list of full objects, and seeing if the 
        first one is an instance of a RigsDataObj.  In that case, we should not gray out
        the wells */
        if (wellsAndRigs.selectedWellRigOrPermitList && wellsAndRigs.selectedWellRigOrPermitList.length > 0 && isInstanceOfRigsDataObj(wellsAndRigs.selectedWellRigOrPermitList[0])) {
            dontGrayOutOtherWells = true
        }
        return dontGrayOutOtherWells
    }

    useEffect(() => {
        if (aoiMapDiv.current) {
            /**
             * Initialize config
             */
            setConfig(new AoIBaseMapConfig());
        }
    }, []);

    const findLayerByTitle = (title: string) => {
        if (!webmap) return null;
        return webmap?.layers.find((layer) => layer.title === title) as FeatureLayer;
    }

    useEffect(() => {
        if (layersLoaded) {
            const pLayer = findLayerByTitle(LayerIds.WellsDataGraphics) as FeatureLayer;
            if (pLayer) {
                pLayer.visible = wellsEnabled;
            }
            const lLayer = findLayerByTitle(LayerIds.WellLineGraphics) as FeatureLayer;
            if (lLayer) {
                lLayer.visible = wellsEnabled;
            }
        }
    }, [wellsEnabled]);

    useEffect(() => {
        if (layersLoaded) {
            const pLayer = findLayerByTitle(LayerIds.RigsData) as FeatureLayer;
            if (pLayer) {
                pLayer.visible = rigsEnabled;
            }
        }
    }, [rigsEnabled]);

    useEffect(() => {
        if (layersLoaded) {
            const pLayer = findLayerByTitle(LayerIds.AoIData) as FeatureLayer;
            if (pLayer) {
                pLayer.visible = aoiEnabled;
            }
        }
    }, [aoiEnabled]);

    useEffect(() => {
        const layer = findLayerByTitle(LayerIds.GridStatsGraphics) as FeatureLayer;
        if (layer) {
            layer.visible = heatmapEnabled;
        }
    }, [heatmapEnabled]);

    useEffect(() => {
        if (layersLoaded && mapKey) {
            if (wellsAndRigs.viewAnalytics) {
                mapKey.collapse();
            } else {
                if (mapKeyVisible) {
                    mapKey.collapse();
                    setTimeout(() => mapKey.expand(), 100);
                }
            }
        }
    }, [wellsAndRigs.viewAnalytics])

    useEffect(() => {
        if (mapLoading || !layersLoaded) {
            return;
        }

        const rigsLayer = findLayerByTitle(LayerIds.RigsData) as FeatureLayer;
        if (rigsLayer && rigsDataList) {
            if (rigsDataList.length === 0) {
                rigsLayer.definitionExpression = "1=0";
            } else {
                const where = `rig_id IN(${rigsDataList?.map((item) => `'${item.id}'`).join(',')})`;
                rigsLayer.definitionExpression = where;
            }
        }

    }, [rigsDataList, layersLoaded, mapLoading]);

    const disableMapNavigation = (_mapView: __esri.MapView, disable: boolean) => {
        // First remove all existing handlers
        mapViewNavigationHandlersRef.current.forEach(handler => handler?.remove());
        mapViewNavigationHandlersRef.current = [];

        if (!disable) {
            return;
        }

        function stopEvtPropagation(e: any) {
            e.stopPropagation();
        }

        mapViewNavigationHandlersRef.current.push(
            _mapView.on("mouse-wheel", stopEvtPropagation),
            _mapView.on("double-click", stopEvtPropagation),
            _mapView.on("double-click", ["Control"], stopEvtPropagation),
            _mapView.on("drag", stopEvtPropagation),
            _mapView.on("drag", ["Shift"], stopEvtPropagation),
            _mapView.on("drag", ["Shift", "Control"], stopEvtPropagation),
            _mapView.on("key-down", (e) => {
                const prohibitedKeys = ["+", "-", "Shift", "_", "=", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
                const keyPressed = e.key;
                if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                    e.stopPropagation();
                }
            })
        )
    };

    const aoiPopupActionTriggered = (event: any) => {
        if (event.action.id === "keep-drawing") {
            setIsKeepDrawingActive(true);
            sketch.create(previousSketchTypeRef.current as any);
            mapview.popup.close();
        } else if (event.action.id === "aoi") {
            setSaveCurrentType(event.action.id);
        }
    }

    const handleSketchCreate = useCallback(
        (event: any) => {
            const gLayer = webmap?.layers.find(
                (layer) => layer.title === LayerIds.AoiSketch
            ) as GraphicsLayer;
            if (event.state === "start" || event.state === "cancel") {
                if (previousSketchTypeRef.current === "") {
                    gLayer?.removeAll();
                    setCurrentConfirmSketch(undefined);
                }
            }
            if (event.state === "complete") {
                var area = geometryEngine.geodesicArea(event.graphic.geometry, 'square-miles');
                if (area > 10000) {
                    toast.error(
                        `Area of AOI cannot exceed 10,000 square miles. Continue drawing to create a smaller AOI.`
                    );
                    gLayer?.removeAll();
                    sketch.create(previousSketchTypeRef.current as any);
                    return;
                }

                if (
                    previousSketchTypeRef.current === "" ||
                    currentConfirmSketch === undefined
                ) {
                    setCurrentConfirmSketch(event.graphic);
                    mapview?.whenLayerView(gLayer).then((layerView) => {
                        layerView.highlight(event.graphic);
                        setIsKeepDrawingActive(false);
                    });
                } else {
                    // Union the new sketch with the existing sketch
                    const newSketch = event.graphic;

                    const existingSketch = currentConfirmSketch;
                    if (existingSketch) {
                        const unionedSketch = geometryEngine.union([
                            existingSketch.geometry,
                            newSketch.geometry,
                        ]) as __esri.Polygon;

                        const unionedGraphic = new Graphic({
                            geometry: unionedSketch,
                            symbol: newSketch.symbol
                        });
                        setCurrentConfirmSketch(unionedGraphic);
                        gLayer?.removeAll();
                        gLayer?.add(unionedGraphic);
                        mapview?.whenLayerView(gLayer).then((layerView) => {
                            layerView.highlight(unionedGraphic);
                            setIsKeepDrawingActive(false);
                        });
                    }
                }
            }
        },
        [currentConfirmSketch]
    );

    const handleSketchDelete = useCallback(() => {
        // AOI Popup
        mapview.popup.close();
        setCurrentConfirmSketch(undefined);
    }, []);

    useEffect(() => {
        //@ts-ignore
        if (config !== null && config.hasOwnProperty("mapConfig") && !mapSet && subscriptionQuery.length > 0) {
            setMapSet(true);
            //@ts-ignore
            const { mapConfig } = config;
            webmap = new WebMap({
                portalItem: {
                    id: mapConfig.webMapId
                },
                basemap: "dark-gray-vector"
            });

            const graphicsLayer = new GraphicsLayer({ title: LayerIds.AoiSketch });
            webmap.add(graphicsLayer);

            const aoiGraphicsLayer = new GraphicsLayer({ title: LayerIds.AoIData });
            webmap.add(aoiGraphicsLayer, webmap?.layers.length - 1);
            const selectedFeaturesGraphicsLayer = new GraphicsLayer({ title: LayerIds.SelectedFeatures });
            webmap.add(selectedFeaturesGraphicsLayer);

            mapview = new MapView({
                container: aoiMapDiv.current == null ? undefined : aoiMapDiv.current,
                map: webmap,
                ui: { components: ["attribution"] },
                constraints: {
                    minZoom: mapConfig.minZoom,
                },
            });


            print = new Print({
                view: mapview,
                templateOptions: {
                    title: "Print Map",
                    author: "Energy Domain",
                    legendEnabled: false,
                    scaleEnabled: true,
                    dpi: "300"
                }
            });

            esriConfig.request?.interceptors?.push({
                urls: ["https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"],
                before: async (params) => {
                    if (params?.requestOptions?.query?.Web_Map_as_JSON) {
                        const Web_Map_as_JSON = JSON.parse(params.requestOptions.query.Web_Map_as_JSON);
                        const screenshot = await mapview.takeScreenshot({ format: "png" });
                        const testLayer = {
                            type: "image",
                            title: "",
                            opacity: 1,
                            extent: mapview.extent.toJSON(),
                            imageData: screenshot.dataUrl.replace("data:image/png;base64,", "")
                        }

                        if (Web_Map_as_JSON.layoutOptions.legendOptions.operationalLayers) {
                            Web_Map_as_JSON.operationalLayers = [testLayer];
                        }
                        params.requestOptions.query.Web_Map_as_JSON = JSON.stringify(Web_Map_as_JSON);
                    }
                },
            });

            const printExpand = new Expand({
                view: mapview,
                content: print,
                icon: "esri-icon-printer",
                expandTooltip: "Print",
                group: "top-right",
                expanded: false
            });

            mapview.ui.add(printExpand, 'top-right');

            const compass = new Compass({
                view: mapview
            });

            mapview.ui.add(compass, 'top-right');

            const darkGrayVector = Basemap.fromId("dark-gray-vector");
            darkGrayVector.when(() => darkGrayVector.title = "Dark")

            const grayVector = Basemap.fromId("gray-vector");
            grayVector.when(() => grayVector.title = "Light")

            const satellite = Basemap.fromId("satellite");
            satellite.when(() => satellite.title = "Satellite")

            const basemapGallery = new BasemapGallery({
                view: mapview,
                source: [darkGrayVector, grayVector, satellite],
            });

            const basemapGalleryExpand = new Expand({
                view: mapview,
                content: basemapGallery,
                icon: "esri-icon-basemap",
                expandTooltip: "Basemap Gallery",
                group: "top-right",
                expanded: false
            });

            reactiveUtils.watch(
                () => basemapGallery.activeBasemap,
                (currentBasemap) => {
                    basemapGalleryExpand.collapse();
                    if (currentBasemap.title === "Dark") {
                        setCurrentBasemapType('dark');
                    } else {
                        setCurrentBasemapType('light');
                    }
                }
            );


            mapview.ui.add(basemapGalleryExpand, 'top-right');

            zoomRef.current = new Zoom({
                view: mapview,
                visible: false
            });
            mapview.ui.add(zoomRef.current, 'top-right');

            // Disable map navigation until tabular data is loaded.
            disableMapNavigation(mapview, true);

            mapview.on("layerview-create", function (event) {
                if (event.layer.title === LayerIds.WellsDataGraphics) {
                    wellsLayerView = event.layerView as __esri.FeatureLayerView;
                    wellsLayerView.highlightOptions = {
                        color: new Color("#0000ff"),
                        haloOpacity: 0.9,
                        fillOpacity: 0.2
                    };
                    setWellLayerLoaded(true);
                }
            });

            reactiveUtils.watch(
                () => mapview.popup.visible,
                (visible) => {
                    if (visible) {
                        setPopupOpen((prevState) => {
                            if (prevState !== true) {
                                setTimeout(() => mapview.popup.reposition(), 200)
                                return true;
                            }
                            return prevState;
                        });
                    } else {
                        setPopupOpen((prevState) => {
                            if (prevState === true) {
                                return false;
                            }
                            return prevState;
                        });
                    }
                }
            );

            reactiveUtils.watch(
                () => mapview.scale,
                (scale) => {
                    if (print) {
                        print.templateOptions.scale = scale;
                    }
                }
            );

            reactiveUtils.watch(
                () => mapview.popup.selectedFeatureIndex,
                (selectedFeatureIndex) => {
                    setPopupSelectedIndex(selectedFeatureIndex);
                }
            );
            reactiveUtils.watch(
                () => mapview.popup.features,
                (features) => {
                    if (features && features.length > 0) {
                        if (features.length > 1) {
                            mapview.popup.selectedFeatureIndex = 1;
                        }
                    }
                }
            );

            const wellGraphicsLayer = BaseLayers.getWellsGraphicsLayer([]);
            wellGraphicsLayer.visible = false;
            mapview.map.layers.add(wellGraphicsLayer);

            const wellLinesGraphicsLayer = BaseLayers.getLinesGraphicsLayer([]);
            wellLinesGraphicsLayer.visible = false;
            mapview.map.layers.add(wellLinesGraphicsLayer);

            mapview.on('click', (event: any) => {
                setScreenPoint(event);
                event.preventDefault();
            });

            webmap.when(() => {
                webmap?.layers.forEach((layer) => layer.opacity = 0);
                const countyLayer = findLayerByTitle(LayerIds.CountyStats) as FeatureLayer;
                if (countyLayer) {
                    countyLayer.visible = false
                }
                dispatch(INIT_MAP({ map: webmap, mapview }));
                //TODO: Remove the rest of the filter code and ensure no further issues
                // }

                const layers = webmap?.layers
                    .filter(layer => { return layer.type === 'feature' })
                    .map(layer => {
                        const featLayer = layer as FeatureLayer;
                        featLayer.outFields = ['*'];
                        const isPoint = featLayer.title === LayerIds.WellData
                        if (isPoint || featLayer.title === LayerIds.LateralLines) {
                            featLayer.definitionExpression = wellDataQuery
                            if (isPoint) {
                                //@ts-ignore
                                featLayer.renderer = Renderers.wellsByTypeRendererPlatform;
                            }
                            featLayer.visible = false;
                        } else {
                            if (featLayer.title !== LayerIds.WellsDataGraphics && featLayer.title !== LayerIds.CensusCountyLayer) {
                                featLayer.definitionExpression = subscriptionQuery
                            }
                        }
                        if (featLayer.title === LayerIds.RigsData) {
                            //@ts-ignore 
                            featLayer.renderer = Renderers.rigPointsRenderer;
                        }
                        return featLayer;
                    });


                sketch = new Sketch({
                    layer: graphicsLayer,
                    view: mapview,
                    creationMode: "single",
                    availableCreateTools: ["polygon", "circle", "rectangle"],
                    visibleElements: {
                        createTools: { point: false, polyline: false },
                        duplicateButton: false,
                        selectionTools: { "lasso-selection": false, "rectangle-selection": false },
                        settingsMenu: false,
                        undoRedoMenu: false,
                    },
                    visible: false
                });

                createHandlerRef.current = sketch.on("create", handleSketchCreate);
                deleteHandlerRef.current = sketch.on("delete", handleSketchDelete);

                reactiveUtils.watch(
                    () => sketch.activeTool,
                    (activeTool) => {
                        if (activeTool) {
                            previousSketchTypeRef.current = activeTool;
                        }
                    }
                );

                reactiveUtils.watch(
                    () => sketch.visible,
                    () => {
                        const updateSketchStyles = () => {
                            const container = sketch.container;
                            if (typeof container === "string") {
                                setTimeout(() => updateSketchStyles(), 50);
                                return;
                            }
                            const sketchSections = container.querySelectorAll(".esri-sketch__section");
                            if (!sketchSections || sketchSections.length === 0) {
                                setTimeout(() => updateSketchStyles(), 50);
                                return;
                            }

                            sketchSections.forEach(sketchSection => {
                                sketchSection.querySelectorAll("calcite-action").forEach((element: any) => {
                                    element.setAttribute("scale", "m")
                                });
                            });
                        }
                        updateSketchStyles();
                    }
                );

                reactiveUtils.on(() =>
                    mapview.popup,
                    'trigger-action',
                    (e) => aoiPopupActionTriggered(e)
                );
                mapview.ui.add(sketch, 'top-right');

                mapview.popup.defaultPopupTemplateEnabled = false;
                mapview.popup.dockEnabled = true;

                mapKey = new Expand({
                    view: mapview,
                    content: filterPanelRef.current == null ? undefined : filterPanelRef.current,
                    expanded: true,
                    expandIcon: 'key',
                    expandTooltip: 'Show Map Key',
                    collapseTooltip: 'Hide Map Key',
                    group: "top-right",
                });

                reactiveUtils.watch(() =>
                    mapKey.expanded,
                    (expanded) => {
                        setMapKeyVisible(expanded);
                    }
                );
                mapview.ui.add(mapKey, 'top-right');

                setLayersLoaded(true);
            });
        }
        return () => {
            // Re enable map navigation
            disableMapNavigation(mapview, false);
        }
    }, [config, subscriptionQuery]);

    useEffect(() => {
        if (sketch) {
            // Remove previous handlers before rebinding
            createHandlerRef.current?.remove();
            deleteHandlerRef.current?.remove();
            createHandlerRef.current = sketch.on("create", handleSketchCreate);
            deleteHandlerRef.current = sketch.on("delete", handleSketchDelete);
        }
    }, [handleSketchCreate, handleSketchDelete]);

    useEffect(() => {
        aoiDataLoading &&
            dispatch(
                fetchAoiList(access_token, false)
            );
        // eslint-disable-next-line
    }, [aoiDataLoading]);

    useEffect(() => {
        if (!createAoiModal) {
            dispatch(clearCurrentAoi());
        }
    }, [createAoiModal])

    useEffect(() => {
        if (layersLoaded) {
            const aoiLayer: any = findLayerByTitle(LayerIds.AoIData) as FeatureLayer;
            if (aoiLayer) {
                const sym = { type: 'simple-fill', color: "#16A15E33" };
                aoiLayer.removeAll();
                savedAoiData.forEach((item: AoiListObject) => {
                    const geoJson = wktToGeoJSON(item.geom);
                    const geom: any = geojsonToArcGIS(geoJson)
                    const g = new Graphic({
                        attributes: {
                            well_count: item.well_count,
                            permit_count: item.permit_count,
                            completion_count: item.completion_count,
                            operator_count: item.operator_count,
                            name: item.aoi_name,
                            id: item.id
                        },
                        geometry: { ...geom, type: 'polygon' },
                        symbol: sym
                    })
                    //@ts-ignore
                    //g.popupTemplate = aoiDataTemplate;
                    aoiLayer.graphics.push(g);
                    if (lastAddedAoiName && lastAddedAoiName === item.aoi_name) {
                        mapview.goTo(g.geometry);
                        dispatch(setLastAddedAoiName(""));
                    }
                })
            }
        }
    }, [savedAoiData, layersLoaded]);

    useEffect(() => {
        if (layersLoaded && wellsAndRigs.selectedAoiData && wellsAndRigs.selectedAoiData.aoi_id !== currentSelectedAoi) {
            if (awsSmallerExtents && awsSmallerExtents.length > 0) {
                setCurrentSelectedAoi(wellsAndRigs.selectedAoiData.aoi_id);
            }
        }
    }, [wellsAndRigs.selectedAoiData, awsSmallerExtents]);

    useEffect(() => {
        if (wellsAndRigs.selectedAoiData) {
            const aoi = getAoi(wellsAndRigs.selectedAoiData.aoi_id);
            if (aoi) {
                mapview.when(() => {
                    mapZoomToTarget(aoi);
                });
            }
        }
    }, [wellsAndRigs.selectedAoiData, aoiDataLoading]);

    useEffect(() => {
        if (currentSelectedAoi && !mapLoading) {
            const aoi = getAoi(wellsAndRigs.selectedAoiData.aoi_id);
            if (aoi) {
                updateAoiSelection(aoi);
            }
        }
    }, [currentSelectedAoi, mapLoading]);

    useEffect(() => {
        const noAoI = !wellsAndRigs.selectedAoiData || wellsAndRigs.selectedAoiData.aoi_id == 0;
        const noCurrentWells = !wellsAndRigs.selectedWellRigOrPermitUIDList || wellsAndRigs.selectedWellRigOrPermitUIDList.length < 1;

        if (noAoI && noCurrentWells && !popupOpen) {
            resetEffectedWells();
        }

    }, [wellsAndRigs.selectedAoiData, wellsAndRigs.selectedWellRigOrPermitUIDList, popupOpen]);

    useEffect(() => {
        if (layersLoaded && !!wellsAndRigs.selectedRowId || wellsAndRigs.selectedRowId.toString().length === 0 && popupOpen) {
            mapview.popup.visible = false;
        }
        if (!wellsAndRigs.selectedRowId || (!config || !config.hasOwnProperty("mapConfig"))) {
            return;
        }
        const layer = findLayerByTitle(LayerIds.RigsData) as FeatureLayer;

        layer?.queryFeatures({ where: `rig_id = '${wellsAndRigs.selectedRowId}'`, returnGeometry: true, outFields: ["*"] }).then((res: any) => {
            if (res.features.length > 0) {
                const geom = res.features[0].geometry;
                //@ts-ignore
                const { mapConfig } = config;
                mapview.when(() => {
                    mapZoomToTarget({ target: geom, zoom: mapConfig.highlightZoom });
                });
                mapview.openPopup({ features: res.features, location: res.features[0].geometry, });
            }
        });

    }, [wellsAndRigs.selectedRowId, config]);

    useEffect(() => {
        if ((!wellsAndRigs.downloadCol && wellsAndRigs.showTableLoader) || !heatmapLoaded || graphicLayersLoading) {
            setMapLoading(true);
        }
        if (mapLoading && !wellsAndRigs.downloadCol && !wellsAndRigs.showTableLoader && heatmapLoaded && !graphicLayersLoading) {
            setMapLoading(false);
        }
    }, [wellsAndRigs.showTableLoader, heatmapLoaded, graphicLayersLoading])

    useEffect(() => {
        const updatePopupStyles = () => {
            const container = mapview.popup.container;
            if (typeof container === "string") {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            const contentFeatureElement = container.querySelector(
                ".esri-features__content-feature"
            );

            if (!contentFeatureElement) {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            //The action bar (immediately beneath the title bar)
            const calciteActionBar =
                contentFeatureElement.querySelector("calcite-action-bar");

            if (!calciteActionBar) {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            //The individual buttons in the action bar
            const calciteActionElements =
                calciteActionBar.querySelectorAll("calcite-action");

            if (calciteActionElements.length === 0) {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            for (let x = 0; x < calciteActionElements.length; x++) {
                const element =
                    calciteActionElements[x].shadowRoot?.querySelector(
                        "button"
                    );

                if (!element) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                calciteActionElements[x].setAttribute("text-enabled", "");

                element.style.padding = "2px 10px";
                element.style.marginBottom = "5px";

                const [iconContainer] = element.querySelectorAll(
                    ".icon-container"
                ) as NodeListOf<HTMLElement>;
                iconContainer.style.height = "30px";
                iconContainer.style.width = "30px";

                const calciteIcons = element.querySelectorAll(
                    "calcite-icon"
                ) as NodeListOf<HTMLElement>;
                const featureIcons = contentFeatureElement.querySelectorAll(
                    "span.esri-features__icon"
                ) as NodeListOf<HTMLElement>;
                const icons = [...calciteIcons, ...featureIcons];
                icons.forEach((icon) => {
                    icon.style.height = "90%";
                    icon.style.width = "90%";
                    icon.style.fontSize = "25px";
                });
                const [textContainer] = element.querySelectorAll(
                    ".text-container"
                ) as NodeListOf<HTMLElement>;
                textContainer.style.fontSize = "15.5px";
                textContainer.style.marginLeft = "4px";
            }
            setTimeout(() => { mapview.popup.reposition() }, 200);
        };
        if (popupOpen) {
            updatePopupStyles();
            window.addEventListener("resize", updatePopupStyles);
        } else {
            window.removeEventListener("resize", updatePopupStyles);
        }
        return () => {
            window.removeEventListener("resize", updatePopupStyles);
        };
    }, [popupOpen]);

    useEffect(() => {
        if (!mapLoading && layersLoaded) {
            //Refresh selected AOI layer effects
            if (wellsAndRigs.selectedAoiData && wellsAndRigs.selectedAoiData.aoi_id !== 0) {
                const aoi = getAoi(wellsAndRigs.selectedAoiData.aoi_id);
                if (aoi) {
                    //only update selection if aoi has changed since last selection
                    if (wellsAndRigs.selectedAoiData.aoi_id !== currentSelectedAoi) updateAoiSelection(aoi);
                    addLayerEffectForSelectedAoi(aoi);
                }
            }
            // Re enable map navigation
            disableMapNavigation(mapview, false);

            // Restore opacity of all webmap layers to 1
            webmap?.layers.forEach((layer) => layer.opacity = 1)

            if (zoomRef.current) {
                zoomRef.current.visible = true;
            }
        } else {
            if (mapview) {
                // Disable map navigation until tabular data is loaded.
                disableMapNavigation(mapview, true);
                if (zoomRef.current) {
                    zoomRef.current.visible = false;
                }
            }
        }
        if (mapLoading) {
            if (!mapHasLoadedOnce) {
                setMapHasLoadedOnce(true);
            }
        }
    }, [mapLoading, layersLoaded]);

    useEffect(() => {

        if (popupOpen) {
            //ENE-500 remove popup
            // const tmpFeature = mapview.popup.features && mapview.popup.features.length > 0
            //     ? mapview.popup.features[mapview.popup.selectedFeatureIndex].getAttribute('uid')
            //     : "";
            const tmpFeature = wellsAndRigs.selectedWellRigOrPermitUIDList && wellsAndRigs.selectedWellRigOrPermitUIDList.length > 0
                ? wellsAndRigs.selectedWellRigOrPermitUIDList[0]
                : "";
            // !featuresUpdated || 
            const featureSelected = wellsAndRigs.selectedRowId && wellsAndRigs.selectedRowId.toString().length > 0 && (tmpFeature === wellsAndRigs.selectedRowId)
            const currentFeature = featureSelected
                ? wellsAndRigs.selectedRowId
                : tmpFeature
            let aoi: any;
            const aoiIndex = mapview.popup.features.findIndex((f) => (f.layer && f.layer.title === LayerIds.AoIData) || (f.attributes && f.attributes.title === 'Area of Interest Statistics'))
            if (aoiIndex > -1) {
                aoi = mapview.popup.features[aoiIndex];
            }

            //Single feature selected
            // if a single feature is selected, then clear the selected AOI
            if (wellsAndRigs.selectedAoiData['aoi_id'] && location.pathname !== aoiPathname) {
                dispatch(handleSelectedAoiData({ aoi_id: 0 }))
            }
            if (currentFeature && currentFeature !== "") {
                if (!featureSelected && currentFeature !== wellsAndRigs.selectedWellRigOrPermitUIDList[0]) {
                    setCheckandSetFeatures([currentFeature.toString()]);
                }
                if (aoiIndex > -1 && wellsAndRigs.selectedWellRigOrPermitUIDList.length !== 1) {
                    addLayerEffectForSelectedAoi(aoi);
                } else {
                    addLayerEffectForSelectedFeatures([currentFeature.toString()]);
                }
            } else {
                if (aoi) {
                    updateAoiSelection(aoi);
                }
            }
            setTimeout(() => { mapview.popup.reposition() }, 500);
        } else {
            const gLayer = webmap?.layers.find((layer) => layer.title === LayerIds.AoiSketch) as GraphicsLayer;
            if (!isKeepDrawingActive) {
                if (gLayer) {
                    gLayer.removeAll();
                }
                setCurrentConfirmSketch(undefined);
            }
        }
    }, [popupOpen, popupSelectedIndex]);

    useEffect(() => {
        //Check for updated filter properties...
        if (panelFilters && panelFilters.hasOwnProperty(FieldNames.queryPanelKeys[0])) {
            const renderers = getCurrentRenderers(panelFilters);
            const filterAllIfEmpty = false;
            if (layers) {
                const filters = []
                filters.push({ layerId: LayerIds.WellData, filterAllIfEmpty, value: renderers, field: FieldNames.wellDataPointsTypeId });

                FieldNames.queryPanelKeys.forEach((panelKey, i) => {
                    if (panelKey in panelFilters) {
                        let formattedList: string[] = [];
                        if (Array.isArray(panelFilters[panelKey])) {
                            formattedList = panelFilters[panelKey].map((filterValue: string) => {
                                const idxs = filterValue.toString().split("");
                                return idxs.map((c: string, i) => {
                                    return i == 0
                                        ? c.toUpperCase()
                                        : [' ', '-', '_', '/'].includes(idxs[i - 1])
                                            ? c.toUpperCase()
                                            : c
                                }).join("");
                                //return filterValue.charAt(0).toUpperCase() + filterValue.slice(1);

                            });
                        } else {
                            for (const key in panelFilters[panelKey]) {
                                if (key.toLowerCase() === "start") {
                                    formattedList.push("DATERANGE");
                                }
                                formattedList.push(panelFilters[panelKey][key]);
                            }
                            for (const key in panelFilters[panelKey]) {
                                if (key.toLowerCase() === "min") {
                                    formattedList.push("NUMBERRANGE");
                                }
                                formattedList.push(panelFilters[panelKey][key]);
                            }
                        }
                        filters.push({ layerId: LayerIds.WellData, filterAllIfEmpty, value: formattedList, field: FieldNames.panelKeyMatch[i] })
                    } else {
                        filters.push({ layerId: LayerIds.WellData, filterAllIfEmpty, value: [], field: FieldNames.panelKeyMatch[i] });
                    }
                });
                //TODO: Remove the rest of the filter code and ensure no further issues
                //dispatch(SET_FILTER(filters));
            }
        }
    }, [panelFilters])
    const getColorByCategories = (_colorBySummary: { [key: string]: number; }) => {
        const colorByKeys = Object.keys(_colorBySummary);
        let colorByCategories = [] as IColorByItem[];
        const _newColorByColors = {} as { [key: string]: string };
        const darkModeColors = ["#e31a1c", "#33a02c", "#1f78b4", "#ff8f00", "#6a3d9a", "#ffff99", "#b15928", "#fba4a9", "#a6cee3", "#4b9a2c", "#ff7f00", "#8fb8d4", "#e34a1c", "#cab2d6", "#b2df8a", "#fdbf6f", "#97c98a", "#fb9a99", "#fdbf7f", "#5a8bb4"]
        const lightModeColors = ["#e31a1c", "#33a02c", "#1f78b4", "#ff8f00", "#6a3d9a", "#c2185b", "#0288d1", "#fbc02d", "#5d4037", "#00796b", "#512da8", "#303f9f", "#c62828", "#2e7d32", "#e64a19", "#6a1b9a", "#ad1457", "#0277bd"];
        // get a copy of the colors array
        const colors = currentBasemapType === "dark" ? darkModeColors : lightModeColors;
        let _numberOfNA = 0;

        if (colorByKeys.length > 0) {
            const defaultColor = "gray";
            colorByKeys.forEach((key) => {
                colorByCategories.push({
                    name: key,
                    count: _colorBySummary[key],
                    color: defaultColor,
                });
            });

            const _numberOfColors = colors.length;
            _numberOfNA = colorByCategories.find((c) => c.name === "N/A")?.count || 0;
            colorByCategories = colorByCategories.filter((c) => c.name !== "N/A");

            // Sort so we can assign colors to the most common categories first
            colorByCategories.sort((a, b) => b.count - a.count);

            colorByCategories = colorByCategories.map((c, i) => {
                if (i < _numberOfColors) {
                    // first check previous colors
                    const previousColor = previousColorByColors[c.name];
                    if (previousColor && previousColor !== defaultColor) {
                        c.color = previousColor;
                        _newColorByColors[c.name] = previousColor;
                    } else {
                        c.color = colors[0];
                        _newColorByColors[c.name] = colors[0];
                    }
                    // remove from colors
                    const colorIndex = colors.indexOf(_newColorByColors[c.name]);
                    if (colorIndex > -1) {
                        colors.splice(colorIndex, 1);
                    }
                }

                return c;
            });
        }
        colorByCategories.sort((a, b) => b.count - a.count)
        return { categories: colorByCategories, lookup: _newColorByColors, numberofNA: _numberOfNA };
    }

    useEffect(() => {
        if (layersLoaded && wellsAndRigs.mapLineData) {
            setMapLoading(true);
            const mapWellGraphics: Graphic[] = [];
            let colorBySummary = {
                [FieldNames.colorByOperatorName]: {} as { [key: string]: number },
                [FieldNames.colorByReservoir]: {} as { [key: string]: number },
            }

            wellsAndRigs.mapData.forEach((mapDataTile, idx) => {
                if (mapDataTile.data !== null) {
                    mapDataTile.data.forEach((f) => {
                        const operatorColorByValue = f[FieldNames.colorByOperatorName as keyof typeof f] as string;
                        const reservoirColorByValue = f[FieldNames.colorByReservoir as keyof typeof f] as string;
                        colorBySummary[FieldNames.colorByOperatorName][operatorColorByValue] = colorBySummary[FieldNames.colorByOperatorName][operatorColorByValue] || 0;
                        colorBySummary[FieldNames.colorByReservoir][reservoirColorByValue] = colorBySummary[FieldNames.colorByReservoir][reservoirColorByValue] || 0;

                        // if (operatorColorByValue !== "N/A") {
                        colorBySummary[FieldNames.colorByOperatorName][operatorColorByValue]++;
                        // }
                        // if (reservoirColorByValue !== "N/A") {
                        colorBySummary[FieldNames.colorByReservoir][reservoirColorByValue]++;
                        // }

                        const pt = {
                            type: 'point',
                            spatialReference: { wkid: 4326 },
                            y: f.latitude,
                            x: f.longitude
                        }
                        mapWellGraphics.push(new Graphic({ attributes: { ...f, ObjectId: idx + 1 }, geometry: pt }));
                    });
                }
            });
            const mapWellLineGraphics: Graphic[] = [];
            wellsAndRigs.mapLineData.forEach((mapLineDataTile, idx) => {
                if (mapLineDataTile.data !== null) {
                    mapLineDataTile.data.forEach((f) => {
                        if (wellsAndRigs.mapData !== null && wellsAndRigs.mapData.length > 0 && wellsAndRigs.mapData[0].data?.find((md) => md.well_api === f.well_api)) {
                            const line = wktToGeoJSON(f.line_geometry);
                            const esriLine: any = geojsonToArcGIS(line);
                            mapWellLineGraphics.push(new Graphic({ attributes: { ...f, ObjectId: idx + 1 }, geometry: { ...esriLine, type: 'polyline' } }));
                        }
                    });
                }
            });

            const { categories: colorByCategoriesOperator, lookup: colorByCategoriesOperatorLookup, numberofNA: colorByNumberOfNAOperator } = getColorByCategories(colorBySummary[FieldNames.colorByOperatorName]);
            const { categories: colorByCategoriesReservoir, lookup: colorByCategoriesReservoirLookup, numberofNA: colorByNumberOfNAReservoir } = getColorByCategories(colorBySummary[FieldNames.colorByReservoir]);

            const newColorByStats = {
                "well_status": [] as IColorByItem[],
                [FieldNames.colorByOperatorName]: colorByCategoriesOperator,
                [FieldNames.colorByReservoir]: colorByCategoriesReservoir,
                "naOperator": colorByNumberOfNAOperator,
                "naReservoir": colorByNumberOfNAReservoir
            }

            let colorByCategories = [] as IColorByItem[];
            if (colorByField === FieldNames.colorByOperatorName) {
                colorByCategories = colorByCategoriesOperator;
                setPreviousColorByColors(colorByCategoriesOperatorLookup);
            } else if (colorByField === FieldNames.colorByReservoir) {
                colorByCategories = colorByCategoriesReservoir;
                setPreviousColorByColors(colorByCategoriesReservoirLookup);
            }

            dispatch(UpdateColorByStats(newColorByStats));
            updateWellGraphics(mapWellGraphics, mapWellLineGraphics, colorByCategories);
            setMapLoading((prevState) => {
                if (!heatmapLoaded || wellsAndRigs.showTableLoader || graphicLayersLoading) return prevState;
                return false;
            });
        }
    }, [wellsAndRigs.mapData, layersLoaded, currentBasemapType])

    useEffect(() => {
        if (!webmap) {
            return;
        }
        const updateRenderers = async () => {
            setMapLoading(true);
            const wellPointsGraphicsLayer = findLayerByTitle(LayerIds.WellsDataGraphics) as FeatureLayer;
            const wellLinesGraphicsLayer = findLayerByTitle(LayerIds.WellLineGraphics) as FeatureLayer;

            if (wellPointsGraphicsLayer) {
                if (colorByField === "well_status") {
                    wellPointsGraphicsLayer.renderer = Renderers.wellsByTypeRendererPlatform as any;
                } else {
                    wellPointsGraphicsLayer.renderer = await getColorByPointRenderer(colorByStats[colorByField]);
                }
            }

            if (wellLinesGraphicsLayer) {
                if (colorByField === "well_status") {
                    wellLinesGraphicsLayer.renderer = getWellLinesRenderer();
                } else {
                    wellLinesGraphicsLayer.renderer = getColorByLineRenderer(colorByStats[colorByField]);
                }
            }
            setMapLoading((prevState) => {
                if (!heatmapLoaded || wellsAndRigs.showTableLoader || graphicLayersLoading) return prevState;
                return false;
            });
        }
        updateRenderers();
    }, [colorByField, colorByStats])

    useEffect(() => {
        if (!awsSmallerExtents || awsSmallerExtents.length === 0) {
            return
        }
        setGraphicLayersLoading(true);
        //abort graphic layers loading if they haven't loaded in 20 seconds...
        setTimeout(() => {
            setGraphicLayersLoading(false);
        }, 20000);
        const handlerAbort = new AbortController();
        const e = awsSmallerExtents[0];
        const poly = `POLYGON((${e.xmin} ${e.ymin}, ${e.xmin} ${e.ymax}, ${e.xmax} ${e.ymax}, ${e.xmax} ${e.ymin}, ${e.xmin} ${e.ymin}))`;
        dispatch(getMapData(access_token, handlerAbort, ({ ...e, ...panelFilters, geometry: poly } as unknown) as any));//, wellsAndRigs.mapData[e.tile].controller));

        return () => {
            handlerAbort.abort();
        }
    }, [panelFilters, awsSmallerExtents])

    useEffect(() => {
        if (!layersLoaded) {
            return;
        }
        if (!layers || !layers[LayerIds.WellData] || !layers[LayerIds.WellData][FieldNames.wellDataPointsTypeId]) {
            return;
        }
        const formData: any = {}
        const renderers = getCurrentRenderers(panelFilters);
        if ([...layers[LayerIds.WellData][FieldNames.wellDataPointsTypeId]].sort().join(',') === renderers.sort().join(',')) {
            return;
        }
        const labelsSelected = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.filter((info) => {
            return layers[LayerIds.WellData][FieldNames.wellDataPointsTypeId].indexOf(info.value) > -1
        }).map((info) => info.label);
        const wellTypes = wellTypeOption.filter((opt) => labelsSelected.includes(opt.value))
        if (wellTypes) {
            formData['well_type'] = wellTypes.map((wt) => wt.value);
        }
        const wellStatus = wellStatusOption.filter((opt) => labelsSelected.includes(opt.value))
        if (wellStatus) {
            formData['well_status'] = wellStatus.map((wt) => wt.value);
        }
        const drillType = drillTypeOption.filter((opt) => labelsSelected.includes(opt.value))
        if (drillType) {
            formData['drill_type'] = drillType.map((wt) => wt.value);
        }
        const prod_type = productType.filter((opt) => labelsSelected.includes(opt.value))
        if (prod_type) {
            formData['production_type'] = prod_type.map((wt) => wt.value);
        }
        //('changing left panel..');
        dispatch(SetPanelFilter(formData));
    }, [layers]);

    useEffect(() => {
        if (access_token && loadSubscriptionData) {
            dispatch(fetchSubscriptionData(access_token));
        }
    }, [access_token, loadSubscriptionData])


    useEffect(() => {
        if (currentConfirmSketch) {
            const poly = currentConfirmSketch.geometry as Polygon
            addLayerEffectForSelectedAoi(currentConfirmSketch);

            const query = {
                //query object
                geometry: currentConfirmSketch.geometry,
                spatialRelationship: "intersects",
                returnGeometry: false,
                outFields: ["well_api", "uid"],
            }

            dispatch(showSiteLoader());
            queryForUniqueWellIds(query, true).then((wellIds: any) => {
                setCheckandSetFeatures(wellIds);
                //@ts-ignore
                currentConfirmSketch.popupTemplate = confirmDataTemplate;

                let outSpatialReference = new SpatialReference({
                    wkid: 4326 //Sphere_Sinusoidal projection
                });
                const tmpWGS84 = projection.project(currentConfirmSketch.geometry, outSpatialReference) as __esri.Geometry;
                const geoJson = arcgisToGeoJSON(tmpWGS84)
                const wkt = geojsonToWKT(geoJson)
                dispatch(
                    fetchAoiStats(access_token, { ...panelFilters, geometry: wkt })
                ).then((result: any) => {
                    if (result.msg == 'success') {
                        currentConfirmSketch.attributes = { ...result.data }
                        currentConfirmSketch.attributes.title = "Area of Interest Statistics"
                    } else {
                        currentConfirmSketch.attributes = {}
                        currentConfirmSketch.attributes.title = "AOI contains no data returned"
                    }
                    mapview.popup.location = poly.centroid;
                    mapview.openPopup({ features: [currentConfirmSketch], location: poly.centroid });
                })
                    .finally(() => {
                        dispatch(hideSiteLoader());
                    });
            })
                .catch((err) => {
                });
        } else {
            // setCheckandSetFeatures([]);
        }
    }, [currentConfirmSketch])

    useEffect(() => {
        if (!subscriptionData) return;
        let tmpSubs: ISubscriptionMapData[] = [];
        const { details } = subscriptionData;
        details.forEach((item) => {
            tmpSubs.push({ type: item.line_item_type, name: item.line_item_name, state: item.state_code });
        });
        setCurrentSubscriptions(tmpSubs);
    }, [subscriptionData])

    useEffect(() => {
        if (currentConfirmSketch) {
            let outSpatialReference = new SpatialReference({
                wkid: 4326 //Sphere_Sinusoidal projection
            });
            const tmpWGS84 = projection.project(currentConfirmSketch.geometry, outSpatialReference) as __esri.Geometry;
            if (saveCurrentType === 'aoi') {
                if (currentConfirmSketch) {
                    const geoJson = arcgisToGeoJSON(tmpWGS84.toJSON())
                    dispatch(NEW_AOI({ geojson: geoJson, crs: tmpWGS84.spatialReference.wkid }));
                    setSaveCurrentType('');
                    mapview.popup.close();
                }
            }
            if (saveCurrentType === 'projects') {
                if (currentConfirmSketch) {
                    const geoJson = arcgisToGeoJSON(tmpWGS84.toJSON())
                    dispatch(NEW_PROJECT({ geojson: geoJson, crs: tmpWGS84.spatialReference.wkid }));
                    setSaveCurrentType('');
                    mapview.popup.close();
                }
            }
        }
    }, [saveCurrentType])

    useEffect(() => {
        if (screenPoint !== prevScreenPoint.current && mapSet)
            handleMapClick(screenPoint, panelFilters, wellsAndRigs.selectedWellRigOrPermitUIDList);
        prevScreenPoint.current = screenPoint;
    }, [screenPoint]);

    useEffect(() => {
        if (!layersLoaded) {
            return;
        }
        if (wellsAndRigs.selectedWellRigOrPermitUIDList && wellsAndRigs.selectedWellRigOrPermitUIDList.length >= 1) {
            addLayerEffectForSelectedFeatures(wellsAndRigs.selectedWellRigOrPermitUIDList);
        }
    }, [wellsAndRigs.selectedWellRigOrPermitUIDList, layersLoaded]);

    useEffect(() => {
        if (wellLayerLoaded && wellsAndRigs.selectedRowId && wellsAndRigs.selectedRowId.toString().length > 0) {
            selectFeatures(wellsAndRigs.selectedRowId.toString(), true, true);
        } else {
            if (selectedHighlight !== null) {
                selectedHighlight.remove();
                mapview.closePopup();
                setSelectedHighlight(null);
            }
        }
    }, [wellsAndRigs.selectedRowId, wellLayerLoaded]);

    useEffect(() => {
        if (!loadSubscriptionData) {
            let query = defaultQuery;
            if (currentSubscriptions && currentSubscriptions.length > 0) {

                const national = currentSubscriptions.filter((cs) => cs.type === 3);
                if (national && national.length > 0) {
                    query = "1=1";
                } else {
                    const basins = currentSubscriptions.filter((cs) => cs.type === 1);
                    const basinsClause = basins.length > 0 ? (basins.map((ab: ISubscriptionMapData) =>
                        FieldNames.countyStatsBasinName + " = '" + ab.name + "'")).join(" OR ") : "1=0";

                    const counties = currentSubscriptions.filter((cs) => cs.type === 2);
                    const countyClause = counties.length > 0
                        ? (counties.map((ab: ISubscriptionMapData) =>
                            "(" + FieldNames.countyStatsCountyName + " = '" + ab.name + "' AND " +
                            FieldNames.countyStatsStateAbbr + " = '" + ab.state + "')")).join(" OR ")
                        : "1=0";


                    query = "(" + countyClause + ") OR (" + basinsClause + ")";
                }
            }
            setSubscriptionQuery(query)
            const reg1 = new RegExp(FieldNames.countyStatsBasinName, "g")
            const reg2 = new RegExp(FieldNames.countyStatsStateAbbr, "g")
            const reg3 = new RegExp(FieldNames.countyStatsCountyName, "g")
            if (query === defaultQuery) {
                setWellDataQuery("1=0");
            } else {
                setWellDataQuery(query.replace(reg1, FieldNames.wellPointsBasin)
                    .replace(reg2, FieldNames.wellPointsStateAbbr)
                    .replace(reg3, FieldNames.wellPointsCounty))
            }
        }
    }, [currentSubscriptions, loadSubscriptionData]);

    useEffect(() => {
        if (layersLoaded) {
            webmap?.layers
                .filter(layer => { return layer.type === 'feature' })
                .map(layer => {
                    let featLayer = layer as FeatureLayer;
                    const isPoint = featLayer.title === LayerIds.WellData
                    if (isPoint || featLayer.title === LayerIds.LateralLines) {
                        featLayer.definitionExpression = wellDataQuery
                        if (isPoint) {
                            //@ts-ignore
                            featLayer.renderer = Renderers.wellsByTypeRendererPlatform
                            const pLayer = findLayerByTitle(LayerIds.WellsDataGraphics) as FeatureLayer;
                            pLayer.renderer = featLayer.renderer;
                            pLayer.minScale = featLayer.minScale;
                            pLayer.maxScale = featLayer.maxScale;
                            //ENE-500 remove popup
                            // pLayer.popupTemplate = featLayer.popupTemplate;
                            // pLayer.popupEnabled = true;
                            featLayer.visible = false;
                        } else {
                            const lLayer = findLayerByTitle(LayerIds.WellLineGraphics) as FeatureLayer;
                            lLayer.renderer = featLayer.renderer;
                            lLayer.minScale = featLayer.minScale;
                            lLayer.maxScale = featLayer.maxScale;
                            //ENE-500 remove popup
                            // lLayer.popupTemplate = featLayer.popupTemplate;
                            // lLayer.popupEnabled = true;
                        }
                    } else {
                        if (featLayer.title !== LayerIds.WellsDataGraphics && featLayer.title !== LayerIds.CensusCountyLayer) {
                            featLayer.definitionExpression = subscriptionQuery
                        }
                    }
                    if (featLayer.title === LayerIds.RigsData) {
                        //@ts-ignore
                        featLayer.renderer = Renderers.rigPointsRenderer;
                    }
                    return featLayer;
                });
        }
    }, [layersLoaded, subscriptionQuery]);
    function wktToEsriPolygon(wkt: string) {
        const coords = wkt.replace("POLYGON((", "").replace("))", "").split(",");
        const rings = coords.map((coord) => {
            const [x, y] = coord.trim().split(" ").map(Number);
            return [x, y];
        });
        return new Polygon({
            rings: [rings],
            spatialReference: { wkid: 4326 }
        });
    }
    const getUpdatedHeatmapRenderer = useCallback(async (layer: FeatureLayer) => {
        const colorTheme = colorSchemes.getSchemeByName({
            basemapTheme: currentBasemapType,
            geometryType: "polygon",
            theme: "high-to-low",
            name: currentBasemapType === "dark" ? "Blue 21" : "Blue 28"
        });
        layer.definitionExpression = "1=1";
        layer.opacity = 1;
        const renderParams = {
            layer,
            field: FieldNames.heatmapGridStatsTotalWells,
            defaultSymbolEnabled: false,
            colorScheme: colorTheme,
        } as any;
        const res = await colorRendererCreator.createContinuousRenderer(renderParams);
        return res.renderer;
    }, [currentBasemapType])

    const getRigsRenderer = useCallback((isWellsLayerShown: boolean) => {
        if (currentBasemapType === "light" || !isWellsLayerShown) {
            return Renderers.rigPointsRenderer as any;
        }
        return Renderers.rigPointsRendererWhite as any;
    }, [currentBasemapType])

    const getWellLinesRenderer = useCallback(() => {
        if (currentBasemapType === "light") {
            return {
                type: "simple",
                symbol: {
                    type: "simple-line",
                    color: [0, 0, 0, 255],
                    width: 1,
                }
            } as any;
        }
        return {
            type: "simple",
            symbol: {
                type: "simple-line",
                color: [103, 205, 207, 255],
                width: 1,
            }
        } as any;
    }, [currentBasemapType])

    useEffect(() => {
        if (!layersLoaded || !mapview) {
            return;
        }
        reactiveUtils.watch(
            () => mapview.stationary,
            (stationary) => {
                if (stationary === true && webmap) {
                    const wellsLayer = findLayerByTitle(LayerIds.WellData) as FeatureLayer;
                    const rigsLayer = findLayerByTitle(LayerIds.RigsData) as FeatureLayer;
                    const isWellsLayerShown = wellsLayer && mapview.scale <= wellsLayer.minScale && mapview.extent !== null;
                    if (rigsLayer) {
                        rigsLayer.renderer = getRigsRenderer(isWellsLayerShown);
                        rigsLayer.opacity = 1;
                    }
                    if (isWellsLayerShown) {
                        if (sketch) sketch.visible = true;
                        const outSpatialReference = new SpatialReference({
                            wkid: 4326
                        });
                        try {
                            const wgs84Extent: Extent = projection.project(mapview.extent, outSpatialReference) as Extent;
                            const tiles = [0];
                            const smallerExtents = tiles.map((num) => {
                                const xminAdj = 0;
                                const yminAdj = 0;
                                const xmaxAdj = 0;
                                const ymaxAdj = 0;
                                return {
                                    tile: num,
                                    spatialReference: { wkid: 4326 },
                                    xmin: wgs84Extent.xmin + xminAdj,
                                    ymin: wgs84Extent.ymin + yminAdj,
                                    xmax: wgs84Extent.xmax - xmaxAdj,
                                    ymax: wgs84Extent.ymax - ymaxAdj
                                }
                            })
                            setAWSSmallerExtents((prevState) => {
                                if (inExtent(smallerExtents?.[0], prevState?.[0])) {
                                    return prevState;
                                }
                                const e: IAWSEXtent = getBufferedExtent(smallerExtents?.[0]);
                                //setPrevExtent(prevState?.[0]);
                                return [e];
                            });
                        } catch (ex) {
                            console.warn('Map error, unable to obtain current wgs84 extent');
                        }
                    } else {
                        if (sketch) sketch.visible = false;
                        setAWSSmallerExtents([]);
                    }
                }
            }
        )
        setPreviousColorByColors({});
    }, [currentBasemapType, layersLoaded, getRigsRenderer]);

    useEffect(() => {
        if (!mapview) return;
        if (mapSet && heatmapGridTotals && heatmapGridTotals.length > 0) {

            function getHeatmapGraphicsFromHeatmapGridTotals() {
                return heatmapGridTotals.map((g, i) => {
                    const polygon = wktToEsriPolygon(g.gemetry);
                    return new Graphic({
                        geometry: polygon,
                        attributes: {
                            ...g,
                            "ObjectID": i
                        }
                    });
                });
            }

            async function addInitialHeatmapLayer() {
                const graphics = getHeatmapGraphicsFromHeatmapGridTotals();
                const _layer = BaseLayers.getGridStatsGraphicsLayer(graphics);
                _layer.visible = heatmapEnabled;
                const _renderer = await getUpdatedHeatmapRenderer(_layer);
                _layer.renderer = _renderer
                webmap.add(_layer, 0);
                _layer.queryExtent().then((response: { extent: Extent }) => {
                    if (response && response.extent) {
                        mapview.when(() => {
                            mapZoomToTarget(response.extent.expand(1.1));
                        });
                    }
                });
                setHeatmapLoaded(true);
            }

            async function updateHeatmapLayer() {
                const heatmapLayer = findLayerByTitle(LayerIds.GridStatsGraphics) as FeatureLayer;
                const graphics = getHeatmapGraphicsFromHeatmapGridTotals();
                const _newLayer = BaseLayers.getGridStatsGraphicsLayer(graphics);
                const _renderer = await getUpdatedHeatmapRenderer(_newLayer);
                _newLayer.visible = heatmapEnabled;
                _newLayer.renderer = _renderer;

                updateMapGraphicLayer(
                    webmap,
                    _newLayer,
                    heatmapLayer,
                    _newLayer
                );

                if (graphics.length === 1) {
                    setTimeout(() => {
                        setZoomToWells(true);
                    }, 500);
                } else {
                    if (currentSelectedAoi === 0 && wellsAndRigs.selectedWellRigOrPermitUIDList.length === 0) {
                        setTimeout(() => {
                            _newLayer.queryExtent().then((response: { extent: Extent }) => {
                                if (response && response.extent) {
                                    mapview.when(() => {
                                        mapZoomToTarget(response.extent.expand(1.1));
                                    });
                                }
                            });
                        }, 500);
                    } else {
                        setZoomToWells(true);
                    }
                }
            }
            const heatmapLayer = findLayerByTitle(LayerIds.GridStatsGraphics) as FeatureLayer;
            if (!heatmapLoaded || !heatmapLayer) {
                addInitialHeatmapLayer();
            } else {
                updateHeatmapLayer();
            }
        }
    }, [heatmapGridTotals, mapSet])

    useEffect(() => {
        if (mapLoading || !layersLoaded) return;
        const _layer = findLayerByTitle(LayerIds.GridStatsGraphics) as FeatureLayer;
        if (!_layer) return;
        (async () => {
            try {
                
                // update heatmap renderer base on theme
                const _renderer = await getUpdatedHeatmapRenderer(_layer);
                _layer.renderer = _renderer;
            } catch (error) {
                console.log("Error updating heatmap renderer", error);
            }
        })();
    }, [currentBasemapType, getUpdatedHeatmapRenderer, mapLoading, layersLoaded])

    useEffect(() => {
        // update well lines renderer to black
        const wellLinesGraphicsLayer = findLayerByTitle(LayerIds.WellLineGraphics) as FeatureLayer;
        if (!wellLinesGraphicsLayer || colorByField !== "well_status") return;
        wellLinesGraphicsLayer.renderer = getWellLinesRenderer();
    }, [currentBasemapType, colorByField, getWellLinesRenderer])

    useEffect(() => {
        if (heatmapGridTotals && layersLoaded && heatmapLoaded) {

        }
    }, [heatmapGridTotals, layersLoaded, heatmapLoaded])

    useEffect(() => {
        if (wellsDataList !== null && zoomToWells && !wellsAndRigs.showTableLoader && config.hasOwnProperty("mapConfig") &&
            (!wellsAndRigs.selectedRowId || wellsAndRigs.selectedRowId.toString().length === 0) && wellsAndRigs.selectedWellRigOrPermitUIDList.length === 0) {
            setZoomToWells(false);
            let mp: any = {
                type: 'multipoint',
                spatialReference: { wkid: 4326 }
            };
            wellsDataList.forEach((w) => {
                if (!mp.points) {
                    mp.points = []
                }
                mp.points.push([w.longitude, w.latitude]);
            });
            const mpGeometry = Multipoint.fromJSON(mp);
            if (mpGeometry) {
                setTimeout(() => {
                    if (mpGeometry.extent && mpGeometry.extent !== null) {
                        if (mpGeometry.extent.height == 0) {
                            //@ts-ignore
                            const { mapConfig } = config;
                            mapview.when(() => {
                                mapZoomToTarget({
                                    target: mpGeometry,
                                    zoom: mapConfig.highlightZoom
                                });
                            });
                        } else {
                            mapview.when(() => {
                                mapZoomToTarget(mpGeometry.extent.expand(2.5));
                            });
                        }
                    }
                }, 500);
            }
        }
    }, [wellsAndRigs.showTableLoader, wellsDataList, zoomToWells]);

    const addLayerEffectForSelectedFeatures = (currentWellIds: string[] | undefined) => {
        // there was a bug where the AOI selection on the map would only add to the checked list and not
        // reset it to just selected wells in the AOI.  This fixes that
        if (wellsAndRigs.tabIndex === 0) {
            if (wellsDataList && JSON.stringify([...new Set(currentWellIds)]) !== JSON.stringify([...new Set(wellsAndRigs.selectedWellRigOrPermitUIDList)])) {
                setCheckandSetFeatures(currentWellIds);
            }
        }

        if (currentWellIds && currentWellIds?.length > 0 && currentWellIds?.length === wellsDataList?.length) {
            return
        }
        if (!getDontGrayOutOtherWells()) {
            setLayerEffectFilter(new FeatureEffect({
                //@ts-ignore
                filter: { where: "uid IN ('" + currentWellIds.join("','") + "')" },
                excludedEffect: excludedEffect
            }), LayerIds.WellsDataGraphics);
            setLayerEffectFilter(new FeatureEffect({
                //@ts-ignore
                filter: { where: "uid IN ('" + currentWellIds.join("','") + "')" },
                excludedEffect: excludedEffect
            }), LayerIds.WellLineGraphics);
        }

        // setLayerEffectFilter(new FeatureEffect({
        //     //@ts-ignore
        //     filter: { where: "uid IN ('" + currentWellIds.join("','") + "')" },
        //     excludedEffect: excludedEffect
        // }), LayerIds.RigsData);
    }

    const addLayerEffectForSelectedAoi = (aoi: Graphic) => {
        setLayerEffectFilter(new FeatureEffect({
            filter: {
                geometry: aoi.geometry,
                spatialRelationship: "intersects"
            },
            excludedEffect: excludedEffect
        }), LayerIds.WellLineGraphics);
        const query =
        {
            //query object
            geometry: aoi.geometry,
            spatialRelationship: "intersects",
            returnGeometry: false,
            outFields: ["uid"]
        }
        queryForUniqueWellIds(query).then((wellIds) => {
            setLayerEffectFilter(new FeatureEffect({
                filter: {
                    where: wellIds.length > 0 ? `uid in ('${wellIds.join("','")}')` : "",
                },
                excludedEffect: excludedEffect
            }), LayerIds.WellsDataGraphics);
        });
    }

    useEffect(() => {
        if (checkAndSetFeatures) {
            if (JSON.stringify(checkAndSetFeatures) !== JSON.stringify(wellsAndRigs.selectedWellRigOrPermitUIDList)) {
                dispatch(handleSelectedWellRigOrPermitUIDList(checkAndSetFeatures));
                // we run handle rearrange to move the map-clicked wells to the top of the table
                dispatch(handleRearrange())
            }
            setCheckandSetFeatures(undefined);
        }
    }, [checkAndSetFeatures])

    const getAoi = (aoi_id: number) => {
        if (aoi_id > 0) {
            const aoiLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoIData);
            if (aoiLayer) {
                const aoi = aoiLayer.graphics.find((g: Graphic) => g.getAttribute('id') === wellsAndRigs.selectedAoiData.aoi_id);
                if (aoi) {
                    return aoi;
                }
            }
        }
        return;
    }

    const getBufferedExtent = (extent: IAWSEXtent) => {
        return {
            tile: extent.tile,
            xmin: extent.xmin - 0.1,
            ymin: extent.ymin - 0.1,
            xmax: extent.xmax + 0.1,
            ymax: extent.ymax + 0.1,
            spatialReference: { wkid: extent.spatialReference.wkid }
        }
    }

    const getCurrentRenderers = (values: any) => {
        const allFilterValues = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos

        const prodType = allFilterValues.filter((opt) => values['production_type'] &&
            values['production_type'].find((v: string) => v.toLowerCase() == opt.label.toLowerCase())).map((opt) => opt.value);

        const drillType = allFilterValues.filter((opt) => values['drill_type'] &&
            values['drill_type'].find((v: string) => v.toLowerCase().indexOf(opt.label.toLowerCase()) > -1)).map((opt) => opt.value);

        const wellTypes = allFilterValues.filter((opt) => values['well_type'] &&
            values['well_type'].find((v: string) => v.toLowerCase().indexOf(opt.label.toLowerCase()) > -1)).map((opt) => opt.value);

        if (values['well_type'] && values['well_type'].includes('production')) {
            if (prodType.includes(1)) wellTypes.push(1)
            if (prodType.includes(2)) wellTypes.push(2)
            if (prodType.includes(3)) wellTypes.push(3)
        }

        const wellStatus = allFilterValues.filter((opt) => values['well_status'] &&
            values['well_status'].find((v: string) => {
                if (v.toLowerCase() === opt.label.toLowerCase())
                    return true;
                else {
                    if (opt.label.toLowerCase().indexOf(v.toLowerCase()) > -1) {
                        const remain = opt.label.toLowerCase().replace(v.toLowerCase(), "").trim();
                        return (values['production_type'] && values['production_type'].find((p: string) =>
                            p.toLowerCase() == remain.toLowerCase().replace("/", "_")))
                    }
                    if ((v.toLowerCase()) == 'inactive' && opt.label.toLowerCase().indexOf('shut-in') > -1) {
                        const remain = opt.label.toLowerCase().replace("shut-in", "").trim();
                        return (values['production_type'] && values['production_type'].find((p: string) =>
                            p.toLowerCase() == remain.toLowerCase().replace("/", "_")))
                    }
                    return false;
                }
            })
        ).map((opt) => opt.value);
        const allTypes = [...wellTypes, ...drillType, ...wellStatus];
        if (allTypes.length > 0) {
            return [...allTypes, -100];
        }
        return Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value);
    }

    const handleMapClick: any = (event: { preventDefault: () => void; }, panelFilters: IFilter, selectedWellRigOrPermitUIDList: any) => {
        const wlayer = findLayerByTitle(LayerIds.WellsDataGraphics) as FeatureLayer;
        const layerToQuery = mapview.scale > wlayer.minScale ? LayerIds.CountyStatsGraphic : LayerIds.WellsDataGraphics;
        const layer = findLayerByTitle(layerToQuery) as FeatureLayer;
        queryFeatures(layer, event, "", [...highlights], panelFilters, wellsAndRigs.selectedWellRigOrPermitUIDList);
        event.preventDefault();
    }

    const inExtent = (extent: IAWSEXtent, overallExtent: any): boolean => {
        return !!extent && !!overallExtent && (extent.xmin >= overallExtent.xmin &&
            extent.ymin >= overallExtent.ymin &&
            extent.xmax <= overallExtent.xmax &&
            extent.ymax <= overallExtent.ymax);
    }

    const mapZoomToTarget = (target: any, attempt?: number | undefined) => {
        try {
            if (!mapview || !mapview.goTo) {
                console.warn('mapview is not fully initialized.');
                return;
            }

            // wells are shown, don't zoom out too far
            if (mapview.scale <= 100000) {
                // If the target is a point, zoom to it
                if (target?.target?.type === "point") {
                    mapview.goTo(target);
                    return;
                }
                return;
            }

            mapview.goTo(target);
        } catch (ex: any) {
            console.log('the exception and attemp are ', ex, attempt)
            if (!attempt) {
                setTimeout(() => mapZoomToTarget(target, 1), 500);
            }
        }
    }

    const queryFeatures = (layer: any,
        screenPoint: any,
        clause: string = "",
        tmpHighlights: IHighlight[],
        panelFilters: IFilter,
        selectedWellRigOrPermitUIDList: any
    ) => {
        if (!layer || layer.type != 'feature') return;
        const clauseQuery = clause.length > 0;
        let distance = 100;
        if (mapview.scale < 75000) {
            distance = 50;
        }
        if (mapview.scale < 40000) {
            distance = 15;
        }
        if (mapview.scale < 10000) {
            distance = 5;
        }
        const point = screenPoint !== null ? mapview.toMap(screenPoint) : null;
        const query = (!clauseQuery) ?
            {
                //query object
                geometry: point,
                spatialRelationship: "intersects",
                returnGeometry: false,
                outFields: ["*"],
                distance: distance,
                units: "esriSRUnit_Meter"
            } :
            {
                //query object
                where: clause,
                returnGeometry: false,
                outFields: ["*"]
            }
        const queries = [layer.queryFeatures(query)];
        if (layer.title === LayerIds.WellsDataGraphics) {
            const lLayer: any = findLayerByTitle(LayerIds.WellLineGraphics) as FeatureLayer;
            queries.push(lLayer.queryFeatures(query));

            const wLayer: any = findLayerByTitle(LayerIds.WellsDataGraphics) as FeatureLayer;
            queries.push(wLayer.queryFeatures(query));

            //queries.push(rLayer.queryFeatures(rigQuery));
        }

        Promise.all(queries)
            .then((results) => {
                let allFeatures: any[] = [];
                results.forEach((featureSet: { features: any[]; }) => {
                    allFeatures = [...allFeatures, ...featureSet.features]
                });

                if (allFeatures.length === 0) {
                    setCheckandSetFeatures([]);
                    const aoiLayer: any = findLayerByTitle(LayerIds.AoIData) as FeatureLayer;
                    let foundAoi: boolean = false;
                    if (aoiLayer && point != null) {
                        let outSpatialReference = new SpatialReference({
                            wkid: 4326 //Sphere_Sinusoidal projection
                        });
                        const tmpPointWGS84 = projection.project(point, outSpatialReference) as __esri.Geometry;
                        aoiLayer.graphics.forEach((aoi: Graphic) => {
                            const graphicInPoint = geometryEngine.within(tmpPointWGS84, aoi.geometry);
                            if (graphicInPoint) {
                                foundAoi = true;
                                const geoJson = arcgisToGeoJSON(aoi.geometry)
                                const wkt = geojsonToWKT(geoJson)
                                dispatch(
                                    fetchAoiStats(access_token, { ...panelFilters, geometry: wkt })
                                ).then((result: any) => {
                                    const tempAoi = new Graphic({
                                        attributes: { name: aoi.attributes.name },
                                        geometry: aoi.geometry,
                                        popupTemplate: aoiDataTemplate
                                    });
                                    tempAoi.attributes = { ...tempAoi.attributes, ...result.data };
                                    mapview.openPopup({ features: [tempAoi] });
                                    updateAoiSelection(aoi);
                                    addLayerEffectForSelectedAoi(aoi);
                                    setTimeout(() => { mapview.popup.reposition() }, 500);
                                });
                            }
                            if (!foundAoi) {
                                setCheckandSetFeatures([])
                                dispatch(handleSelectedAoiData({ aoi_id: 0 }))
                            }
                        });
                    }
                    return;
                }
                //ENE-500 Remove popup
                if ([LayerIds.WellsDataGraphics, LayerIds.WellLineGraphics].includes(layer.title)) {
                    const wellIds: any = allFeatures.map((f) => { return f.attributes.uid; });
                    dispatch(handleTabIndex(0));
                    setCheckandSetFeatures([...new Set([...wellIds])]);
                } else {
                    if ([LayerIds.CountyStatsGraphic].includes(layer.title) && point !== null) {
                        mapview.openPopup({ features: allFeatures, location: point });
                    }
                }
            });
    }

    const queryForUniqueWellIds = (query: any, filterUniqueWellAPI: boolean = false): Promise<string[]> => {
        if (query.outFields && !query.outFields.includes(["*"])) {
            query.outFields = [...new Set([...query.outFields, "uid", "well_api"])];
        }
        const wellIdsPromise: Promise<string[]> = new Promise((resolve) => {
            const lLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics);
            const wLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics);
            Promise.all([lLayer.queryFeatures(query), wLayer.queryFeatures(query)])
                .then((results) => {
                    const wellInfo: { keys: string[], data: any } = { keys: [], data: {} };
                    results.forEach((featureSet) => {
                        featureSet.features.forEach((f: { attributes: any }, i: number) => {
                            let well_api = f.attributes.Well_API
                            if (wellInfo.keys.includes(well_api) && !filterUniqueWellAPI) {
                                well_api = well_api + i
                            }
                            wellInfo.keys.push(well_api);
                            wellInfo.data[well_api] = f.attributes.uid;
                        })
                    });
                    const wellIds: string[] = [...new Set([...wellInfo.keys])].map((key) => {
                        return wellInfo.data[key];
                    })
                    resolve([...new Set([...wellIds])]);
                });
        });
        return wellIdsPromise;
    }

    const resetEffectedWells = () => {
        if (layersLoaded) {
            setLayerEffectFilter(new FeatureEffect(), LayerIds.WellsDataGraphics);
            setLayerEffectFilter(new FeatureEffect(), LayerIds.WellLineGraphics);
            //setLayerEffectFilter(new FeatureEffect(), LayerIds.RigsData);
        }
    }

    const selectFeatures = (selectedFeature: string, zoom: boolean, showPopup: boolean) => {
        const query =
        {
            //query object
            where: "",
            returnGeometry: true,
            outFields: [FieldNames.wellPointsUID, FieldNames.listingWellPointsTypeId, FieldNames.wellPointsApi],
        }
        const wLayer: any = findLayerByTitle(LayerIds.WellData) as FeatureLayer;
        //const rLayer:any = findLayerByTitle(LayerIds.RigsData) as FeatureLayer;
        //Well Id is a float, query requires the .0 to work properly as well Id returns as an int from the api
        const wellClause = "uid = '" + wellsAndRigs.selectedRowId + "'";

        query.where = wellClause;
        dispatch(showSiteLoader());
        Promise.all([wLayer.queryFeatures(query)])//,rLayer.queryFeatures(query)])
            .then((results: any) => {
                results.forEach((featureSet: { features: [any] }) => {
                    if (config && config.hasOwnProperty("mapConfig")) {
                        //@ts-ignore
                        const { mapConfig } = config;
                        if (zoom) {
                            mapview.when(() => {
                                if (featureSet && featureSet?.features[0] && featureSet?.features[0]?.geometry) {
                                    mapZoomToTarget({
                                        target: featureSet.features[0].geometry,
                                        zoom: mapConfig.highlightZoom
                                    });
                                }
                            });
                        }
                    }

                    dispatch(hideSiteLoader());
                })
            })
            .catch(() => {
                dispatch(hideSiteLoader());
            });
    }

    const setLayerEffectFilter = (filter: FeatureEffect, layerTitle: string) => {
        if (webmap && webmap?.layers) {
            const wLayer: any = findLayerByTitle(layerTitle) as FeatureLayer;
            if (wLayer) {
                (wLayer as __esri.FeatureLayer).featureEffect = filter;
            }
        }
    }

    const updateAoiSelection = (aoi: Graphic) => {
        const query = {
            geometry: aoi.geometry,
            spatialRelationship: "intersects",
            returnGeometry: false,
            outFields: ["well_api", "uid"],
        }
        queryForUniqueWellIds(query, true).then((wellIds) => {
            setCheckandSetFeatures(wellIds);
        })
            .finally(() => {
                setMapLoading((prevState) => {
                    if (!heatmapLoaded || wellsAndRigs.showTableLoader || graphicLayersLoading) return prevState;
                    return false;
                });
            })
    }
    function svgToDataURL(svgString: string) {
        // Encode the SVG string
        const encodedSVG = encodeURIComponent(svgString)
            .replace(/'/g, '%27')
            .replace(/"/g, '%22');

        // Create the Data URI
        const dataURL = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;

        return dataURL;
    }

    async function loadSvgString(url: string) {
        try {
            const response = await fetch(url);
            const svgText = await response.text();
            return svgText;
        } catch (error) {
            console.error("Error loading SVG:", error);
        }
    }

    function getSVGDataUrlByColor(svgText: string, newFillColor: string) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgElement = svgDoc.querySelector("svg") as SVGElement;
        const paths = svgElement.querySelectorAll("path");
        paths.forEach(path => path.setAttribute("fill", newFillColor));
        const encodedSVG = encodeURIComponent(svgElement.outerHTML).replace(/'/g, '%27').replace(/"/g, '%22');
        const dataURL = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
        return dataURL;
    }

    const svgCache = new Map<string, string>();
    async function loadAndModifySVG(url: string, newFillColor: string) {
        try {
            let svgText = svgCache.get(url);
            if (!svgText) {
                const response = await fetch(url);
                svgText = await response.text();
                svgCache.set(url, svgText);
            }
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
            const svgElement = svgDoc.querySelector("svg") as SVGElement;
            const paths = svgElement.querySelectorAll("path");
            paths.forEach(path => path.setAttribute("fill", newFillColor));
            // return svgToDataURL(svgElement.outerHTML);
            const encodedSVG = encodeURIComponent(svgElement.outerHTML).replace(/'/g, '%27').replace(/"/g, '%22');
            const dataURL = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
            return dataURL;
        } catch (error) {
            console.error("Error loading or modifying SVG:", error);
        }
    }

    const getColorByPointRenderer = async (colorByCategories: IColorByItem[]) => {
        const _colorByCategories = [...colorByCategories, { name: "N/A", color: "gray" }];
        const renderer = Renderers.wellsByTypeRendererPlatform;
        const iconsByCategory = [] as any;
        for (let i = 0; i < renderer.uniqueValueInfos.length; i++) {
            const info = renderer.uniqueValueInfos[i];
            const iconUrl = info.symbol.url;
            const value = info.value;
            const symbol = info.symbol;
            const svgString = await loadSvgString(iconUrl);
            iconsByCategory.push({ value, symbol, svgString });
        }

        const newInfos = [] as any[];
        for (let i = 0; i < iconsByCategory.length; i++) {
            const info = iconsByCategory[i];
            for (let j = 0; j < _colorByCategories.length; j++) {
                const category = _colorByCategories[j];
                const svgUrl = getSVGDataUrlByColor(info.svgString, category.color);
                const newInfo = {
                    value: `${info.value},${category.name}`,
                    symbol: {
                        ...info.symbol,
                        type: "picture-marker",
                        url: svgUrl
                    }
                };
                newInfos.push(newInfo);
            }
        }
        const newRenderer = {
            type: "unique-value",
            field: "parsed_status_id",
            field2: colorByField,
            fieldDelimiter: ",",
            uniqueValueInfos: newInfos,
        } as any;
        return newRenderer;
    }

    const getColorByLineRenderer = (colorByCategories: IColorByItem[]) => {
        const _colorByCategories = [...colorByCategories, { name: "N/A", color: "gray" }];
        const uvis = _colorByCategories.map(
            (category: any, index: number) => {
                return {
                    value: category.name,
                    symbol: {
                        type: "simple-line",
                        color: category.color,
                        width: 1
                    }
                };
            }
        );
        const newLineRenderer = {
            type: "unique-value",
            field: colorByField,
            uniqueValueInfos: uvis,
            defaultSymbol: {
                type: "simple-line",
                color: "gray",
                width: 1
            }
        } as any;
        return newLineRenderer;
    }


    const updateWellGraphics = async (features: Graphic[], lineFeatures: Graphic[], colorByCategories: IColorByItem[]) => {
        const wellPointsLayer = findLayerByTitle(LayerIds.WellData) as FeatureLayer;
        const graphicsLayer: FeatureLayer = BaseLayers.getWellsGraphicsLayer(features);
        const oldGraphicsLayer = findLayerByTitle(LayerIds.WellsDataGraphics) as FeatureLayer;

        if (colorByCategories && colorByCategories.length > 0) {
            wellPointsLayer.renderer = await getColorByPointRenderer(colorByCategories);
        } else {
            wellPointsLayer.renderer =
                Renderers.wellsByTypeRendererPlatform as any;
        }

        updateMapGraphicLayer(
            webmap,
            graphicsLayer,
            oldGraphicsLayer,
            wellPointsLayer
        );

        graphicsLayer.visible = wellsEnabled;

        const wellLinesGraphicsLayer =
            BaseLayers.getLinesGraphicsLayer(lineFeatures);
        const wellLinesLayer = findLayerByTitle(LayerIds.LateralLines) as FeatureLayer;
        const oldLineGraphicsLayer = findLayerByTitle(LayerIds.WellLineGraphics) as FeatureLayer;
        if (colorByCategories && colorByCategories.length > 0) {
            wellLinesLayer.renderer = getColorByLineRenderer(colorByCategories);
        } else {
            wellLinesLayer.renderer = getWellLinesRenderer();
        }

        updateMapGraphicLayer(
            webmap,
            wellLinesGraphicsLayer,
            oldLineGraphicsLayer,
            wellLinesLayer
        );

        wellLinesGraphicsLayer.visible = wellsEnabled;

        if (selectedHighlight !== null) {
            setSelectedHighlight(null);
        }

        setGraphicLayersLoading(false);
    }

    if (config === null) {
        return <div className={`aoiMapDiv ${wellsAndRigs.showTable ? '' : 'isHide'} `} ref={aoiMapDiv}></div>;
    }

    const saveAsAOI = {
        title: 'Save as AOI',
        id: 'aoi',
        className: 'esri-icon-map-pin',
        scale: 'm',
        style: 'font-size: 15px!important;'
    }

    const keepDrawing = {
        title: 'Keep Drawing',
        id: 'keep-drawing',
        className: 'esri-icon-polygon',
        scale: 'm',
        style: 'font-size: 15px!important;'
    }

    const saveAsProjects = {
        title: 'Add to Projects',
        id: 'projects',
        className: 'esri-icon-collection'
    }

    const aoiDataTemplate = {
        content: [{ type: "fields" }],
        fieldInfos: [
            {
                fieldName: "well_count",
                visible: true,
                label: "Active Wells",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "permit_count",
                visible: true,
                label: "Active Permits",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "operator_count",
                visible: true,
                label: "DUCs",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "completion_count",
                visible: true,
                label: "Other",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },

        ],
        title: "{name}"
    }

    const confirmDataTemplate = {
        actions: [saveAsAOI, keepDrawing],
        content: [{ type: "fields" }],
        fieldInfos: [
            {
                fieldName: "well_count",
                visible: true,
                label: "Active Wells",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "permit_count",
                visible: true,
                label: "Active Permits",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "operator_count",
                visible: true,
                label: "DUCs",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "completion_count",
                visible: true,
                label: "Other",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },

        ],
        title: "{title}",
        overwriteActions: true
    }

    const tmpConfig: IBaseMapConfig = config as IBaseMapConfig;
    return (
        <div>
            {mapLoading ? <BouncingDotsLoader /> : <div />}
            <div className={`aoiMapDiv ${wellsAndRigs.showTable ? '' : 'isHide'} `} ref={aoiMapDiv} />
            <div ref={filterPanelRef}>
                {mapKeyVisible && layersLoaded && mapHasLoadedOnce && <FilterPanel useFilter={false} useLegend={true} />}
            </div>
        </div>);
}

const BouncingDotsLoader = (props: any) => {
    return (
        <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default AoIMap;

