import { useState, useEffect, useRef, Children } from "react";
import { getSingleWellData, setRapidAPIMessage, setRapidAPIFromInput, setRapidAPIOnBlur } from "../store/actions/wells-rigs-action"
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { toast } from "react-toastify";
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { logUserAction } from "../store/actions/auth-actions";
import { actionType } from "../../utils/helper";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { OptionType } from "../models/submit-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RapidAPIFilter = ({ children, value, resetFilter }: { children: React.ReactNode; value: string, resetFilter: (rapid_api?: string) => void }) => {
    const dispatch = useAppDispatch();

    // const wellsAndRigs = useAppSelector(state => state.wellsAndRigs);

    // const [oldApiFromInput, setOldApiFromInput] = useState<string>('');
    // const [localApiFromInput, setLocalApiFromInput] = useState<string>('');
    // const [errorText, setErrorText] = useState<string>('');

    // const getSelectedWellAPI = () => {

    //     if (wellsAndRigs.selectedWellRigOrPermitList && wellsAndRigs.selectedWellRigOrPermitList.length === 1 && isInstanceOfWellsAndPermitsObject(wellsAndRigs.selectedWellRigOrPermitList[0])) {
    //         return wellsAndRigs.selectedWellRigOrPermitList[0].well_api
    //     }

    // }

    // const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    // const setDebouncedState = (newValue: string) => {
    //     // Clear the previous timeout if it exists
    //     if (timeoutRef.current) {
    //         clearTimeout(timeoutRef.current);
    //     }

    //     // Set a new timeout to update the state
    //     timeoutRef.current = setTimeout(() => {
    //         dispatch(setRapidAPIFromInput(newValue.trim()))
    //         timeoutRef.current = null;
    //     }, 5000);
    // };


    // useEffect(() => {
    //     // if we set the selected row id to the api from input,
    //     // and then clear or change that selected row id, then the
    //     // text should get cleared out from the input form
    //     const wellAPI = getSelectedWellAPI()
    //     if (wellAPI && wellAPI === wellsAndRigs.rapidAPIFromInput && wellAPI != oldApiFromInput) {
    //         setOldApiFromInput(wellsAndRigs.rapidAPIFromInput)
    //     } else if ((!wellAPI || wellAPI !== wellsAndRigs.rapidAPIFromInput) && oldApiFromInput) {
    //         const elem = document.getElementById("rapid_api") as HTMLInputElement;
    //         if (elem && elem.value) {
    //             elem.value = '';
    //         }
    //         setOldApiFromInput('')
    //     }
    // }, [wellsAndRigs.selectedWellRigOrPermitList])

    // useEffect(() => {
    //     if (wellsAndRigs.rapidAPIMessage && wellsAndRigs.rapidAPIMessage !== "success" && wellsAndRigs.rapidAPIMessage !== "loading") {
    //         toast.error(wellsAndRigs.rapidAPIMessage)
    //         setErrorText("")
    //         setDebouncedState("")
    //         setLocalApiFromInput("")
    //         setOldApiFromInput("")
    //         dispatch(setRapidAPIMessage(""))
    //     } else if (wellsAndRigs.rapidAPIMessage === "success") {
    //         dispatch(setRapidAPIMessage(""))
    //     }
    // }, [wellsAndRigs.rapidAPIMessage])

    // const handleRapidAPIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { value } = e.target;
    //     setDebouncedState(value)
    //     setLocalApiFromInput(value.trim())
    //     if (!value || value.length == 0) {
    //         setErrorText("")
    //     }
    //     handleValidation(true)

    // };

    // const handleValidation = (onlyRemove: boolean) => {
    //     if (localApiFromInput && localApiFromInput.length > 0 && isNaN(parseInt(localApiFromInput))) {
    //         if (!onlyRemove) {
    //             setErrorText("API's must be a number")
    //         }
    //         return false
    //     } else if (localApiFromInput && localApiFromInput.length < 10 && localApiFromInput.length > 0) {
    //         if (!onlyRemove) {
    //             setErrorText("API's should be at least 10 digits long")
    //         }
    //         return false
    //     } else if (localApiFromInput && localApiFromInput.length > 20) {
    //         if (!onlyRemove) {
    //             setErrorText("API's should be less than 20 digits long")
    //         }
    //         return false
    //     } else if (localApiFromInput && localApiFromInput.length > 0) {
    //         setErrorText("")
    //         return true
    //     }
    // }
    const handleSearch = () => {
        if (!value) {
            toast.info("Please select a value to proceed")
            return
        }
        // const validated = handleValidation(false)
        // if (!validated) {
        //     return
        // }
        // if (!errorText && localApiFromInput && localApiFromInput.length > 0 && !wellsAndRigs.rapidAPIMessage) {
        //     dispatch(
        //         logUserAction({
        //             action_type: actionType["rapid_api"],
        //             action_log_detail: `api: ${localApiFromInput}`,
        //         })
        //     );
        //     dispatch(setRapidAPIMessage("loading"))
        resetFilter(value.trim())
        dispatch(setRapidAPIFromInput(value.trim()));
        dispatch(
            logUserAction({
                action_type: actionType["rapid_api"],
                action_log_detail: `api: ${value.trim()}`,
            })
        );
        dispatch(getSingleWellData(value.trim(), true))
        // }
    }
    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.code === 'Enter' ||
            e.code === 'NumpadEnter' ||
            e.key === 'Enter' ||
            e.key === 'NumpadEnter' ||
            e.which === 13 ||
            e.keyCode === 13) {
            e.preventDefault()
            handleSearch();
        }
    };

    return (
        <div>

            <div className="left-row left-padding">
                <img src="images/green-lighting.svg" alt="lighting" className="margin-bottom-point-5" />
                <label htmlFor="">
                    Rapid API Filter
                </label>
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip" className="rapid-tooltip">
                        Enter an API to quickly filter, select, and zoom to any well in your subscription. This action will change all other filters to the default state.
                    </Tooltip>}
                ><span>

                        <i>
                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                        </i>
                    </span>
                </OverlayTrigger>

            </div>
            <div className="rapid-API-filter">
                <div onKeyDown={(e: any) => onKeyDownHandler(e)}>
                    {/* <div className={errorText ? "center-row red-outline" : "center-row"}> */}
                    <div className={"center-row"}>

                        {/* <input
                            className="form-control"
                            placeholder="Enter a single API"
                            name="rapid_api"
                            id="rapid_api"
                            onChange={handleRapidAPIChange}
                            defaultValue={wellsAndRigs.rapidAPIFromInput || ''}
                            onFocus={() => dispatch(setRapidAPIOnBlur(true))}
                            onBlur={() => dispatch(setRapidAPIOnBlur(false))}
                        /> */}
                        {children}
                        <button onClick={(e) => {
                            e.preventDefault()
                            handleSearch()
                        }} className="btn btn-primary btn-input-height">
                            <img src="images/search.svg" className="search-input-icon" />
                        </button>
                    </div>
                </div>
                {/* <span className={errorText ? "rapid-API-error-helper-text" : "rapid-API-error-helper-text-hidden"}>{errorText || "hidden"}</span> */}
            </div >
        </div >

    );
};

export default RapidAPIFilter;
