import React, { useLayoutEffect } from "react";
import withSideNav from "../../HOC/withSideNav";
import {
    GlobalTableProps,
    MemberSettingsViewProps,
} from "../../models/page-props";
import { useEffect, useState } from "react";
import InviteMemberCom from "./InviteMemberCom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { loadInviteMembers, revokeInvitedUser } from "../../store/actions/members-setting-actions";
import GlobalTable from "../../common/GlobalTable";
import useColInviteMembers from "./UseColInviteMembers";
import { debounce } from "lodash";
import { membersSettingsActions } from "../../store/actions/members-setting-actions";
import { toast } from "react-toastify";
import { actionType, ADMIN, ADMIN_CONSTANT } from "../../../utils/helper";
import DeactivateRemoveModal from "./DeactivateRemoveModal";
import Scrollbars from "react-custom-scrollbars";
import { hideCheckOutModal, showCheckOutModal } from "../../store/actions/modal-actions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentModal from "../../common/Modal/PaymentModal";
import { fetchSubscriptionData } from "../../store/actions/subscription-settings-actions";
import { Tooltip } from "react-tooltip";
import { logUserAction } from "../../store/actions/auth-actions";
import DeleteConfirmationModal from "../../common/Modal/DeleteConfirmationModal";
const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const MemberSettingsView = (props: MemberSettingsViewProps) => {
    const dispatch = useAppDispatch();
    const [state, setState] = useState({
        inviteMember: false,
        deactivateRemoveModal: false,
        label: "",
        activeMemberRequired: false,
        userType: "",
        selectedUserId: 0,
        showRevokeModal: false,
        revokeUserId: 0
    });

    const loadSubscriptionData = useAppSelector((state) => state.subscriptionSettings.loadSubscriptionData);
    const checkOutModal = useAppSelector((state) => state.modal.checkOutModal);
    const active_user_count = useAppSelector((state) => state.membersSettings.active_user_count);
    const inviteMembersData = useAppSelector((state) => state.membersSettings.inviteMembersData);
    const inviteMemberDataLoading = useAppSelector((state) => state.membersSettings.inviteMemberDataLoading);
    const invited_user = useAppSelector((state) => state.membersSettings.invited_user);
    const subscriptionData = useAppSelector((state) => state.subscriptionSettings.subscriptionData);
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const access_token = useAppSelector((state) => state.auth.user.access_token);
    const company_configs = useAppSelector((state) => state.auth.user.company_configs);
    const signin_as = useAppSelector((state) => state.auth.user.signin_as);
    const user_id = useAppSelector((state) => state.auth.user.user_id);
    const subscription_status = useAppSelector((state) => state.auth.user.subscription_status);
    const company_id = useAppSelector((state) => state.auth.user.company_data.company_id);
    const userTokenLoading = useAppSelector((state) => state.auth.userTokenLoading);


    const {
        inviteMember,
        deactivateRemoveModal,
        label,
        activeMemberRequired,
        userType,
        selectedUserId,
    } = state;

    const onSearchChange = debounce(() => {
        dispatch(membersSettingsActions.clearInviteMembers());
    }, 500);

    const inviteMemberCancelBtnClick = () => {
        setState((prev) => ({ ...prev, inviteMember: false }));
    };
    const searchRef = React.useRef<HTMLInputElement>(null);
    const onSearchEnterKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            dispatch(membersSettingsActions.clearInviteMembers());
        }
    };
    useEffect(() => {
        if (inviteMemberDataLoading && isAuthenticated && !userTokenLoading) {
            dispatch(
                loadInviteMembers(access_token, {
                    keyword: searchRef?.current?.value || "",
                })
            );
        }
        // eslint-disable-next-line
    }, [inviteMemberDataLoading, isAuthenticated, userTokenLoading]);

    useLayoutEffect(() => {
        if (access_token && loadSubscriptionData) {
            dispatch(fetchSubscriptionData(access_token, inviteMemberDataLoading ? true : false));
        }
    }, [])

    // showModal
    const showModal = (
        label: string,
        userType: string,
        selectedUserId: number
    ) => {
        setState((prev) => ({
            ...prev,
            deactivateRemoveModal: true,
            label,
            ...(userType === ADMIN_CONSTANT &&
                Array.isArray(inviteMembersData) &&
                inviteMembersData.filter(
                    (item) => item.signin_role === ADMIN_CONSTANT
                ).length === 1 && {
                activeMemberRequired: true,
            }),
            userType,
            selectedUserId,
        }));
    };

    // Handleclose Modal
    const handleClose = () => {
        setState((prev) => ({
            ...prev,
            deactivateRemoveModal: false,
            label: "",
            activeMemberRequired: false,
            userType: "",
            selectedUserId: 0,
        }));
    };

    const allowedUser = subscriptionData?.subscription?.allowed_user || 1
    const sliderWidth = (Number(active_user_count) / Number(allowedUser) * 100);
    const onTrashIconClick = (id: number) => {
        setState((prev) => ({ ...prev, revokeUserId: id, showRevokeModal: true }));
    }

    return (
        <div className="settingsWrapper memberSetting">
            <Scrollbars
                className='settingsWrapper-scroll'
                autoHeightMin={0}
                renderThumbVertical={props => < div {...props} className="thumb-vertical" />}
                renderTrackVertical={props => < div {...props} className="track-vertical" />}
            >
                <div className="settingWrapperInner">
                    <div className="item">
                        <h3>Member Settings</h3>
                        <p>Invite new team members and manage member roles</p>
                        {inviteMember && (
                            <InviteMemberCom
                                inviteMemberCancelBtnClick={inviteMemberCancelBtnClick}
                                allowed_sub_user={allowedUser}
                            />
                        )}
                        <div className="table-header">
                            <div className="search-form">
                                <input
                                    ref={searchRef}
                                    type="search"
                                    className="form-control"
                                    placeholder="Search by name or email"
                                    onChange={onSearchChange}
                                    onKeyDown={onSearchEnterKeyPress}
                                />
                            </div>
                            {/* {signin_as === ADMIN && (
                                <button
                                    type="button"
                                    className={
                                        inviteMember
                                            ? "btn btn-disabled"
                                            : "btn btn-primary"
                                    }
                                    onClick={() => {
                                        if (company_id === null) {
                                            toast.info(
                                                `Please add Company details before proceeding to Invite Users.`
                                            );
                                            return;
                                        }
                                        // Note:- subtracting 1 from active_user_count because it contain admin count also
                                        if (
                                            Number(allowedUser) ===
                                            Number(active_user_count) - 1
                                        ) {
                                            toast.info(
                                                <>
                                                    If you need more than three paid seats please contact Energy Domain sales at <a
                                                        href={`mailto:datasales@ed.com`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: "#fff" }}
                                                    >
                                                        datasales@ed.com
                                                    </a>
                                                </>
                                            );
                                            return;
                                        }
                                        setState((prev) => ({
                                            ...prev,
                                            inviteMember: true,
                                        }));
                                    }}
                                >
                                    <i className="fa-solid fa-user-plus"></i> Invite
                                    members
                                </button>
                            )} */}
                        </div>
                        <div className="tabelheader">
                            <div className="paid-seats">
                                <div className="sliderBlock">
                                    <div className="sliderrow">
                                        {/* The width condition has been implemented solely to ensure that the design does not break in old accounts. */}
                                        <span className="blueSlider" style={{ width: `${sliderWidth > 100 ? 100 : sliderWidth}%` }}></span>
                                    </div>
                                    using&nbsp;<strong>{active_user_count}&nbsp;</strong> of
                                    <strong>&nbsp;
                                        {Number(allowedUser)}
                                    </strong>&nbsp;seats
                                </div>

                                {
                                    // company_configs['free_trial_period_enabled'] || company_configs['is_trial_never_end'] ? <></> :
                                    <div
                                        // className={`requestmoreseat  
                                        // ${(subscriptionData?.subscription?.is_canceled || loadSubscriptionData) ? "disabled" : ""}
                                        // `}
                                        className={`requestmoreseat  ${(company_configs?.free_trial_period_enabled && !company_configs.trial_expired) || (subscription_status === "in-active" && subscriptionData === null) || company_configs.is_trial_never_end ? "disabled" : ""}`}
                                        onClick={() => {
                                            if (loadSubscriptionData || (subscription_status === "in-active" && subscriptionData === null)) {
                                                return
                                            }
                                            if ((company_configs.free_trial_period_enabled && !company_configs.trial_expired) || company_configs.is_trial_never_end) {
                                                toast.info('Seats cannot be added in trial mode. Please upgrade your subscription to add seats.', { toastId: "trial_period" })
                                                return;
                                            }
                                            if (company_id === null) {
                                                toast.info(
                                                    `Please add Company details before request more seats.`
                                                );
                                                return;
                                            }
                                            dispatch(
                                                logUserAction({
                                                    action_type: actionType["click_on_add_seats"],
                                                    action_log_detail: `user_id: ${user_id}`,
                                                })
                                            );
                                            dispatch(showCheckOutModal());
                                        }}>
                                        {(subscription_status === "in-active" && subscriptionData === null) ? <div className="alert-msg"
                                            data-tooltip-id="no-subscription-message"
                                            data-tooltip-html={`<div><span>Please subscribe to data first, then add your required seats.</span></div>`}
                                            data-tooltip-place={"bottom"}
                                            data-tooltip-variant="dark">
                                            Add more seats
                                        </div> : <> Add more seats</>}

                                    </div>
                                }
                            </div>

                            {signin_as === ADMIN && (
                                <button
                                    type="button"
                                    className={
                                        `${(inviteMember || (company_configs?.free_trial_period_enabled && !company_configs.trial_expired))
                                            ? "btn btn-disabled"
                                            : "btn btn-primary"}`
                                    }
                                    onClick={() => {
                                        if (company_id === null) {
                                            toast.info(
                                                `Please add Company details before proceeding to Invite Users.`
                                            );
                                            return;
                                        }
                                        if ((company_configs.free_trial_period_enabled && !company_configs.trial_expired) || company_configs.is_trial_never_end) {
                                            toast.info('Inviting members is not available in trial mode. Please upgrade your subscription to enable this feature.', { toastId: "invite_member" })
                                            return;
                                        }
                                        if (
                                            Number(allowedUser) ===
                                            Number(active_user_count)
                                        ) {
                                            toast.info(`Please add more seats then invite new members`);
                                            return;
                                        }
                                        setState((prev) => ({
                                            ...prev,
                                            inviteMember: true,
                                        }));
                                    }}
                                >
                                    <i className="fa-solid fa-user-plus"></i> Invite
                                    members
                                </button>
                            )}
                        </div>
                        <div className="seatsTable">
                            <GlobalTable
                                tableStyle={{
                                    border: 0,
                                    cellPadding: 0,
                                    cellSpacing: 0,
                                }}
                                cols={
                                    useColInviteMembers(
                                        signin_as as number,
                                        user_id as number,
                                        showModal,
                                        onTrashIconClick
                                    ) as GlobalTableProps["cols"]
                                }
                                data={
                                    [
                                        ...((inviteMembersData && inviteMembersData.length !== 0)
                                            ? [
                                                ...inviteMembersData.filter(
                                                    (item) => item.is_active === true
                                                ),
                                            ]
                                            : []),
                                        ...(Array.isArray(invited_user) && invited_user.length ? invited_user : [])
                                    ]
                                }
                            />
                            {deactivateRemoveModal && (
                                <DeactivateRemoveModal
                                    show={deactivateRemoveModal}
                                    handleClose={handleClose}
                                    label={label}
                                    activeMemberRequired={activeMemberRequired}
                                    userType={userType}
                                    selectedUserId={selectedUserId}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={`${Array.isArray(inviteMembersData) &&
                            inviteMembersData.filter((item) => item.is_active === false)
                                .length === 0
                            ? "d-none"
                            : "item minheight"
                            }`}
                    >
                        <h3>Deactivated Members</h3>
                        <p className="mb-4">Members that have been deactivated</p>
                        <div className="seatsTable">
                            <GlobalTable
                                tableStyle={{
                                    border: 0,
                                    cellPadding: 0,
                                    cellSpacing: 0,
                                }}
                                cols={
                                    useColInviteMembers(
                                        signin_as as number,
                                        user_id as number,
                                        showModal
                                    ) as GlobalTableProps["cols"]
                                }
                                data={
                                    inviteMembersData && inviteMembersData.length !== 0
                                        ? [
                                            ...inviteMembersData.filter(
                                                (item) => item.is_active === false
                                            ),
                                        ]
                                        : []
                                }
                            />
                        </div>
                    </div>
                </div>
            </Scrollbars>
            {checkOutModal && (
                <Elements stripe={stripePromise}>
                    <PaymentModal
                        onCancelBtnClick={() => {
                            dispatch(hideCheckOutModal());
                        }}
                        removeBasinOrCounty={() => { }}
                        seatModal={true}
                    />
                </Elements>
            )}
            {state['showRevokeModal'] && (
                <DeleteConfirmationModal
                    show={state['showRevokeModal']}
                    handleClose={() =>
                        setState((prev) => ({
                            ...prev,
                            showRevokeModal: false,
                            revokeUserId: 0
                        }))
                    }
                    confirmBtnClick={() => {
                        dispatch(revokeInvitedUser({ invite_id: state['revokeUserId'] })).finally(() => {
                            setState((prev) => ({ ...prev, revokeUserId: state['revokeUserId'], showRevokeModal: false }))
                        })
                    }}
                    content={
                        <p>Are you sure you want to revoke this invitation?</p>
                    }
                />
            )
            }
            <Tooltip id="no-subscription-message" />
        </div>
    );
};

export default withSideNav(MemberSettingsView);

